import { NSDropdown, NSInput } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import React, { useCallback, useMemo, useState } from 'react';
import './DealScenarioDropdown.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { INestedOptionAnyValue, SimplifiedScenario } from './types';
import { BackOption, DealScenariosOption, NewScenarioOption, UncategorizedScenariosOption } from './constants';
import { filterOptions } from './helpers/searchFilter';
import { transformScenarios } from './helpers/buildScenarios';

interface IDealScenarioDropdown {
    scenarios: SimplifiedScenario[];
    preSelectScenario?: IOptionAnyValue;
    includeCreate?: boolean;
    includeSearch?: boolean;
    includeLabels?: boolean;
    cleanOnSelect?: boolean;
    handleCreateScenario: (option: IOptionAnyValue) => void;
    handleSelectScenario: (option: IOptionAnyValue) => void;
}

const DealScenarioDropdown = ({
    scenarios,
    handleCreateScenario,
    includeCreate,
    handleSelectScenario,
    preSelectScenario,
    cleanOnSelect,
    includeLabels,
    includeSearch,
}: IDealScenarioDropdown) => {
    const [selectedScenario, setSelectedScenario] = useState<IOptionAnyValue | undefined>(preSelectScenario);
    const [breadcrumbs, setBreadcrumbs] = useState<INestedOptionAnyValue[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');

    const { dealScenarios, uncategorizedScenarios } = transformScenarios(scenarios);

    const options: INestedOptionAnyValue[] = useMemo(
        () => [
            ...(includeLabels && dealScenarios.length > 0 ? [DealScenariosOption] : []),
            ...dealScenarios,
            ...(includeLabels && uncategorizedScenarios.length > 0 ? [UncategorizedScenariosOption] : []),
            ...uncategorizedScenarios,
            ...(includeCreate ? [NewScenarioOption] : []),
        ],
        [dealScenarios, uncategorizedScenarios],
    );

    const [currentOptions, setCurrentOptions] = useState<INestedOptionAnyValue[]>(options);

    const handleGoBack = () => {
        if (breadcrumbs.length > 0) {
            const newBreadcrumbs = [...breadcrumbs];
            newBreadcrumbs.pop();
            setBreadcrumbs(newBreadcrumbs);
            setCurrentOptions(newBreadcrumbs.length > 0 ? newBreadcrumbs[newBreadcrumbs.length - 1].submenu! : options);
        }
    };

    const handleSelectOption = (option: IOptionAnyValue) => {
        if (option.value === 'new') {
            handleCreateScenario(option);
        } else if (option.value === 'back') {
            handleGoBack();
        } else if (option.value === 'search') {
            // NOOP;
        } else if (!cleanOnSelect) {
            setSelectedScenario(option);
        }
    };

    const handleOptionSelect = (option: INestedOptionAnyValue) => {
        if (option.submenu) {
            setBreadcrumbs(prev => [...prev, option]);
            setCurrentOptions(option.submenu);
        } else {
            handleSelectOption(option);
            setSearchValue('');
            setCurrentOptions(options);
            handleSelectScenario(option);
        }
    };

    const handleSearchTerm = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchValue(event.target.value);
            const filteredOptions = filterOptions(options, event.target.value);
            setCurrentOptions(filteredOptions);
        },
        [options],
    );

    const searchOption = {
        label: 'SEARCH',
        value: 'search',
        disabled: false,
        toggle: false,
        submenu: undefined,
        component: (
            <NSInput
                type="search"
                name="search-bar"
                placeholder="Search"
                value={searchValue}
                onChange={handleSearchTerm}
                customClassName="bg-secondary border-0 px-0 w-100"
                inputGroupClassName="bg-secondary border-0"
                prependInputAddon={<FontAwesomeIcon className="text-muted" icon={faMagnifyingGlass} />}
            />
        ),
    };

    const search = includeSearch ? [searchOption] : [];

    const dropDownOptions = useMemo(
        () => [...(breadcrumbs.length > 0 ? [BackOption] : search), ...currentOptions],
        [currentOptions, breadcrumbs, searchValue],
    );

    return (
        <div>
            <NSDropdown
                isFullWidth
                options={dropDownOptions}
                selectedOption={selectedScenario}
                handleSelectOption={handleOptionSelect}
                placeholder="Select scenario"
                optionClassName={`dropdown-item ${includeCreate ? ' include-create' : ''}`}
            />
        </div>
    );
};

export default DealScenarioDropdown;
