import React from 'react';
import { Table, TableProps } from 'reactstrap';

export interface INSTableProps extends TableProps {}

const NSTable = ({ className = '', children, ...rest }: INSTableProps) => {
    return (
        <Table {...rest} data-testid="nstable" className={`NSTable ${className}`}>
            {children}
        </Table>
    );
};

export default NSTable;
