import React from 'react';
import { Input } from 'reactstrap';
import NSLabel from '../NSLabel/NSLabel';

export interface INSSwitchProps {
    id: string;
    name: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    containerClassName?: string;
    disabled?: boolean;
}

const NSSwitch = ({ id, name, checked, onChange, label, containerClassName, disabled }: INSSwitchProps) => (
    <div className={`NSSwitch ${containerClassName}`}>
        <Input type="checkbox" id={id} name={name} checked={checked} onChange={onChange} disabled={disabled} className="d-none" />
        <NSLabel for={id} className="NSSwitch__content">
            <span className="NSSwitch__content__slider" />
        </NSLabel>
        {label && <span>{label}</span>}
    </div>
);

export default NSSwitch;
