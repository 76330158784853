import React, { useEffect, useState, useRef } from 'react';
import { NSButton } from 'bricks';
import EmptyState from 'components/EmptyState/EmptyState';
import classNames from 'classnames';
import PageTitle from 'components/PageTitle/PageTitle';
import ReportsControl from './ReportsControl/ReportsControl';
import './Reports.scss';

import CreateReport from './CreateReport/CreateReport';
import { useGetReports } from './hooks/useGetReports';
import ReportTable from './ReportTable/ReportTable';

const Reports = () => {
    const [isCreateReportShown, setIsCreateReportShown] = useState<boolean>(false);
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const scrollableDivRef = useRef<HTMLDivElement>(null);

    const orgId = '3fa85f64-5717-4562-b3fc-2c963f66afa6';
    const { data: reportData } = useGetReports({ pageSize: 10, currentPage: 1, orgId });

    // Save scroll position before hiding the div
    const handleShowCreateReport = () => {
        if (scrollableDivRef.current) {
            setScrollPosition(scrollableDivRef.current.scrollTop);
        }
        setIsCreateReportShown(true);
    };

    // Restore scroll position when showing the div
    useEffect(() => {
        if (!isCreateReportShown && scrollableDivRef.current) {
            scrollableDivRef.current.scrollTop = scrollPosition;
        }
    }, [isCreateReportShown, scrollPosition]);

    return (
        <>
            <div
                ref={scrollableDivRef}
                className={classNames([
                    'flex-column m-1 vh-100 overflow-y--auto',
                    {
                        'd-none': isCreateReportShown,
                        'd-flex': !isCreateReportShown,
                    },
                ])}
            >
                <PageTitle title="Reports" breadcrumbBackLinkCallback={() => console.log('back')} breadcrumbBackLinkText="Back">
                    <NSButton className="NSButton pt-1" text="Create report" color="primary" outline={false} callback={handleShowCreateReport} />
                </PageTitle>
                {!reportData || reportData.items.length === 0 ? (
                    <EmptyState
                        title="No reports created yet!"
                        subtitle="Quickly build powerful reports from your deals using one of our report templates."
                    >
                        <NSButton text="Create report" color="primary" outline={false} className="NSButton" callback={handleShowCreateReport} />
                    </EmptyState>
                ) : (
                    <div>
                        <ReportsControl />
                        <ReportTable reportData={reportData.items} />
                    </div>
                )}
            </div>
            {isCreateReportShown && <CreateReport setIsCreateReportShown={setIsCreateReportShown} />}
        </>
    );
};

export default Reports;
