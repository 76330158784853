import React, { useMemo, useState } from 'react';
import { NSCard, NSNavTabs } from 'bricks';
import classNames from 'classnames';
import Attributes from './Attributes/Attributes';
import { TABS } from './constants';
import FiltersSection from './Filters/FiltersSection';
import { IFilter } from './Filters/types';

const ConfigurationSidebar = () => {
    const [activeTab, setActiveTab] = useState(TABS.ATTRIBUTES);
    const [filters, setFilters] = useState<IFilter[]>([]);

    const tabs = useMemo(
        () =>
            Object.values(TABS).map(tab => ({
                name: tab === TABS.FILTERS && filters.length ? `${tab} (${filters.length})` : tab,
                callback: () => setActiveTab(tab),
                isActive: activeTab === tab,
                id: tab,
            })),
        [activeTab, filters],
    );

    return (
        <NSCard className="ConfigurationSidebar w-25 p-3">
            <NSNavTabs navItems={tabs} />
            <Attributes className={classNames({ 'd-none': activeTab !== TABS.ATTRIBUTES })} />
            <FiltersSection className={classNames({ 'd-none': activeTab !== TABS.FILTERS })} filters={filters} setFilters={setFilters} />
        </NSCard>
    );
};

export default ConfigurationSidebar;
