import React from 'react';
import { NSCheckbox } from 'bricks';
import { IReport, ReportTypeIdToNameMap } from '../../types';

interface IReportRowProps {
    reportRowData: IReport;
    isSelected: boolean;
    onSelect: (id: number) => void;
}

const ReportRow = ({ reportRowData, isSelected, onSelect }: IReportRowProps) => {
    return (
        <tr className="NSTable__tbody__tr">
            <td className="NSTable__tbody__tr__td align-middle">
                <div className="d-flex align-items-center">
                    <NSCheckbox id={`report-select-${reportRowData.id}`} checked={isSelected} callback={() => onSelect(reportRowData.id)} />
                    <span className="text-primary">{reportRowData.name}</span>
                </div>
            </td>
            <td className="NSTable__tbody__tr__td align-middle text-white">{ReportTypeIdToNameMap[reportRowData.type]}</td>
            <td className="NSTable__tbody__tr__td align-middle text-white">{reportRowData.description}</td>
            <td className="NSTable__tbody__tr__td align-middle text-white">
                {reportRowData.deals.map(deal => (
                    <div key={deal}>{deal}</div>
                ))}
            </td>
        </tr>
    );
};

export default ReportRow;
