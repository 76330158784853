import React from 'react';

interface IDetailTextProps {
    label: string;
    value: string | number;
}

const DetailText = ({ label, value }: IDetailTextProps) => {
    return (
        <p className="d-flex justify-content-between">
            <span className="text-muted">{label}:</span>
            <span className="text-dark">{value}</span>
        </p>
    );
};

export default DetailText;
