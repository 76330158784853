/* eslint-disable arrow-body-style */
import React from 'react';
import { ModalHeader } from 'reactstrap';
import classNames from 'classnames';
import RenderIf from '../../components/RenderIf/RenderIf';
import { NSModal, NSBadge } from '..';

export interface INSSlideoutProps {
    toggle: (arg?: any | null) => void;
    isOpen: boolean;
    closeButtonIsShown?: boolean;
    customHeader?: React.ReactElement;
    customHeaderActions?: React.ReactNode;
    modalBodyClassName?: string;
    modalHeaderText?: string | JSX.Element;
    modalHeaderTextClassName?: string;
    modalHeaderSubtitleText?: string | JSX.Element;
    modalHeaderSubtitleTextClassName?: string;
    modalHeaderBadgeText?: string;
    modalHeaderBadgeClassName?: string;
    modalFooterIsShown?: boolean;
    modalFooterButtonText?: string | React.ReactNode;
    modalFooterButtonFunction?: () => void;
    footerButtonOutline?: boolean;
    size?: string;
    children?: React.ReactNode;
}

const NSSlideout = ({
    toggle,
    isOpen,
    closeButtonIsShown = true,
    customHeader,
    customHeaderActions,
    modalBodyClassName,
    modalFooterIsShown = false,
    modalHeaderText = 'Default Modal Header Text',
    modalHeaderTextClassName = 'text-dark',
    modalHeaderSubtitleText,
    modalHeaderSubtitleTextClassName,
    modalHeaderBadgeText,
    modalHeaderBadgeClassName,
    modalFooterButtonText,
    modalFooterButtonFunction,
    footerButtonOutline,
    size = 'lg',
    children,
}: INSSlideoutProps) => {
    return (
        <NSModal
            toggle={toggle}
            isOpen={isOpen}
            contentClassName="h-100 overflow-auto"
            modalBodyClassName={`overflow-auto ${modalBodyClassName}`}
            modalClassName="w-100 modal-right"
            modalFooterIsShown={modalFooterIsShown}
            modalFooterButtonText={modalFooterButtonText}
            modalFooterButtonFunction={modalFooterButtonFunction}
            footerButtonOutline={footerButtonOutline}
            size={size}
            customHeader={
                customHeader || (
                    <ModalHeader
                        tag="div"
                        toggle={toggle}
                        className="d-flex flex-row-reverse p-0 align-items-center"
                        cssModule={{
                            'modal-title': classNames('modal-title w-100', {
                                'pr-3': closeButtonIsShown,
                                'px-3': !closeButtonIsShown,
                            }),
                            close: classNames('btn btn-lg', {
                                'd-none': !closeButtonIsShown,
                            }),
                        }}
                    >
                        <div className="d-flex justify-content-between align-items-center py-1">
                            <div className="d-flex align-items-center">
                                <div>
                                    <h5 className={modalHeaderTextClassName}>{modalHeaderText}</h5>
                                    <h6 className={modalHeaderSubtitleTextClassName}>{modalHeaderSubtitleText}</h6>
                                </div>
                                {Boolean(modalHeaderBadgeText) && (
                                    <NSBadge className={modalHeaderBadgeClassName || 'ml-1 badge-primary-lighten'}>{modalHeaderBadgeText}</NSBadge>
                                )}
                            </div>
                            <RenderIf isTrue={customHeaderActions}>{customHeaderActions}</RenderIf>
                        </div>
                    </ModalHeader>
                )
            }
        >
            {children}
        </NSModal>
    );
};

export default NSSlideout;
