import React from 'react';
import { numeralFormatterCurrency, percentFormatter } from 'ns_libs/formatter';
import { ICostMetricsData } from '../../../types';

export const CostMetrics: React.FC<{ data: ICostMetricsData }> = ({ data }) => {
    const placeholder = '-';
    return (
        data && (
            <div className="text-white">
                <div>{percentFormatter(data.usagePercent)}</div>
                <div>$ / Build .SF: {numeralFormatterCurrency(data.costPerBuildSF)}</div>
                <div>$ / Unit: {numeralFormatterCurrency(data.costPerUnit) || placeholder}</div>
                <div>Amount: {numeralFormatterCurrency(data.amount)}</div>
            </div>
        )
    );
};

export default CostMetrics;
