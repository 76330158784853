import React, { useMemo, useState } from 'react';
import { NSButton } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import { IFilter } from './types';
import FilterModal from './FilterModal/FilterModal';
import Filter from './Filter';
import { Attributes } from '../constants';

interface IFilterSectionProps {
    className?: string;
    filters: IFilter[];
    setFilters: (filters: IFilter[]) => void;
}

const FiltersSection = ({ className, filters, setFilters }: IFilterSectionProps) => {
    const [isFilterModalShown, setIsFilterModalShown] = useState<boolean>(false);

    const attributeOptions: IOptionAnyValue[] = useMemo(() => {
        return Object.values(Attributes).map(attribute => ({
            label: attribute,
            value: attribute,
            disabled: filters.some(f => f.attribute === attribute),
        }));
    }, [filters]);

    const toggleFilterModal = () => setIsFilterModalShown(!isFilterModalShown);

    const addFilter = (filter: IFilter) => {
        setFilters([...filters, filter]);
    };

    const removeFilter = (attribute: Attributes) => {
        const newFilters = filters.filter(f => f.attribute !== attribute);
        setFilters(newFilters);
    };

    const editFilter = (attribute: Attributes, filter: IFilter) => {
        const updatedFilters = filters.map(f => {
            if (f.attribute === attribute) {
                return filter;
            }
            return f;
        });
        setFilters(updatedFilters);
    };

    return (
        <div className={className}>
            <div>
                {filters.map(filter => (
                    <Filter
                        key={filter.attribute}
                        filter={filter}
                        editFilter={editFilter}
                        removeFilter={removeFilter}
                        attributeOptions={attributeOptions}
                    />
                ))}
                <NSButton className="text-primary cursor--pointer m-0 mt-1 p-0" callback={() => setIsFilterModalShown(true)}>
                    + Add filter
                </NSButton>
            </div>
            <FilterModal toggle={toggleFilterModal} isOpen={isFilterModalShown} saveFilter={addFilter} attributeOptions={attributeOptions} />
        </div>
    );
};

export default FiltersSection;
