// Later we have to define the min and max values for each target return.
export const TARGET_RETURNS_CONFIG = [
    {
        title: 'RETURN ON COST',
        field: 'cost',
        min: 0,
        max: 100,
        unit: '%',
    },
    {
        title: 'RETURN ON EQUITY',
        field: 'equity',
        min: 0,
        max: 100,
        unit: '%',
    },
    {
        title: 'PROFIT RETURN',
        field: 'profit',
        min: 0,
        max: 1000000000,
        unit: '$',
    },
];
