import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { config } from 'config';

mapboxgl.accessToken = config.mapboxGlApiKey;

export interface IMapboxProps {
    lng: number;
    lat: number;
    zoom?: number;
    hideMarker?: boolean;
}

const Map = ({ lng, lat, zoom = 15, hideMarker = false }: IMapboxProps) => {
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const mapInstance = useRef<mapboxgl.Map | null>(null);
    const markerInstance = useRef<mapboxgl.Marker | null>(null);

    useEffect(() => {
        if (!mapContainerRef.current) return;

        if (!mapInstance.current) {
            mapInstance.current = new mapboxgl.Map({
                container: 'data-map',
                style: 'mapbox://styles/mapbox/dark-v10',
                center: [lng, lat],
                zoom,
            });

            if (!hideMarker) {
                markerInstance.current = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(mapInstance.current);
            }
        }
    }, []);

    useEffect(() => {
        if (mapInstance.current) {
            mapInstance.current.jumpTo({
                center: [lng, lat],
                zoom,
            });

            if (markerInstance.current) {
                markerInstance.current.remove();
                markerInstance.current = null;
            }

            if (!hideMarker) {
                markerInstance.current = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(mapInstance.current);
            }
        }
    }, [lng, lat, zoom, hideMarker]);

    return (
        <>
            <div
                className=""
                role="button"
                tabIndex={0}
                style={{
                    height: '300px',
                    width: '300px',
                    opacity: 1,
                    zIndex: 1,
                }}
                data-testid="map"
            />
            <div
                ref={mapContainerRef}
                id="data-map"
                className="position-absolute"
                style={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}
            />
        </>
    );
};

export default Map;
