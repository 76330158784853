import React from 'react';
import { DndContext, closestCenter, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export interface IProps {
    selectedColumns: string[];
    setSelectedColumns: (selectedColumns: string[]) => void;
}

interface DraggableColumnProps {
    id: string;
}

const DraggableColumn: React.FC<DraggableColumnProps> = ({ id }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} className="text-white d-flex align-items-center bg-secondary p-2 mt-2 rounded">
            <div {...attributes} {...listeners} className="pr-2" style={{ cursor: 'grab' }}>
                <FontAwesomeIcon icon={faGripVertical} className="text-muted" />
            </div>
            <h5 className="my-0">{id}</h5>
        </div>
    );
};

const SelectedColumns: React.FC<IProps> = ({ selectedColumns, setSelectedColumns }) => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (over && active.id !== over.id) {
            const oldIndex = selectedColumns.indexOf(active.id as string);
            const newIndex = selectedColumns.indexOf(over.id as string);

            const updatedColumns = arrayMove(selectedColumns, oldIndex, newIndex);
            setSelectedColumns(updatedColumns);
        }
    };

    return (
        <div className="SelectedColumns d-flex flex-column my-2">
            <p className="text-white mt-2 mb-0">Selected columns:</p>
            {selectedColumns.length === 0 && <p className="text-muted">No columns added - add columns below</p>}
            <OverlayScrollbarsComponent
                defer
                options={{
                    scrollbars: { autoHide: 'leave' },
                    overflow: { y: 'scroll' },
                }}
                className="OverlayScrollbarsComponent overflow-auto"
            >
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                    <SortableContext items={selectedColumns} strategy={verticalListSortingStrategy}>
                        {selectedColumns.map(columnName => (
                            <DraggableColumn key={columnName} id={columnName} />
                        ))}
                    </SortableContext>
                </DndContext>
            </OverlayScrollbarsComponent>
        </div>
    );
};

export default SelectedColumns;
