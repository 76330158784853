import React, { useMemo } from 'react';
import { IOptionAnyValue } from 'bricks/types';
import { NSSelect } from 'bricks';

export interface IProps {
    label?: string;
    placehoder?: string;
    options?: IOptionAnyValue[];
    onSelectOption: (option: IOptionAnyValue) => void;
    selectedOption?: IOptionAnyValue;
}

export const AddToComparisonSimpleSelect = ({ label = 'Add to comparison', placehoder, options, onSelectOption, selectedOption }: IProps) => {
    const internalSelectedOption = useMemo(() => {
        if (selectedOption && options) {
            return options.find(option => option.value === selectedOption);
        }
        return null;
    }, [options, selectedOption, placehoder]);

    return (
        <>
            <div className="text-white">{label}</div>
            <NSSelect
                placeholder={placehoder}
                options={options}
                name="state"
                value={internalSelectedOption}
                onChange={option => onSelectOption(option)}
                isClearable={false}
            />
        </>
    );
};

export default AddToComparisonSimpleSelect;
