import numeral from 'numeral';

// ------- Currency Formatter ---------

const formatUS = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const formatUSTwoDigits = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const formCanada = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const formatUSDCurrency = (value: number) => {
    if (value) {
        let formatted = formatUS.format(value);
        if (formatted === '-$0') {
            formatted = '$0';
        }
        return formatted;
    }
    return '$0';
};

export const formatUSDCurrencyTwoDigits = (value: number | string | undefined, decimal: boolean = false) => {
    let returnValue = '$0';
    if (decimal) {
        returnValue = '$0.00';
    }
    if (value) {
        let formatted = returnValue;
        if (typeof value !== 'string') {
            formatted = formatUSTwoDigits.format(value);
        } else if (!Number.isNaN(parseFloat(value))) {
            formatted = formatUSTwoDigits.format(parseFloat(value));
        }

        if (formatted === '-$0.00' || formatted === '$0.00') {
            return returnValue;
        }
        return formatted;
    }
    return returnValue;
};

export const formatCanadaCurrency = (value: number) => {
    let formatted = formCanada.format(value);
    if (formatted === '-$0') {
        formatted = '$0';
    }
    return formatted;
};

/*
 * This function implements workarounds for bugs in the numeral.js library.
 * 1) Negative values that round to zero have incorrect formatting
 *    (https://github.com/adamwdraper/Numeral-js/issues/777)
 * 2) Extremely small results return NaN
 *    (https://github.com/adamwdraper/Numeral-js/issues/512)
 */
export const numeralFixed = (value: number | string | undefined, format: string) => {
    // Numeral.format() accepts a custom rounding function, which is where both of these
    // issues occur, so we can use this to detect or intercept values that will fail,
    // and handle these cases accordingly

    let roundsToZero; // will be true if the rounding function returns 0

    const customRounder = (num: string): number => {
        // The odd typing here is to most accurately represent what numeral.js is doing
        // under the hood, which is passing a numeric value as a string to the rounding
        // function, and expecting the rounding function to coerce the value to a number.

        // The library uses Math.round as the default rounding function, so try that first
        const defaultResult = Math.round(num as any);
        if (defaultResult === 0) {
            roundsToZero = true;
        }
        if (!Number.isNaN(defaultResult)) {
            return defaultResult;
        }

        // Numeral appends '+e' to the arg passed into this function, so if the original
        // value already has '+e' (as JS does automatically for very small numbers),
        // the argument will be something like "-1.1641532182693481e-10e+2" which cannot
        // be coerced to a number unless the exponents are split up and calculated manually.
        const parts = num.split('e');
        if (parts.length === 3) {
            const [coef, exponent1, exponent2] = parts.map(e => Number(e));
            const e = 10;
            const fixedResult = Math.round((coef * e) ** (exponent1 + exponent2));
            if (fixedResult === 0) {
                roundsToZero = true;
            }
            return fixedResult;
        }
        return NaN;
    };
    const defaultResult = numeral(value).format(format, customRounder);
    // if the result would have rounded to zero, just pass in 0 and return that instead
    return roundsToZero ? numeral(0).format(format) : defaultResult;
};

export const numeralFormatterCurrency = (value: number | string | undefined, format: string = '$0,0.00') => numeralFixed(value, format);
export const numeralFormatter = (value: number | string | undefined, format: string = '0,0') => numeralFixed(value, format);

// ------- Percentage ---------

export const formatPercentageNumber = (numerator: number, denominator: number, decimals: number = 0) => {
    if (denominator === 0) {
        return 0;
    }
    return Number(((numerator / denominator) * 100).toFixed(decimals));
};

export const formatPercentage = (numerator: number, denominator: number, decimals: number) =>
    `${formatPercentageNumber(numerator, denominator, decimals).toFixed(decimals)}%`;

export const percentFormatter = (value: number | string | undefined): string | undefined => {
    if (value === undefined || value === '') {
        return;
    }

    const numericValue = typeof value === 'string' ? parseFloat(value) : value;

    // eslint-disable-next-line consistent-return
    return `${numericValue.toFixed(2)}%`;
};

// ------- Date ---------

export const formatDate = (date: string | null): string => {
    return date ? new Date(date).toLocaleDateString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' }) : '-';
};
