import React, { Dispatch, SetStateAction } from 'react';
import NSCard from 'bricks/NSCard/NSCard';
import { FORM_ROW_CLASS_NAME, FORM_LABEL_CLASS_NAME, dealTypeProFormaParkingOptions } from 'views/ProFormaTable/constants';
import { NSButton, NSInput, NSLabel, NSSelect } from 'bricks';
import { customStyles } from 'bricks/NSSelect/NSSelect';
import classNames from 'classnames';
import RenderIf from 'components/RenderIf/RenderIf';
import NSInputNumberStepper from 'bricks/NSInputNumberStepper/NSInputNumberStepper';
import { IOptionAnyValue } from 'bricks/types';
import 'css/partials/_bricks.NSInput.scss';
import { PRO_FORMA_SIZE_UNIT_OPTIONS } from 'constants/unitTypes';
import CalculateSizeCard from '../CalculateSizeCard/CalculateSizeCard';

export interface IApartmentSizeDetailsProps {
    values: any;
    setValues: Dispatch<SetStateAction<any>>;
    deal: any;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelect: (name: string, option: string | IOptionAnyValue | IOptionAnyValue[]) => void;
}

const ApartmentSizeDetails = ({ values, setValues, deal, handleInputChange, handleSelect }: IApartmentSizeDetailsProps) => (
    <div className="d-flex">
        <div className="d-flex flex-column col-8 pl-0">
            <div className={FORM_ROW_CLASS_NAME}>
                <NSLabel className={FORM_LABEL_CLASS_NAME}>Land area</NSLabel>
                <NSInput
                    type="number"
                    name="landArea"
                    customClassName="NSInput__appended-child--with-select"
                    value={values.landArea}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                    placeholder="Enter land area"
                    inputGroupClassName="p-0"
                    appendInputAddon={
                        <NSSelect
                            name="landAreaUnitType"
                            options={PRO_FORMA_SIZE_UNIT_OPTIONS}
                            value={PRO_FORMA_SIZE_UNIT_OPTIONS.find(opt => opt.value === values.landAreaUnitType)}
                            onChange={option => handleSelect('landAreaUnitType', option)}
                            isClearable={false}
                            formatOptionLabel={(option: { label: string; value: string }, context: { context: string }) => {
                                if (context.context === 'menu') {
                                    return option.label === 'ft' ? (
                                        <span>
                                            {option.label}
                                            <sup>2</sup>
                                        </span>
                                    ) : (
                                        option.label
                                    );
                                }

                                return option.label === 'ft' ? (
                                    <span>
                                        {option.label}
                                        <sup>2</sup>
                                    </span>
                                ) : (
                                    option.label
                                );
                            }}
                            styles={{
                                ...customStyles,
                                control: styles => ({
                                    ...styles,
                                    border: 'none',
                                    backgroundColor: '#47515d',
                                    width: '80px',
                                }),
                            }}
                        />
                    }
                />
            </div>
            <div className={FORM_ROW_CLASS_NAME}>
                <NSLabel className={`d-flex ${FORM_LABEL_CLASS_NAME}`}>
                    <NSButton
                        color="secondary"
                        outline={false}
                        callback={() => setValues({ ...values, isFAR: true })}
                        text="FAR"
                        className={classNames('px-3', { 'border border-primary bg-primary-lighten': values.isFAR })}
                    />
                    <NSButton
                        color="secondary"
                        outline={false}
                        callback={() => setValues({ ...values, isFAR: false })}
                        text="Gross build. area"
                        className={classNames({ 'border border-primary bg-primary-lighten': !values.isFAR })}
                    />
                </NSLabel>
                <RenderIf isTrue={values.isFAR}>
                    <NSInputNumberStepper
                        name="farAmount"
                        value={values.farAmount}
                        onChange={(count: number) => setValues({ ...values, farAmount: count })}
                        min={0.1}
                        inputGroupClassName="w-25"
                        step={0.1}
                    />
                </RenderIf>

                <RenderIf isTrue={!values.isFAR}>
                    <NSInput
                        type="number"
                        name="grossBuildingArea"
                        customClassName="NSInput__appended-child--with-select"
                        value={values.grossBuildingArea}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                        placeholder="Enter gross buildable area"
                        inputGroupClassName="p-0"
                        appendInputAddon={
                            <NSSelect
                                name="grossBuildingAreaUnitType"
                                options={PRO_FORMA_SIZE_UNIT_OPTIONS}
                                value={PRO_FORMA_SIZE_UNIT_OPTIONS.find(opt => opt.value === values.grossBuildingAreaUnitType)}
                                onChange={option => handleSelect('grossBuildingAreaUnitType', option)}
                                isClearable={false}
                                formatOptionLabel={(option: { label: string; value: string }, context: { context: string }) => {
                                    if (context.context === 'menu') {
                                        return option.label === 'ft' ? (
                                            <span>
                                                {option.label}
                                                <sup>2</sup>
                                            </span>
                                        ) : (
                                            option.label
                                        );
                                    }

                                    return option.label === 'ft' ? (
                                        <span>
                                            {option.label}
                                            <sup>2</sup>
                                        </span>
                                    ) : (
                                        option.label
                                    );
                                }}
                                styles={{
                                    ...customStyles,
                                    control: styles => ({
                                        ...styles,
                                        border: 'none',
                                        backgroundColor: '#47515d',
                                        width: '80px',
                                    }),
                                }}
                            />
                        }
                    />
                </RenderIf>
            </div>

            <CalculateSizeCard />

            <div className={FORM_ROW_CLASS_NAME}>
                <NSLabel className={FORM_LABEL_CLASS_NAME}>Buildings</NSLabel>
                <NSInputNumberStepper
                    name="buildings"
                    value={values.buildings}
                    onChange={(count: number) => setValues({ ...values, buildings: count })}
                    min={1}
                    inputGroupClassName="w-25"
                    step={1}
                />
            </div>

            <div className={FORM_ROW_CLASS_NAME}>
                <NSLabel className={FORM_LABEL_CLASS_NAME}>{values.buildings <= 1 ? 'Stories' : 'Avg. # of stories'}</NSLabel>
                <NSInputNumberStepper
                    name="averageStories"
                    value={values.averageStories}
                    onChange={(count: number) => setValues({ ...values, averageStories: count })}
                    min={0.1}
                    inputGroupClassName="w-25"
                    step={0.1}
                />
            </div>

            <div className={FORM_ROW_CLASS_NAME}>
                <NSLabel className={FORM_LABEL_CLASS_NAME}>Built gross SF</NSLabel>
                <NSInput
                    type="number"
                    name="builtGrossSF"
                    value={values.builtGrossSF}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                    placeholder="Enter built gross SF"
                    inputGroupClassName="p-0"
                    appendInputAddon={
                        <div className="px-2">
                            ft<sup>2</sup>
                        </div>
                    }
                />
            </div>

            <div className={FORM_ROW_CLASS_NAME}>
                <NSLabel className={FORM_LABEL_CLASS_NAME}>Parking</NSLabel>
                <div className="w-100">
                    <NSSelect
                        name="parking"
                        options={dealTypeProFormaParkingOptions[deal.assetType.name]}
                        value={values.parking}
                        onChange={options => handleSelect('parking', options || [])}
                        isMulti
                    />
                </div>
            </div>

            <RenderIf isTrue={values.parking?.find((park: any) => park.value === 'structured')}>
                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Structured parking spaces</NSLabel>
                    <NSInputNumberStepper
                        name="structuredParkingSpaces"
                        value={values.structuredParkingSpaces}
                        onChange={(count: number) => setValues({ ...values, structuredParkingSpaces: count })}
                        min={0.1}
                        inputGroupClassName="w-25"
                        step={1}
                    />
                </div>
            </RenderIf>

            <RenderIf isTrue={values.parking?.find((park: any) => park.value === 'surface')}>
                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Surface parking spaces</NSLabel>
                    <NSInputNumberStepper
                        name="surfaceParkingSpaces"
                        value={values.surfaceParkingSpaces}
                        onChange={(count: number) => setValues({ ...values, surfaceParkingSpaces: count })}
                        min={1}
                        inputGroupClassName="w-25"
                        step={1}
                    />
                </div>
            </RenderIf>
        </div>

        <div className="col-4 pr-0">
            <div className="d-flex mb-2">
                <NSCard className="NSCard--level-3 p-2 mr-2 w-50">
                    <div>Units</div>
                    <div className="text-dark font-italic">In progress</div>
                </NSCard>

                <NSCard className="NSCard--level-3 p-2 w-50">
                    <div>Net rentable area</div>
                    <div className="text-dark font-italic">In progress</div>
                </NSCard>
            </div>

            <div className="d-flex mb-2">
                <NSCard className="NSCard--level-3 p-2 mr-2 w-50">
                    <div>Units/acre</div>
                    <div className="text-dark font-italic">In progress</div>
                </NSCard>

                <NSCard className="NSCard--level-3 p-2 w-50">
                    <div>Gross buildable area</div>
                    <div className="text-dark font-italic">In progress</div>
                </NSCard>
            </div>

            <div className="d-flex mb-2">
                <NSCard className="NSCard--level-3 p-2 mr-2 w-50">
                    <div>Total parking spots</div>
                    <div className="text-dark font-italic">In progress</div>
                </NSCard>

                <NSCard className="NSCard--level-3 p-2 w-50">
                    <div>Parking ratio</div>
                    <div className="text-dark font-italic">In progress</div>
                </NSCard>
            </div>

            <div className="d-flex">
                <NSCard className="NSCard--level-3 p-2 mr-2 w-50">
                    <div>Efficiency ratio</div>
                    <div className="text-dark font-italic">In progress</div>
                </NSCard>
            </div>
        </div>
    </div>
);

export default ApartmentSizeDetails;
