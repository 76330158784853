import React from 'react';
import NSNavbar from 'components/NSNavbar/NSNavbar';
import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './LayoutWithNavbar.scss';

const LayoutWithNavbar = () => {
    const { isAuthenticated } = useAuth0();

    return (
        <>
            {isAuthenticated && <NSNavbar />}
            <div className="LayoutWithNavbar px-3 pb-3">
                <Outlet />
            </div>
        </>
    );
};

export default LayoutWithNavbar;
