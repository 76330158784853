import React from 'react';
import { NSCard } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faWarning } from '@fortawesome/free-solid-svg-icons';
import RenderIf from 'components/RenderIf/RenderIf';

export interface INSInfoBannerProps {
    title: string;
    subtitle?: string;
    className?: string;
    warning?: boolean;
    customInfo?: React.ReactNode;
}

const NSInfoBanner = ({ title, subtitle = '', className = '', warning = false, customInfo = undefined }: INSInfoBannerProps) => {
    return (
        <NSCard className={`${className} shadow-none`}>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon className="icon ml-2 text-warning" icon={warning ? faWarning : faInfo} fixedWidth size="lg" />
                    <div className="ml-1">
                        <h5 className="mb-0 text-dark">{title}</h5>
                        <h5 className="mb-1 mt-1 text-muted">{subtitle}</h5>
                    </div>
                </div>
                <RenderIf isTrue={customInfo}>
                    <div className="align-items-center">{customInfo}</div>
                </RenderIf>
            </div>
        </NSCard>
    );
};

export default NSInfoBanner;
