/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { InputType } from 'reactstrap/es/Input';
import { faFilePdf, faFileExcel, faFileWord, faFilePowerpoint, faFileImage, faFileVideo, IconDefinition } from '@fortawesome/free-solid-svg-icons';

export enum NSBUTTON_SIZES {
    SMALL = 'sm',
    LARGE = 'lg',
}

export const iconsByFilenameExtension: { [key: string]: IconDefinition } = {
    pdf: faFilePdf,
    doc: faFileWord,
    docx: faFileWord,
    pptx: faFilePowerpoint,
    ppt: faFilePowerpoint,
    xls: faFileExcel,
    xlsx: faFileExcel,
    tiff: faFileImage,
    jpg: faFileImage,
    jpeg: faFileImage,
    bmp: faFileImage,
    gif: faFileImage,
    png: faFileImage,
    mp4: faFileVideo,
    mov: faFileVideo,
};

export const InputTypes = {
    text: 'text' as InputType,
    email: 'email' as InputType,
    select: 'select' as InputType,
    file: 'file' as InputType,
    radio: 'radio' as InputType,
    checkbox: 'checkbox' as InputType,
    switch: 'switch' as InputType,
    textarea: 'textarea' as InputType,
    button: 'button' as InputType,
    reset: 'reset' as InputType,
    submit: 'submit' as InputType,
    date: 'date' as InputType,
    datetimeLocal: 'datetime-local' as InputType,
    hidden: 'hidden' as InputType,
    image: 'image' as InputType,
    month: 'month' as InputType,
    number: 'number' as InputType,
    range: 'range' as InputType,
    search: 'search' as InputType,
    tel: 'tel' as InputType,
    url: 'url' as InputType,
    week: 'week' as InputType,
    password: 'password' as InputType,
    datetime: 'datetime' as InputType,
    time: 'time' as InputType,
    color: 'color' as InputType,
};

export enum SUBPROJECT_STAGES {
    OPPORTUNITY = 1,
    DUE_DILIGENCE = 2,
    PREDEVELOPMENT = 3,
    CONSTRUCTION = 4,
    STABILIZATION = 5,
    COMPLETED = 6,
    ARCHIVED = 7,
}

export enum NORTHSPYRE_TIER_TYPES {
    NORTHSPYRE_FOR_VENDORS = 'Northspyre for Vendors',
    NORTHSPYRE_PLAN = 'Northspyre Plan',
    NORTHSPYRE_PLAN_PLUS = 'Northspyre Plan+',
    NORTHSPYRE_PRO = 'Northspyre Pro',
    NORTHSPYRE_ENTERPRISE = 'Northspyre Enterprise',
    NORTHSPYRE_HISTORICAL_DATA = 'Northspyre Historical Data',
    NORTHSPYRE_LITE = 'Northspyre Lite',
}

export const buttonCrudOperation = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE',
    PATCH: 'PATCH',
};

export const subprojectLifeStages = {
    DEAD: 0,
    ACTIVE: 1,
    ARCHIVED: 2,
    EXPORTED: 3,
};

export const MAX_FILE_UPLOAD_2GB = 2 * 1024 * 1024 * 1024;

export enum CONTENT_TYPE {
    PDF = 'application/pdf',
    DOC = 'application/msword',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    PPT = 'application/vnd.ms-powerpoint',
    PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    XLS = 'application/vnd.ms-excel',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    TIFF = 'image/tiff',
    JPG = 'image/jpeg',
    JPEG = 'image/jpeg',
    BMP = 'image/bmp',
    GIF = 'image/gif',
    PNG = 'image/png',
    MP4 = 'video/mp4',
    MOV = 'video/vnd.sealedmedia.softseal.mov',
    MOVQT = 'video/quicktime',
}
