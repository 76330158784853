import React from 'react';
import { NSInput, NSMultiSelect } from 'bricks';
import { AdditionalFieldType } from '../constants';
import { FieldInput, IAdditionalField } from '../types';

interface AdditionalFieldProps {
    additionalField: IAdditionalField;
    attribute: string;
    isRangeValid: boolean;
    fieldInput: FieldInput;
    setFieldInput: (value: FieldInput) => void;
}

const AdditionalField: React.FC<AdditionalFieldProps> = ({ additionalField, attribute, isRangeValid, fieldInput, setFieldInput }) => {
    const dateRangeErrorMessage = 'Range end date is before range start date';
    const rangeErrorMessage = 'Range end is before range start';

    const errorMessage = additionalField.type === AdditionalFieldType.DateRange ? dateRangeErrorMessage : rangeErrorMessage;

    switch (additionalField.type) {
        case AdditionalFieldType.Selector:
            return (
                <NSMultiSelect
                    componentClassName="mt-0"
                    selectName={additionalField.type}
                    options={additionalField.options}
                    value={fieldInput && 'options' in fieldInput ? fieldInput.options : []}
                    onChange={options => setFieldInput({ options })}
                />
            );
        case AdditionalFieldType.Text:
        case AdditionalFieldType.Textarea:
        case AdditionalFieldType.Number:
        case AdditionalFieldType.Date:
            return (
                <NSInput
                    placeholder={`Enter ${attribute.toLowerCase()}`}
                    customClassName="bg-secondary border-0"
                    name={`${attribute.toLowerCase()}Input`}
                    type={additionalField.type}
                    value={fieldInput && 'value' in fieldInput ? fieldInput.value : undefined}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldInput({ value: e.target.value })}
                />
            );
        case AdditionalFieldType.Range:
        case AdditionalFieldType.DateRange:
            return (
                <>
                    <div className="d-flex align-items-center">
                        <NSInput
                            type={additionalField.type === AdditionalFieldType.DateRange ? 'date' : 'number'}
                            customClassName="bg-secondary border-0"
                            placeholder={`Enter minimum ${attribute.toLowerCase()}`}
                            name={`${attribute.toLowerCase()}FromInput`}
                            value={fieldInput && 'from' in fieldInput ? fieldInput.from : undefined}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldInput({
                                    from: e.target.value,
                                    to: fieldInput && 'to' in fieldInput ? fieldInput?.to : '',
                                })
                            }
                            invalid={!isRangeValid}
                        />
                        <span className="px-2">and</span>
                        <NSInput
                            type={additionalField.type === AdditionalFieldType.DateRange ? 'date' : 'number'}
                            customClassName="bg-secondary border-0"
                            placeholder={`Enter maximum ${attribute.toLowerCase()}`}
                            name={`${attribute.toLowerCase()}ToInput`}
                            value={fieldInput && 'to' in fieldInput ? fieldInput.to : undefined}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldInput({
                                    to: e.target.value,
                                    from: fieldInput && 'from' in fieldInput ? fieldInput?.from : '',
                                })
                            }
                            invalid={!isRangeValid}
                        />
                    </div>
                    {!isRangeValid && <div className="text-danger mt-1">{errorMessage}</div>}
                </>
            );
        default:
            return null;
    }
};

export default AdditionalField;
