import React from 'react';
import { NSModal } from 'bricks';

export interface IImportUsesModalProps {
    isOpen: boolean;
    toggle: () => void;
    handleDeleteBudgetCategories: () => void;
}

const ImportUsesModal = ({ isOpen, toggle, handleDeleteBudgetCategories }: IImportUsesModalProps) => (
    <NSModal
        isOpen={isOpen}
        toggle={toggle}
        modalHeaderText="Import via different method"
        modalHeaderClassName="text-white bg-danger"
        modalFooterButtonText="Confirm"
        modalFooterButtonColor="danger"
        modalFooterButtonFunction={handleDeleteBudgetCategories}
    >
        <div className="text-dark h5 mb-0">Are you sure you want to return to the import selection step?</div>
        <div className="h6 mt-1">This will override any existing data you've entered and will not save your work.</div>
    </NSModal>
);

export default ImportUsesModal;
