import React, { useEffect, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { NSButton } from 'bricks';
import { useAuth0TokenContext } from '../../contexts/Auth0TokenContext';
import AppTemplateWithAuthorization from '../../components/AppTemplateWithAuthorization';
import { config } from '../../config';
import { URLS } from '../../services/urls';

const Counter = () => {
    const [responseMessage, setResponseMessage] = useState('');
    const [counter, setCounter] = useState(null);

    const { isAuthenticated, accessToken } = useAuth0TokenContext();

    useEffect(() => {
        if (isAuthenticated && accessToken) {
            // Ensure accessToken is available
            const initializeWebSocket = async () => {
                try {
                    // Append the token as a query parameter in the WebSocket URL
                    const webSocketUrl = `${config.webSocketBaseUrl}/counter?token=${accessToken}`;
                    const ws = new WebSocket(webSocketUrl);

                    ws.onopen = () => {
                        console.log('Connected to WebSocket server');
                    };

                    ws.onmessage = event => {
                        const data = JSON.parse(event.data);
                        setCounter(data.count); // Update counter based on WebSocket message
                    };

                    ws.onerror = error => {
                        console.error('WebSocket connection error:', error);
                    };

                    return () => {
                        ws.close();
                    };
                } catch (error) {
                    console.error('Failed to fetch access token:', error);
                }

                return undefined;
            };

            initializeWebSocket();
        }
    }, [isAuthenticated, accessToken]);

    const handleButtonClick = async () => {
        try {
            const response = await fetch(`${config.apiBaseUrl}/increment_counter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({}),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setCounter(data.count); // Update the counter with the HTTP response
        } catch (error) {
            setResponseMessage(`Error: ${error.message}`);
        }
    };

    const handleRedirect = () => {
        window.location.href = config.northspyreProjectsAppUrl;
    };

    return (
        <AppTemplateWithAuthorization>
            <NSButton className="m-2" color="secondary" callback={handleButtonClick}>
                Increment Counter
            </NSButton>
            <p>Current Counter Value: {counter !== null ? counter : 'Click the button to increment the count!'}</p>
            <NSButton className="mx-2 NSButton--secondary-lighten" callback={handleRedirect}>
                Go to Northspyre Projects App
            </NSButton>
            <div className="my-3">
                <Link to={URLS.TIFF} className="d-block">
                    Tiff
                </Link>
                <Link to={URLS.MARTIN}>Martin</Link>
                <Link to={generatePath(URLS.PRO_FORMA.HOME, { dealId: '111' })} className="d-block">
                    Pro Forma
                </Link>
                <Link to={URLS.REPORTS}>Reports</Link>
            </div>
            {responseMessage && <p>{responseMessage}</p>}
        </AppTemplateWithAuthorization>
    );
};

export default Counter;
