import { useMutation } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';

export const deleteTimeline = async ({ orgId, dealId, timelineId }: { orgId: string; dealId: string; timelineId: string }) => {
    const response = await axiosInstance.delete(API_ENDPOINTS.TIMELINE_BY_ID(orgId, dealId, timelineId));
    return response.data;
};

export const useDeleteTimeline = () =>
    useMutation({
        mutationFn: deleteTimeline,
    });
