import React from 'react';
import { NSInput, NSModal } from '../../../../bricks';

interface IEditProFormaModalProps {
    showEditProFormaModal: boolean;
    toggleEditProFormaModal: () => void;
}

const EditProFormaModal = ({ showEditProFormaModal, toggleEditProFormaModal }: IEditProFormaModalProps) => (
    <NSModal
        isOpen={showEditProFormaModal}
        toggle={toggleEditProFormaModal}
        modalHeaderText="Edit pro forma"
        modalHeaderClassName="text-white bg-primary"
        modalFooterButtonText="Save"
        modalFooterButtonFunction={() => {}}
    >
        <NSInput label="Name" type="text" name="name" placeholder="Enter pro forma name" labelClassName="mb-0" value="" onChange={() => {}} required />
        <NSInput
            label="Description"
            type="textarea"
            name="description"
            placeholder="Enter optional description for this pro forma"
            labelClassName="mb-0 mt-2"
            value=""
            onChange={() => {}}
        />
    </NSModal>
);

export default EditProFormaModal;
