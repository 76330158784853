import { PRO_FORMA_TABS, PRO_FORMA_TABLE_NAV, RETURNS_AND_WATERFALL_TABS } from './constants';

export const getProFormaUrl = (sideNavName: string, tabName?: string) => {
    const section = encodeURIComponent(sideNavName);
    let tab = '';

    if (!tabName) {
        if (sideNavName === PRO_FORMA_TABLE_NAV.PRO_FORMA.name) {
            tab = `&tab=${encodeURIComponent(PRO_FORMA_TABS.GENERAL_INFORMATION.name)}`;
        } else if (sideNavName === PRO_FORMA_TABLE_NAV.RETURNS_AND_WATERFALL.name) {
            tab = `&tab=${encodeURIComponent(RETURNS_AND_WATERFALL_TABS.RETURNS)}`;
        }
    } else {
        tab = `&tab=${encodeURIComponent(tabName)}`;
    }

    const url = `${window.location.pathname}?section=${section}${tab}`;

    return url;
};
