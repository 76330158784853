import NSSearchBar from 'bricks/NSSearchBar/NSSearchBar';
import React, { useState } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { NSButton } from 'bricks';
import { ATTRIBUTE_TYPES } from '../../constants';

export interface IProps {
    selectedColumns: string[];
    setSelectedColumns: (selectedColumns: string[]) => void;
}

const AddColumns = ({ selectedColumns, setSelectedColumns }: IProps) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const attributeTypes = ATTRIBUTE_TYPES.filter(name => {
        return name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
    });

    const handleAddColumn = (columnName: string) => {
        if (!selectedColumns.includes(columnName)) {
            setSelectedColumns([...selectedColumns, columnName]);
        }
    };

    return (
        <div className="AddColumns d-flex flex-column">
            <p className="text-white my-2">Add columns:</p>
            <NSSearchBar
                containerClassName="mb-0"
                searchValue={searchValue || ''}
                placeholder="Search columns"
                handleChangeSearchValue={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value)}
                id="report-options"
            />
            <OverlayScrollbarsComponent
                defer
                options={{
                    scrollbars: { autoHide: 'leave' },
                    overflow: { y: 'scroll' },
                }}
                className="OverlayScrollbarsComponent overflow-auto"
            >
                {attributeTypes.map(attributeName => {
                    return (
                        <div key={attributeName}>
                            <NSButton callback={() => handleAddColumn(attributeName)} className="mt-2 py-2 w-100" color="secondary" outline={false}>
                                <div className="text-white d-flex justify-content-between align-items-center">
                                    <h5 className="my-0">{attributeName}</h5>
                                </div>
                            </NSButton>
                        </div>
                    );
                })}
            </OverlayScrollbarsComponent>
        </div>
    );
};

export default AddColumns;
