import React from 'react';
import { NSCard, NSNavTabs } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import { PRO_FORMA_TABLE_NAV, RETURNS_AND_WATERFALL_TABS } from 'views/ProFormaTable/constants';
import classNames from 'classnames';

export interface IReturnsAndWaterfallProps {
    navigateToSection: (sideNavName: string, tabName?: string) => void;
    currentSection?: string;
    currentTab?: string;
}

const ReturnsAndWaterfall = ({ navigateToSection, currentSection, currentTab }: IReturnsAndWaterfallProps) => (
    <>
        <NSNavTabs
            containerClassName="px-0 w-50"
            navItems={Object.entries(RETURNS_AND_WATERFALL_TABS).map(([key, value], index) => ({
                callback: e => {
                    e.preventDefault();
                    navigateToSection(currentSection || PRO_FORMA_TABLE_NAV.RETURNS_AND_WATERFALL.name, value);
                },
                className: classNames('text-nowrap', {
                    'mr-1': index !== Object.keys(RETURNS_AND_WATERFALL_TABS).length - 1,
                }),
                name: value,
                isActive: currentTab === value,
                id: `returns-waterfall-tab-${index}`,
            }))}
        />
        <hr className="my-0" />
        <NSCard className="p-2">
            <RenderIf isTrue={currentTab === RETURNS_AND_WATERFALL_TABS.RETURNS}>
                <p>Details go here.</p>
            </RenderIf>
            <RenderIf isTrue={currentTab === RETURNS_AND_WATERFALL_TABS.WATERFALL_ANALYSIS}>
                <p>Details go here.</p>
            </RenderIf>
        </NSCard>
    </>
);

export default ReturnsAndWaterfall;
