import { NSCard, NSInput, NSLabel, NSSelect } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';
import RenderIf from 'components/RenderIf/RenderIf';
import { STATES_OPTIONS } from 'constants/statesList';
import React, { useState } from 'react';
import {
    FORM_LABEL_CLASS_NAME,
    FORM_ROW_CLASS_NAME,
    GENERAL_INFORMATION_EXPANDABLE_SECTIONS,
    DEAL_TYPES,
    dealTypeProFormaFormFields,
} from 'views/ProFormaTable/constants';
import ApartmentSizeDetails from './ApartmentSizeDetails/ApartmentSizeDetails';

const ProFormaGeneralInformation = () => {
    const deal = { assetType: { name: 'Apartment' } }; // TODO: this is a MOCK, update when we have deal type

    const initialValues: { [key: string]: any } = {
        dealName: '',
        address: '',
        city: '',
        county: '',
        state: STATES_OPTIONS[0].value,
        zip: null,
        startDate: '',
    };

    const selectedInitialValues = {
        ...initialValues,
        ...(dealTypeProFormaFormFields[deal.assetType.name] || {}),
    };
    const [values, setValues] = useState<{ [key: string]: any }>(selectedInitialValues);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
    };

    const handleSelect = (name: string, option: string | IOptionAnyValue | IOptionAnyValue[]) => {
        setValues({ ...values, [name]: option });
    };

    return (
        <>
            <ExpandableSection
                number={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[0].number}
                title={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[0].title}
                subtitle={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[0].subtitle}
                isExpanded
                animate
            >
                <NSCard className="p-3 NSCard--level-2">
                    <div className="d-flex">
                        <div className="d-flex flex-column w-50">
                            <div className={FORM_ROW_CLASS_NAME}>
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>Deal name</NSLabel>
                                <NSInput
                                    type="text"
                                    name="dealName"
                                    value={values.dealName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                    placeholder="Enter deal name"
                                />
                            </div>
                            <div className={FORM_ROW_CLASS_NAME}>
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>Address</NSLabel>
                                <NSInput
                                    type="text"
                                    name="address"
                                    value={values.address}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                    placeholder="Enter address"
                                />
                            </div>
                            <div className={FORM_ROW_CLASS_NAME}>
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>City</NSLabel>
                                <NSInput
                                    type="text"
                                    name="city"
                                    value={values.city}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                    placeholder="Enter city"
                                />
                            </div>
                            <div className={FORM_ROW_CLASS_NAME}>
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>County</NSLabel>
                                <NSInput
                                    type="text"
                                    name="county"
                                    value={values.county}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                    placeholder="Enter county"
                                />
                            </div>
                            <div className={FORM_ROW_CLASS_NAME}>
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>State</NSLabel>
                                <div className="col-4 pl-0">
                                    <NSSelect
                                        name="state"
                                        options={STATES_OPTIONS}
                                        value={{ label: values.state, value: values.state }}
                                        onChange={option => handleSelect('state', option.value)}
                                        isClearable={false}
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>Zip</NSLabel>
                                <div className="col-4 pl-0">
                                    <NSInput
                                        type="number"
                                        name="zip"
                                        value={values.zip}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                        placeholder="Enter zip code"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-50 ml-3 NSCard--level-3 p-2">
                            <div>map goes here</div>
                        </div>
                    </div>
                </NSCard>
            </ExpandableSection>

            <ExpandableSection
                number={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[1].number}
                title={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[1].title}
                subtitle={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[1].subtitle}
                isExpanded
                animate
            >
                <NSCard className="p-3 NSCard--level-2">
                    <div className="d-flex">
                        <div className="d-flex w-50">
                            <div className="d-flex w-100 my-auto">
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>Start month</NSLabel>
                                <NSInput
                                    type="date"
                                    name="startDate"
                                    value={values.startDate}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                />
                            </div>
                        </div>
                        <NSCard className="w-50 ml-3 p-2 NSCard--level-3">
                            <div>Sale month</div>
                            <div className="text-dark font-italic">In progress</div>
                        </NSCard>
                    </div>
                </NSCard>
            </ExpandableSection>

            <ExpandableSection
                number={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[2].number}
                title={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[2].title}
                subtitle={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[2].subtitle}
                isExpanded
                animate
            >
                <NSCard className="p-3 NSCard--level-2">
                    <RenderIf isTrue={deal.assetType.name === DEAL_TYPES.APARTMENT}>
                        <ApartmentSizeDetails
                            values={values}
                            setValues={setValues}
                            deal={deal}
                            handleInputChange={handleInputChange}
                            handleSelect={handleSelect}
                        />
                    </RenderIf>

                    <RenderIf isTrue={deal.assetType.name === DEAL_TYPES.INDUSTRIAL}>{/* <IndustrialSizeDetails /> */}</RenderIf>

                    <RenderIf isTrue={deal.assetType.name === DEAL_TYPES.RETAIL}>{/* <RetailSizeDetails /> */}</RenderIf>

                    <RenderIf isTrue={deal.assetType.name === DEAL_TYPES.OFFICE}>{/* <OfficeSizeDetails /> */}</RenderIf>
                </NSCard>
            </ExpandableSection>
        </>
    );
};

export default ProFormaGeneralInformation;
