/* eslint-disable no-unused-vars */

export enum DEVELOPMENT_SOURCES_TYPES {
    DEBT_YIELD = 'debt_yield',
    LTC = 'loan_to_cost',
    LTV = 'loan_to_value',
}

export const PRO_FORMA_DEVELOPMENT_SOURCES_OPTIONS = [
    {
        label: 'Debt yield',
        value: DEVELOPMENT_SOURCES_TYPES.DEBT_YIELD,
    },
    {
        label: 'LTC',
        value: DEVELOPMENT_SOURCES_TYPES.LTC,
    },
    {
        label: 'LTV',
        value: DEVELOPMENT_SOURCES_TYPES.LTV,
    },
];
