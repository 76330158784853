import React, { MouseEventHandler, useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp, IconProp } from '@fortawesome/fontawesome-svg-core';
import RenderIf from '../../components/RenderIf/RenderIf';
import { buttonCrudOperation, subprojectLifeStages, NSBUTTON_SIZES } from '../constants';

type IconPosition = 'left' | 'right';

export interface INSButtonProps {
    id?: string;
    color?: string;
    text?: string;
    icon?: IconProp;
    iconPosition?: IconPosition;
    size?: SizeProp;
    disabled?: boolean;
    disabledTooltip?: string | React.JSX.Element;
    tooltipAutohide?: boolean;
    callback?: Function;
    tooltip?: string | React.JSX.Element;
    className?: string;
    innerContentClassName?: string;
    includeTooltip?: boolean;
    crudOperation?: string;
    subprojectLifeStage?: number;
    iconOnly?: boolean;
    outline?: boolean;
    buttonType?: string;
    iconSize?: SizeProp | undefined;
    children?: React.ReactNode;
    formId?: string;
    onMouseDown?: MouseEventHandler<HTMLButtonElement>;
    isLoading?: boolean;
    dataTestId?: string;
}

const NSButton = ({
    id = '',
    color = '',
    text = '',
    icon,
    iconPosition = 'left',
    size,
    disabled = false,
    disabledTooltip = '',
    tooltipAutohide = true,
    callback = () => false,
    tooltip = '',
    className = '',
    innerContentClassName,
    includeTooltip = true,
    crudOperation = buttonCrudOperation.GET,
    subprojectLifeStage = subprojectLifeStages.ACTIVE,
    iconOnly = false,
    outline = false,
    buttonType = 'button',
    iconSize = 'sm',
    children = null,
    formId,
    onMouseDown = () => {},
    isLoading = false,
    dataTestId,
    ...rest
}: INSButtonProps) => {
    const [hover, setHover] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const toggleHover = () => setHover(!hover);

    const toggleTooltip = () => setShowTooltip(!showTooltip);

    const btnColor = color || '';
    const btnText = text || '';
    let btnTitle = '';
    const btnIcon = icon || null;
    const btnIconSize = iconSize || 'sm';
    const btnId = id || '';
    let btnTooltip: string | React.JSX.Element | null = tooltip || null;

    if (disabled) {
        btnTitle = (btnTooltip as string) || '';
        btnTooltip = disabledTooltip || '';
    }

    const loadingSpinner = () => (
        <span
            className={classNames('spinner-border', {
                'spinner-border-sm': size !== NSBUTTON_SIZES.LARGE,
            })}
            style={size === NSBUTTON_SIZES.LARGE ? { height: '25px', width: '25px' } : {}}
        />
    );

    const iconOnlyCallback = () => {
        if (callback && (!disabled || !isLoading)) {
            callback();
        }
    };

    return (
        <>
            <RenderIf isTrue={!iconOnly}>
                <Button
                    id={btnId}
                    onMouseEnter={toggleHover}
                    onMouseLeave={toggleHover}
                    onMouseDown={onMouseDown}
                    color={btnColor}
                    outline={outline}
                    disabled={disabled || isLoading}
                    size={size}
                    onClick={callback as React.MouseEventHandler<HTMLButtonElement> | undefined}
                    onKeyDown={callback as React.KeyboardEventHandler<HTMLButtonElement> | undefined}
                    title={btnTitle}
                    className={`NSButton rounded ${className || ''}`}
                    type={buttonType as 'button' | 'reset' | 'submit' | undefined}
                    data-testid={dataTestId}
                    {...(formId && { form: formId })}
                    {...rest}
                >
                    {/* wrapper workaround for showing tooltips when button is disabled. */}
                    <span id={`${btnId}-tooltip-wrapper`} data-testid="tooltip-wrapper" className={innerContentClassName}>
                        <RenderIf isTrue={isLoading}>{loadingSpinner()}</RenderIf>

                        <RenderIf isTrue={!isLoading}>
                            <RenderIf isTrue={btnIcon && iconPosition === 'left'}>
                                <FontAwesomeIcon className={`icon ${btnText ? 'mr-1' : ''}`} icon={btnIcon as IconProp} size={btnIconSize} />
                            </RenderIf>

                            {children || btnText}

                            <RenderIf isTrue={btnIcon && iconPosition === 'right'}>
                                <FontAwesomeIcon className={`icon ${btnText ? 'ml-1' : ''}`} icon={btnIcon as IconProp} size={btnIconSize} />
                            </RenderIf>
                        </RenderIf>
                    </span>
                </Button>

                <RenderIf isTrue={btnId && btnTooltip && includeTooltip}>
                    <Tooltip target={`${btnId}-tooltip-wrapper`} isOpen={showTooltip} toggle={toggleTooltip} autohide={tooltipAutohide}>
                        {btnTooltip}
                    </Tooltip>
                </RenderIf>
            </RenderIf>

            <RenderIf isTrue={iconOnly}>
                <FontAwesomeIcon
                    className={className}
                    icon={btnIcon as IconProp}
                    size={btnIconSize}
                    id={btnId}
                    onClick={iconOnlyCallback}
                    onKeyDown={iconOnlyCallback}
                    data-testid={dataTestId}
                    {...rest}
                />

                <RenderIf isTrue={btnTooltip}>
                    <Tooltip target={btnId as string | HTMLElement | React.RefObject<HTMLElement>} isOpen={showTooltip} toggle={toggleTooltip}>
                        {btnTooltip}
                    </Tooltip>
                </RenderIf>
            </RenderIf>
        </>
    );
};

export default NSButton;
