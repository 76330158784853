import { BASE_URL } from './constants';

export const V1 = `${BASE_URL}/api`;

export const URLS = {
    // Cloudfront url for images
    IMAGE_CLOUDFRONT_URL: (name: string) => `https://d24iv9sojvq5iq.cloudfront.net/images/${name}`,
    MARTIN: '/martin',
    TIFF: '/tiff',
    REPORTS: '/reports',
    DEALS: {
        HOME: '/deals',
        VIEW: '/deals/:dealId',
        FORM: '/deals/form/:dealId?',
    },
    UTILITIES: '/utilities',
    PRO_FORMA: {
        HOME: `/deal/:dealId/pro_forma`,
        BASE_URL: `/deal/:dealId/pro_forma/:proformaId`,
        WIZARD: `/deal/:dealId/pro_forma/wizard`,
        TABLE: `/deal/:dealId/pro_forma/:proformaId/table`,
    },
};

const API_ENDPOINTS = {
    BASE_URL,
    DEALS: (organizationId: string) => `${V1}/${organizationId}/deals`,
    DEALS_BY_ID: (organizationId: string, dealId: number) => `${V1}/${organizationId}/deals/${dealId}`,
    REPORTS: (orgId: string) => `${V1}/${orgId}/reports`,
    TIMELINES: (orgId: string, dealId: string) => `${V1}/${orgId}/deals/${dealId}/timelines`,
    TIMELINE_BY_ID: (orgId: string, dealId: string, timelineId: string) => `${V1}/${orgId}/deals/${dealId}/timelines/${timelineId}`,
    BACK_OF_ENVELOPE: (orgId: string) => `${V1}/${orgId}/back-of-envelope`,
    BACK_OF_ENVELOPE_BY_ID: (orgId: string, scenarioId: string) => `${V1}/${orgId}/back-of-envelope/${scenarioId}`,
    PRO_FORMA: (dealId: number) => `${V1}/deals/${dealId}/pro_forma`,
};

export default API_ENDPOINTS;
