import React, { Dispatch, SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import RenderIf from 'components/RenderIf/RenderIf';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { URLS } from 'services/urls';
import { ICreateProFormaStep2Card } from 'views/CreateProFormaModal/types';
import NSRadioCard from 'components/NSRadioCard/NSRadioCard';

export interface IProFormaStep2OptionCardProps {
    stepSelection: string;
    setStepSelection: Dispatch<SetStateAction<string>>;
    card: ICreateProFormaStep2Card;
}

const ProFormaStep2OptionCard = ({ stepSelection, setStepSelection, card }: IProFormaStep2OptionCardProps) => {
    const cloudfrontImage = URLS.IMAGE_CLOUDFRONT_URL(card.image);

    const cardClassName = classNames('p-2 mb-2 NSCard--level-4', {
        'border border-primary': stepSelection === card.name,
        'border border-secondary': stepSelection !== card.name,
    });

    return (
        <NSRadioCard cardClassName={cardClassName} onClick={() => setStepSelection(card.name)}>
            <div className="d-flex position-relative justify-content-between my-1">
                <FontAwesomeIcon
                    icon={card.icon}
                    size="lg"
                    className={classNames('NSRadioCard__absolute-icon--bottom position-absolute rounded-circle p-2 text-dark', {
                        'bg-primary': stepSelection === card.name,
                        'bg-secondary': stepSelection !== card.name,
                    })}
                />
                <div className="ml-3 mb-2">
                    <img src={cloudfrontImage} alt="pro forma starting frame" className="NSRadioCard__static-image--fixed-width" />
                </div>

                <div className="pr-1">
                    <RenderIf isTrue={stepSelection === card.name}>
                        <FontAwesomeIcon icon={faDotCircle} className="text-primary NSRadioCard__radio-btn--selected" />
                    </RenderIf>
                    <RenderIf isTrue={stepSelection !== card.name}>
                        <FontAwesomeIcon icon={faCircle} />
                    </RenderIf>
                </div>
            </div>

            <div className="h6 my-1">{card.time}</div>
            <div className="my-0 text-dark h4">{card.name}</div>
            <div className="h6 my-1">{card.description}</div>
        </NSRadioCard>
    );
};

export default ProFormaStep2OptionCard;
