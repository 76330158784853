import { NSButton, NSDropdown } from 'bricks';
import PageTitle from 'components/PageTitle/PageTitle';
import React, { useMemo, useState } from 'react';
import { IOptionAnyValue } from 'bricks/types';
import TitleInlineEdit from './TitleInlineEdit';
import { IReport } from '../types';

export interface IReportHeaderProps {
    reportName: string;
    reportDate?: string;
    isEditing?: boolean;
    onClickBack: () => void;
    onChangeName: () => void;
    onClickExport: () => void;
    exportDisabled?: boolean;
    onClickSave: () => void;
    saveDisabled?: boolean;
    onClickEdit: () => void;
    savedReports?: Pick<IReport, 'name'>[];
    onChangeSavedReport?: (report: Pick<IReport, 'name'>) => void;
}

export const ReportHeader = ({
    reportName,
    reportDate,
    exportDisabled = false,
    saveDisabled = false,
    isEditing = false,
    onClickBack,
    onChangeName,
    onClickExport,
    onClickEdit,
    onClickSave,
    savedReports,
    onChangeSavedReport,
}: IReportHeaderProps) => {
    const [selectedSavedReport, setSelectedSavedReport] = useState<IOptionAnyValue>();
    const [isNameValid, setIsNameValid] = useState(true);

    const savedReportsOptions: IOptionAnyValue[] = useMemo(() => {
        if (savedReports) {
            return savedReports.map(report => ({
                label: report.name,
                value: report.name,
            }));
        }
        return [];
    }, [savedReports]);

    const currentDate = new Date();
    const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
    const subtitle = reportDate || formattedDate;

    const handleChangeSavedReportSelection = (option: IOptionAnyValue) => {
        setSelectedSavedReport(option);
        onChangeSavedReport?.({ name: option.label });
    };

    return (
        <PageTitle
            title={<TitleInlineEdit reportName={reportName} onChangeName={onChangeName} isEditing={isEditing} onValidationChange={setIsNameValid} />}
            subtitle={subtitle}
            breadcrumbBackLinkText="Back"
            breadcrumbBackLinkCallback={onClickBack}
        >
            <div className="d-flex">
                {savedReports?.length && (
                    <NSDropdown
                        containerClassName="mr-2"
                        options={savedReportsOptions}
                        selectedOption={selectedSavedReport}
                        handleSelectOption={handleChangeSavedReportSelection}
                        placeholder="Select saved report"
                    />
                )}
                {!isEditing && <NSButton className="btn-secondary mr-2" callback={onClickExport} disabled={exportDisabled} text="Export" />}
                {isEditing ? (
                    <NSButton
                        className="btn-primary"
                        callback={onClickSave}
                        text="Save report"
                        dataTestId="save-report-btn"
                        disabled={!isNameValid || saveDisabled}
                    />
                ) : (
                    <NSButton className="btn-secondary" callback={onClickEdit} text="Edit" />
                )}
            </div>
        </PageTitle>
    );
};

export default ReportHeader;
