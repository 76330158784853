import { useMutation } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';

export const deleteReports = async ({ orgId, reportIds }: { orgId: string; reportIds: number[] }) => {
    const response = await axiosInstance.delete(API_ENDPOINTS.REPORTS(orgId), { data: { report_ids: reportIds } });
    return response.data;
};

export const useDeleteReports = () =>
    useMutation({
        mutationFn: deleteReports,
    });
