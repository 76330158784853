import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { IDealsQueryParams, IDealsResponse } from '../types';

const fetchDeals = async ({ pageSize, currentPage, orgId, searchValue }: IDealsQueryParams): Promise<IDealsResponse> => {
    const includeSearch = searchValue ? `&search=${searchValue}` : '';
    const response = await axiosInstance.get(`${API_ENDPOINTS.DEALS(orgId)}?page=${currentPage - 1}&page_size=${pageSize}${includeSearch}`);
    return response.data;
};

export const useFetchDeals = ({ pageSize, currentPage, orgId, searchValue }: IDealsQueryParams) =>
    useQuery({
        queryKey: ['deals', orgId, currentPage, pageSize, searchValue],
        queryFn: () => fetchDeals({ pageSize, currentPage, orgId, searchValue }),
        staleTime: 5000,
        refetchOnMount: true,
    });
