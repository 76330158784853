import React, { useMemo } from 'react';
import { Button } from 'reactstrap';
import { useAuth0TokenContext } from '../contexts/Auth0TokenContext';

interface AppTemplateWithAuthorizationProps {
    children: React.ReactNode;
}

const AppTemplateWithAuthorization = ({ children }: AppTemplateWithAuthorizationProps) => {
    const { isLoading, isAuthenticated, login, logout } = useAuth0TokenContext();

    const loginOrLogoutButton = useMemo(
        () =>
            isAuthenticated ? (
                <Button
                    onClick={() => {
                        return logout({
                            logoutParams: {
                                returnTo: window.location.origin,
                            },
                        });
                    }}
                    className="mx-2"
                >
                    Logout
                </Button>
            ) : (
                <Button className="mx-2" onClick={login}>
                    Login
                </Button>
            ),
        [isAuthenticated],
    );

    const content = useMemo(() => (isAuthenticated ? children : null), [isAuthenticated, children]);

    return (
        <div className="App">
            <header className="App-header mb-4">
                <p>Welcome to Northspyre Deal</p>
                {isLoading ? null : loginOrLogoutButton}
            </header>
            <div>{isLoading ? 'Loading...' : content}</div>
        </div>
    );
};

export default AppTemplateWithAuthorization;
