import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsFromLine } from '@fortawesome/pro-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NSTable, NSCheckbox, NSButton, NSDropdown } from '../../../../../../bricks';
import { PRO_FORMA_DEVELOPMENT_UNIT_OPTIONS } from '../../../../../../constants/unitTypes';
import { IOptionAnyValue } from '../../../../../../bricks/types';

const DevelopmentUsesTable: React.FC = () => {
    const [usesUnitType, setUsesUnitType] = useState<IOptionAnyValue>(PRO_FORMA_DEVELOPMENT_UNIT_OPTIONS?.[0]);

    return (
        <NSTable>
            <thead className="NSTable__thead">
                <tr className="NSTable__thead__tr">
                    <th className="NSTable__thead__tr__th NSTable__thead__tr__th--right-border">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <NSCheckbox id="uses-table-select-all" checked={false} callback={() => {}} />
                                <FontAwesomeIcon className="mr-2 text-muted" icon={faArrowsFromLine} />
                                <span className="ml-0">Uses</span>
                            </div>
                            <NSButton className="px-2 text-muted" size="sm" iconOnly icon={faPlus} callback={() => {}} />
                        </div>
                    </th>
                    <th className="NSTable__thead__tr__th">Start</th>
                    <th className="NSTable__thead__tr__th">End</th>
                    <th className="NSTable__thead__tr__th">Curve</th>
                    <th className="NSTable__thead__tr__th">
                        <div className="d-flex justify-content-end">
                            <span className="mr-1">$ /</span>
                            <NSDropdown
                                options={PRO_FORMA_DEVELOPMENT_UNIT_OPTIONS}
                                selectedOption={usesUnitType}
                                handleSelectOption={setUsesUnitType}
                                badgeColor="dark-lighten"
                                isBadgeToggle
                            />
                        </div>
                    </th>
                    <th className="NSTable__thead__tr__th text-right">Amount</th>
                </tr>
            </thead>
            <tbody className="NSTable__tbody">
                <tr className="NSTable__tbody__tr">
                    <td className="NSTable__tbody__tr__td NSTable__tbody__tr__td--right-border">Cell 1</td>
                    <td className="NSTable__tbody__tr__td">Cell 2</td>
                    <td className="NSTable__tbody__tr__td">Cell 3</td>
                    <td className="NSTable__tbody__tr__td">Cell 4</td>
                    <td className="NSTable__tbody__tr__td">
                        <div className="d-flex justify-content-end">Cell 5</div>
                    </td>
                    <td className="NSTable__tbody__tr__td">
                        <div className="d-flex justify-content-end">Cell 6</div>
                    </td>
                </tr>
            </tbody>
            <tfoot className="NSTable__tfoot">
                <tr className="NSTable__tfoot__tr">
                    <td className="NSTable__tfoot__tr__td NSTable__tfoot__tr__td--right-border text-left" colSpan={4}>
                        Total
                    </td>
                    <td className="NSTable__tfoot__tr__td text-right">$38.24</td>
                    <td className="NSTable__tfoot__tr__td text-right">$1,500,000</td>
                </tr>
            </tfoot>
        </NSTable>
    );
};

export default DevelopmentUsesTable;
