import React from 'react';
import { Col, Row } from 'reactstrap';
import { Deal } from 'views/Deals/types';
import Details from './Details/Details';
import Timeline from './Timeline/Timeline';
import TargetReturns, { TargetReturnsValues } from './TargetReturns/TargetReturns';

interface IOverviewProps {
    deal: Deal;
}

const Overview = ({ deal }: IOverviewProps) => {
    const handleTargetReturnsSave = (values: TargetReturnsValues) => {
        console.log(values);
    };

    return (
        <Row className="g-0 h-100">
            <Col md="4" className="d-flex flex-column h-100 border-end">
                <Details deal={deal} />
            </Col>

            <Col md="8" className="d-flex flex-column h-100">
                <div className="flex-shrink-0">
                    <TargetReturns onSave={handleTargetReturnsSave} />
                </div>
                <div className="flex-grow-1 overflow-auto mt-3">
                    <Timeline dealId={deal.id} />
                </div>
            </Col>
        </Row>
    );
};

export default Overview;
