import React from 'react';
import ReportHeader from 'views/Reports/ReportHeader/ReportHeader';
import ConfigurationSidebar from './ConfigurationSidebar/ConfigurationSidebar';

export interface IPipelineReportProps {
    onClickBack: () => void;
}

const PipelineReport = ({ onClickBack }: IPipelineReportProps) => {
    return (
        <div className="m-3">
            <ReportHeader
                reportName="New Pipeline Report"
                onClickBack={onClickBack}
                onChangeName={() => console.log('change name')}
                onClickExport={() => console.log('export')}
                onClickSave={() => console.log('save')}
                onClickEdit={() => console.log('edit')}
            />
            <div className="pipelineReports d-flex mt-3 vh-100">
                <ConfigurationSidebar />
                <div className="dealTable ml-2 w-75">TABLE</div>
            </div>
        </div>
    );
};

export default PipelineReport;
