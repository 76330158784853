import React, { useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import CreateProFormaModal from 'views/CreateProFormaModal/CreateProFormaModal';
import PageTitle from '../../components/PageTitle/PageTitle';
import RenderIf from '../../components/RenderIf/RenderIf';
import { NSButton, NSNavTabs } from '../../bricks';
import { PRO_FORMA_HOME_TABS, NORTHSPYRE_PRO_FORMAS, UPLOADED_PRO_FORMAS } from './constants';
import NorthspyreProFormasTable from './components/NorthspyreProFormasTable/NorthspyreProFormasTable';
import UpdatedProFormasTable from './components/UploadedProFormasTable/UploadedProFormasTable';

export interface IProFormaHomeProps {
    dealId?: number;
}

const ProFormaHome = ({ dealId }: IProFormaHomeProps) => {
    const [activeTabName, setActiveTabName] = useState(NORTHSPYRE_PRO_FORMAS);
    const [openCreateProFormaModal, setOpenCreateProFormaModal] = useState(false);

    const toggleOpenProformaModal = () => setOpenCreateProFormaModal(!openCreateProFormaModal);

    return (
        <>
            <PageTitle title="Pro Forma" displayBreadcrumb dealName="4th Street Highrise" pageMetaDataTitle="Pro Forma Home">
                <NSButton color="secondary" outline={false} callback={() => {}} text="Upload" className="mr-2" />
                <NSButton color="primary" outline={false} callback={toggleOpenProformaModal} text="Create pro forma" />
            </PageTitle>

            <NSNavTabs
                containerClassName="mb-0 text-dark d-flex justify-content-start mt-3"
                navItems={PRO_FORMA_HOME_TABS.map((navName, index) => ({
                    callback: () => setActiveTabName(navName),
                    name: navName,
                    isActive: activeTabName === navName,
                    id: `pro-forma-home-nav-tab-${index}`,
                    className: 'text-dark text-nowrap px-4',
                }))}
            />

            <TabContent activeTab={activeTabName}>
                <TabPane tabId={NORTHSPYRE_PRO_FORMAS}>
                    <RenderIf isTrue={activeTabName === NORTHSPYRE_PRO_FORMAS}>
                        <NorthspyreProFormasTable />
                    </RenderIf>
                </TabPane>
                <TabPane tabId={UPLOADED_PRO_FORMAS}>
                    <RenderIf isTrue={activeTabName === UPLOADED_PRO_FORMAS}>
                        <UpdatedProFormasTable />
                    </RenderIf>
                </TabPane>
            </TabContent>

            <RenderIf isTrue={openCreateProFormaModal}>
                <CreateProFormaModal toggle={toggleOpenProformaModal} isOpen={openCreateProFormaModal} />
            </RenderIf>
        </>
    );
};

export default ProFormaHome;
