import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import './ActionMenu.scss';
import { NSButton } from 'bricks';

const ActionMenu = ({ onView, onDelete }: { onView: () => void; onDelete: () => void }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const toggleMenu = () => setIsOpen(prev => !prev);

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            window.document.addEventListener('mousedown', handleClickOutside);
        } else {
            window.document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            window.document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div ref={menuRef} className="ActionMenu">
            <NSButton callback={toggleMenu} className="cursor--pointer" color="#3B3F4C">
                <FontAwesomeIcon icon={faEllipsisV} />
            </NSButton>
            {isOpen && (
                <div className="ActionMenu__dropdown">
                    <ul className="ActionMenu__list">
                        <li>
                            <button
                                type="button"
                                onClick={() => {
                                    onView();
                                    setIsOpen(false);
                                }}
                                className="ActionMenu__dropdown__item cursor--pointer"
                            >
                                Edit
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                onClick={() => {
                                    onDelete();
                                    setIsOpen(false);
                                }}
                                className="ActionMenu__dropdown__item cursor--pointer"
                            >
                                Delete
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};
export default ActionMenu;
