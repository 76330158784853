import React, { useMemo } from 'react';
import { Button } from 'reactstrap';
import { Deal } from 'views/Deals/types';
import Map from 'components/Map/Map';
import { Link } from 'react-router-dom';
import { URLS } from 'services/urls';
import { DEFAULT_MAP_CENTER } from 'views/Deals/MapView/MapView';
import DetailText from './components/DetailText';

interface IDetailsProps {
    deal: Deal;
}
const Details = ({ deal }: IDetailsProps) => {
    const details = useMemo(
        () => [
            { id: 1, label: 'County', value: deal.county },
            { id: 2, label: 'State', value: deal.state },
            { id: 3, label: 'City', value: deal.city },
            { id: 4, label: 'Zip Code', value: deal.zipCode },
            { id: 5, label: 'Market', value: deal.market },
            { id: 6, label: 'Submarket', value: deal.market },
            { id: 7, label: 'Creation date', value: deal.createdAt },
            { id: 8, label: 'Deal type', value: deal.market },
            { id: 9, label: 'Asset type', value: deal.constructionType },
            { id: 10, label: 'Parking type', value: deal.parkingType },
            { id: 11, label: 'Phase', value: deal?.phase?.name },
            { id: 12, label: 'Lead source', value: deal?.leadSource?.name },
            { id: 13, label: 'Leasing strategy', value: deal?.leasingStrategy?.name },
            { id: 14, label: 'Rank', value: deal?.rank?.name },
            { id: 15, label: 'Acquisition price', value: deal.acquisitionPrice },
            { id: 16, label: 'IRR', value: deal.irr },
            { id: 17, label: 'Equity multiple', value: deal.equityMultiple },
            { id: 18, label: 'Created by', value: deal.dealCreator },
            { id: 19, label: 'Deal owner', value: deal?.dealOwner?.name },
            { id: 20, label: 'Development Manager', value: deal?.developmentManager?.name },
            { id: 21, label: 'Investor', value: deal.investors?.[0]?.name },
            { id: 22, label: 'Lender', value: deal.lenders?.[0]?.name },
        ],
        [deal],
    );

    return (
        <div className="DealDashboard__radius-none d-flex flex-column card p-2 shadow-none overflow-hidden NSCard--level-3">
            <div className="d-flex justify-content-between align-items-center mb-3 DealDashboard__separator">
                <h5>Details</h5>
                <Link to={URLS.DEALS.FORM} state={{ deal }}>
                    <Button color="link" className="text-primary">
                        Edit details
                    </Button>
                </Link>
            </div>

            <div className="DealDashboard__hide-scrollbar d-flex flex-column flex-grow-1 overflow-auto">
                <div className="d-flex flex-column">
                    <p className="pb-1 DealDashboard__separator">
                        <span className="text-muted">Description:</span>
                        <br />
                        <span className="text-dark">{deal.description}</span>
                    </p>
                    {details.map(({ label, value, id }) => (
                        <DetailText key={id} label={label} value={value} />
                    ))}
                </div>

                <div className="mt-3 flex-grow-1 d-flex position-relative">
                    <Map lat={deal?.address_center?.lat ?? DEFAULT_MAP_CENTER.lat} lng={deal?.address_center?.lng ?? DEFAULT_MAP_CENTER.lng} />
                </div>
            </div>
        </div>
    );
};

export default Details;
