import React, { useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NSTable, NSButton, NSDropdown } from 'bricks';
import { PRO_FORMA_DEVELOPMENT_UNIT_OPTIONS } from 'constants/unitTypes';
import { PRO_FORMA_DEVELOPMENT_SOURCES_OPTIONS } from 'constants/sources';
import { IOptionAnyValue } from 'bricks/types';

const DevelopmentSourcesTable: React.FC = () => {
    const [sourcesUnitType, setSourcesUnitType] = useState<IOptionAnyValue>(PRO_FORMA_DEVELOPMENT_UNIT_OPTIONS?.[0]);
    const [sourcesType, setSourcesType] = useState<IOptionAnyValue>(PRO_FORMA_DEVELOPMENT_SOURCES_OPTIONS?.[0]);

    return (
        <NSTable>
            <thead className="NSTable__thead">
                <tr className="NSTable__thead__tr">
                    <th className="NSTable__thead__tr__th NSTable__thead__tr__th--right-border" colSpan={4}>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <span className="ml-0">Name</span>
                            </div>
                            <NSButton className="px-2 text-muted" size="sm" iconOnly icon={faPlus} callback={() => {}} />
                        </div>
                    </th>
                    <th className="NSTable__thead__tr__th" colSpan={2}>
                        <div className="d-flex justify-content-end">
                            <NSDropdown
                                options={PRO_FORMA_DEVELOPMENT_SOURCES_OPTIONS}
                                selectedOption={sourcesType}
                                handleSelectOption={setSourcesType}
                                badgeColor="dark-lighten"
                                isBadgeToggle
                            />
                        </div>
                    </th>
                    <th className="NSTable__thead__tr__th" colSpan={2}>
                        <div className="d-flex justify-content-end">Fund. traunch</div>
                    </th>
                    <th className="NSTable__thead__tr__th" colSpan={2}>
                        <div className="d-flex justify-content-end">% of sources</div>
                    </th>
                    <th className="NSTable__thead__tr__th" colSpan={2}>
                        <div className="d-flex justify-content-end">
                            <span className="mr-1">$ /</span>
                            <NSDropdown
                                options={PRO_FORMA_DEVELOPMENT_UNIT_OPTIONS}
                                selectedOption={sourcesUnitType}
                                handleSelectOption={setSourcesUnitType}
                                badgeColor="dark-lighten"
                                isBadgeToggle
                            />
                        </div>
                    </th>
                    <th className="NSTable__thead__tr__th text-right" colSpan={2}>
                        Amount
                    </th>
                </tr>
            </thead>
            <tbody className="NSTable__tbody">
                <tr className="NSTable__tbody__tr">
                    <td className="NSTable__tbody__tr__td" colSpan={4}>
                        <div className="ml-2">Cell 1</div>
                    </td>
                    <td className="NSTable__tbody__tr__td" colSpan={2}>
                        <div className="d-flex justify-content-end">Cell 2</div>
                    </td>
                    <td className="NSTable__tbody__tr__td" colSpan={2}>
                        <div className="d-flex justify-content-end">Cell 3</div>
                    </td>
                    <td className="NSTable__tbody__tr__td" colSpan={2}>
                        <div className="d-flex justify-content-end">Cell 4</div>
                    </td>
                    <td className="NSTable__tbody__tr__td" colSpan={2}>
                        <div className="d-flex justify-content-end">Cell 5</div>
                    </td>
                    <td className="NSTable__tbody__tr__td" colSpan={2}>
                        <div className="d-flex justify-content-end">Cell 6</div>
                    </td>
                </tr>
            </tbody>
            <tfoot className="NSTable__tfoot">
                <tr className="NSTable__tfoot__tr">
                    <td className="NSTable__tfoot__tr__td NSTable__tfoot__tr__td--right-border text-left" colSpan={4}>
                        <div className="ml-2">Total</div>
                    </td>

                    <td className="NSTable__tfoot__tr__td" colSpan={2} />
                    <td className="NSTable__tfoot__tr__td" colSpan={2} />
                    <td className="NSTable__tfoot__tr__td text-right" colSpan={2}>
                        100%
                    </td>
                    <td className="NSTable__tfoot__tr__td text-right" colSpan={2}>
                        $38.24
                    </td>
                    <td className="NSTable__tfoot__tr__td text-right" colSpan={2}>
                        $1,500,000
                    </td>
                </tr>
            </tfoot>
        </NSTable>
    );
};

export default DevelopmentSourcesTable;
