import axios from 'axios';

let accessToken: string | null = null;

export const setAxiosAccessToken = (token: string | null) => {
    accessToken = token;
};

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    config => {
        if (accessToken) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    error => Promise.reject(error),
);

export default axiosInstance;
