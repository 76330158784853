import React from 'react';
import { NSCard } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import { Deal } from 'views/Deals/types';
import { BOEScenario } from '../types';
import { formatBOEValues, generateScenarioReturns } from '../helper';
import './ScenarioReturns.scss';

export interface IScenarioReturnsProps {
    boeScenario: BOEScenario;
    deal?: Deal;
}

const ScenarioReturns = ({ boeScenario, deal }: IScenarioReturnsProps) => {
    const scenarioReturns = generateScenarioReturns(boeScenario, deal);
    return (
        <div>
            <span className="text-muted">SCENARIO RETURNS</span>
            <div className="d-flex flex-row" style={{ gap: 16 }}>
                {scenarioReturns.map(scenario => (
                    <NSCard
                        key={scenario.title[0]}
                        className={classNames('py-2 px-3 flex-grow-1 ScenarioReturns__card', {
                            'ScenarioReturns__card--success': scenario.displayMode === 'success',
                            'ScenarioReturns__card--error': scenario.displayMode === 'danger',
                        })}
                    >
                        <div className="d-flex flex-row justify-content-between">
                            <span className="text-muted">{scenario.title[0]}</span>
                            <div className="d-flex flex-row align-items-center">
                                <FontAwesomeIcon icon={faBullseyeArrow} className="text-success pr-1" />
                                <span>{formatBOEValues(Number(scenario.target), scenario.formatter[0])}</span>
                            </div>
                        </div>
                        <h3 className="text-white bold mb-0">{formatBOEValues(Number(scenario.value[0]), scenario.formatter[0])}</h3>
                    </NSCard>
                ))}
            </div>
        </div>
    );
};

export default ScenarioReturns;
