import React, { MouseEventHandler } from 'react';
import { Badge, BadgeProps } from 'reactstrap';
import classNames from 'classnames';
import { NORTHSPYRE_TIER_TYPES, SUBPROJECT_STAGES } from '../constants';

/*
NSBadge Props
`tier`: assigns the color for the specified subproject tier
    - no need to pass in the `color` prop if `tier` prop is passed in
`color`: string for bootstrap badge colors
    - 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'
    - 'primary-lighten', 'success-lighten', 'danger-lighten', 'warning-lighten',
    'light-lighten', 'info-lighten', 'dark-lighten'
*/

export interface INSBadgeProps extends BadgeProps {
    tier?:
        | NORTHSPYRE_TIER_TYPES.NORTHSPYRE_PLAN
        | NORTHSPYRE_TIER_TYPES.NORTHSPYRE_PLAN_PLUS
        | NORTHSPYRE_TIER_TYPES.NORTHSPYRE_PRO
        | NORTHSPYRE_TIER_TYPES.NORTHSPYRE_HISTORICAL_DATA
        | NORTHSPYRE_TIER_TYPES.NORTHSPYRE_LITE
        | NORTHSPYRE_TIER_TYPES.NORTHSPYRE_FOR_VENDORS
        | NORTHSPYRE_TIER_TYPES.NORTHSPYRE_ENTERPRISE;
    stage?:
        | SUBPROJECT_STAGES.OPPORTUNITY
        | SUBPROJECT_STAGES.DUE_DILIGENCE
        | SUBPROJECT_STAGES.PREDEVELOPMENT
        | SUBPROJECT_STAGES.CONSTRUCTION
        | SUBPROJECT_STAGES.STABILIZATION
        | SUBPROJECT_STAGES.COMPLETED
        | SUBPROJECT_STAGES.ARCHIVED;
    className?: string;
    children: React.ReactNode;
    color?: string;
    onClick?: MouseEventHandler<HTMLSpanElement>;
    role?: string;
    dataTestId?: string;
}

const NSBadge = ({
    tier,
    className = '',
    style = {},
    children,
    color = 'secondary',
    id,
    onClick = () => {},
    role = '',
    stage,
    dataTestId,
    ...rest
}: INSBadgeProps) => {
    const badgeClassName = classNames([
        'NSBadge',
        className,
        {
            'NSBadge--purple': tier === NORTHSPYRE_TIER_TYPES.NORTHSPYRE_PLAN,
            'NSBadge--dark-blue': tier === NORTHSPYRE_TIER_TYPES.NORTHSPYRE_PLAN_PLUS,
            'NSBadge--blue': tier === NORTHSPYRE_TIER_TYPES.NORTHSPYRE_PRO || tier === NORTHSPYRE_TIER_TYPES.NORTHSPYRE_ENTERPRISE,
            'NSBadge--gray': tier === NORTHSPYRE_TIER_TYPES.NORTHSPYRE_HISTORICAL_DATA,
            'NSBadge--indigo': tier === NORTHSPYRE_TIER_TYPES.NORTHSPYRE_LITE,
            'NSBadge--orange': tier === NORTHSPYRE_TIER_TYPES.NORTHSPYRE_FOR_VENDORS,
            'NSBadge--yellow': stage === SUBPROJECT_STAGES.OPPORTUNITY,
            'NSBadge--lime-green': stage === SUBPROJECT_STAGES.DUE_DILIGENCE,
            'NSBadge--green': stage === SUBPROJECT_STAGES.PREDEVELOPMENT,
            'NSBadge--cyan': stage === SUBPROJECT_STAGES.CONSTRUCTION,
            'NSBadge--light-blue': stage === SUBPROJECT_STAGES.STABILIZATION,
            'NSBadge--dark-cyan': stage === SUBPROJECT_STAGES.COMPLETED,
            'NSBadge--light-gray': stage === SUBPROJECT_STAGES.ARCHIVED,
        },
    ]);

    return (
        <Badge className={badgeClassName} color={color} id={id} onClick={onClick} role={role} data-testid={dataTestId} {...rest}>
            {children}
        </Badge>
    );
};

export default NSBadge;
