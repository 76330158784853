import React, { useRef, useState } from 'react';
import { NSButton } from 'bricks';
import PageTitle from 'components/PageTitle/PageTitle';
import Map from 'components/Map/Map';
import DealForm from 'components/DealForm/DealForm';
import './CreateDeal.scss';
import { FormikProps } from 'formik';
import { IFormValues } from 'components/DealForm/types';
import { useLocation, useNavigate } from 'react-router-dom';

const CreateDeal = () => {
    const [mapCoordinates, setMapCoordinates] = useState({
        center: { lat: 39.8283, lng: -98.5795 }, // Center of the US
    });

    const formRef = useRef<FormikProps<IFormValues>>(null);
    const navigate = useNavigate();
    const location = useLocation();

    const dealDetails = location.state?.deal;
    const isEditMode = !!dealDetails;

    const handleFinishClick = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div>
            <PageTitle
                title={isEditMode ? 'Edit deal details' : 'Create deal'}
                subtitle={isEditMode ? 'Modify existing information about this deal.' : 'Fill out the information below to populate this deal.'}
            >
                <NSButton color="secondary" callback={handleGoBack} className="mr-2" text="Exit" />
                <NSButton color="primary" callback={handleFinishClick} text={isEditMode ? 'Save' : 'Finish'} data-testid="finish-button" />
            </PageTitle>
            <div className="d-flex mt-2">
                <DealForm ref={formRef} setMapCoordinates={setMapCoordinates} initialValues={dealDetails} />

                <div className="w-50 position-relative">
                    <div style={{ height: '100%', width: '100%' }}>
                        <Map lng={mapCoordinates.center.lng} lat={mapCoordinates.center.lat} zoom={3} hideMarker />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateDeal;
