import { config } from 'config';

export const LOCAL_URL = 'https://localhost:8000';
const PROD_URL = 'https://deal.northspyre.com';
export const STAGING_URL = 'https://deal-staging.northspyre.com';
export const QA_URL = 'https://qa.deal.northspyre.com';
export const PM_URL = 'https://pm.deal.northspyre.com';

const STAGING_INDEX_NAME = 'northspyre_staging_budget_lines';
const PROD_INDEX_NAME = 'northspyre_budget_lines';
const PROD_GLOBAL_VENDOR_INDEX_NAME = 'prod_global_vendors';
export const PROD_GLOBAL_VENDOR_DESC_INDEX_NAME = 'prod_global_vendors_desc';
const STAGING_GLOBAL_VENDOR_INDEX_NAME = 'staging_global_vendors';

// Universal Search index names
const PROD_UNIVERSAL_SEARCH_INDEX_NAME = 'prod_universal_search';
const STAGING_UNIVERSAL_SEARCH_INDEX_NAME = 'staging_universal_search';
const LOCAL_UNIVERSAL_SEARCH_INDEX_NAME = 'test_universal_search';

export const ENVIRONMENTS = {
    /*  eslint-disable no-unused-vars */
    DEV: 'development',
    STAGING: 'staging',
    QA: 'qa',
    PM: 'pm',
    PROD: 'production',
};

const getEnvironmentalVariables = (): { [key: string]: string } => {
    if (config.environment === ENVIRONMENTS.DEV) {
        return {
            BASE_URL: LOCAL_URL,
            ALGOLIA_INDEX_NAME: PROD_INDEX_NAME,
            ALGOLIA_GLOBAL_VENDOR_INDEX_NAME: PROD_GLOBAL_VENDOR_INDEX_NAME,
            ALGOLIA_UNIVERSAL_SEARCH_INDEX_NAME: LOCAL_UNIVERSAL_SEARCH_INDEX_NAME,
        };
    }
    if (config.environment === ENVIRONMENTS.STAGING) {
        return {
            BASE_URL: STAGING_URL,
            ALGOLIA_INDEX_NAME: STAGING_INDEX_NAME,
            ALGOLIA_GLOBAL_VENDOR_INDEX_NAME: STAGING_GLOBAL_VENDOR_INDEX_NAME,
            ALGOLIA_UNIVERSAL_SEARCH_INDEX_NAME: STAGING_UNIVERSAL_SEARCH_INDEX_NAME,
        };
    }
    // TODO: uncomment this enviroment creation after define how the enviroment would be loaded
    // if (process.env.ENVIRONMENT === ENVIRONMENTS.QA) {
    //     return {
    //         BASE_URL: QA_URL,
    //         ALGOLIA_INDEX_NAME: PROD_INDEX_NAME,
    //         ALGOLIA_GLOBAL_VENDOR_INDEX_NAME: PROD_GLOBAL_VENDOR_INDEX_NAME,
    //         ALGOLIA_UNIVERSAL_SEARCH_INDEX_NAME: LOCAL_UNIVERSAL_SEARCH_INDEX_NAME,
    //     };
    // }
    // if (process.env.ENVIRONMENT === ENVIRONMENTS.PM) {
    //     return {
    //         BASE_URL: PM_URL,
    //         ALGOLIA_INDEX_NAME: PROD_INDEX_NAME,
    //         ALGOLIA_GLOBAL_VENDOR_INDEX_NAME: PROD_GLOBAL_VENDOR_INDEX_NAME,
    //         ALGOLIA_UNIVERSAL_SEARCH_INDEX_NAME: LOCAL_UNIVERSAL_SEARCH_INDEX_NAME,
    //     };
    // }
    return {
        BASE_URL: PROD_URL,
        ALGOLIA_INDEX_NAME: PROD_INDEX_NAME,
        ALGOLIA_GLOBAL_VENDOR_INDEX_NAME: PROD_GLOBAL_VENDOR_INDEX_NAME,
        ALGOLIA_UNIVERSAL_SEARCH_INDEX_NAME: PROD_UNIVERSAL_SEARCH_INDEX_NAME,
    };
};

export default getEnvironmentalVariables;
