import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSCard, NSSwitch } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import React, { useState } from 'react';
import { FORM_ROW_CLASS_NAME } from 'views/ProFormaTable/constants';

const CalculateSizeCard = () => {
    const [shouldCalculate, setShouldCalculate] = useState(false);

    const toggleCalculate = () => setShouldCalculate(!shouldCalculate);

    return (
        <>
            <RenderIf isTrue={shouldCalculate}>
                <NSCard className="NSCard--level-3 p-3 mb-2">
                    <div className="d-flex justify-content-between text-dark">
                        <div>Lot</div>
                        <div>Land area</div>
                        <div>FAR</div>
                        <div>Gross buildable area</div>
                    </div>

                    <div role="presentation" className="text-primary mt-2 cursor--pointer" onClick={() => {}}>
                        <FontAwesomeIcon icon={faPlus} size="sm" className="mr-1" />
                        Add lot
                    </div>
                    <hr />

                    <div className="d-flex justify-content-between">
                        <div className="text-dark">Total gross buildable area:</div>
                        <div>
                            <span className="text-dark">195,000 </span>
                            <span>
                                ft<sup>2</sup>
                            </span>
                        </div>
                    </div>
                </NSCard>
            </RenderIf>
            <div className={FORM_ROW_CLASS_NAME}>
                <NSSwitch
                    id="calculateToggle"
                    name="calculateToggle"
                    checked={shouldCalculate}
                    onChange={toggleCalculate}
                    label="Calculate"
                    containerClassName="text-dark"
                />
            </div>
        </>
    );
};

export default CalculateSizeCard;
