import { DraggableTable } from 'components/DraggableTable/DraggableTable';
import React, { useCallback } from 'react';
import { Deal } from '../types';
import { createColumns } from './helper';
import { COLUMN_OPTIONS } from '../constants';
import PageSizeDropdown from './PageSizeDropdown/PageSizeDropdown';
import PaginationButtons from './PaginationButtons/PaginationButtons';

interface IDealsTableProps {
    deals: Deal[];
    visibleColumns: Record<string, boolean>;
    handleSelectDeal?: (deal: Deal) => void;
    className?: string;
    pageSize: number;
    currentPage: number;
    totalItems: number;
    setPageSize: (size: number) => void;
    setCurrentPage: (page: number) => void;
}

const DealsTable: React.FC<IDealsTableProps> = ({
    deals,
    visibleColumns,
    className,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    totalItems,
    handleSelectDeal = _ => {},
}) => {
    const allColumns = createColumns(COLUMN_OPTIONS);

    // TODO: Integrate sorting with backend
    const handleAscendingSortPressed = (columnName: keyof Deal) => {
        console.log('Sorting ascending: ', columnName);
    };

    // TODO: Integrate sorting with backend
    const handleDescendingSortPressed = (columnName: keyof Deal) => {
        console.log('Sorting descending: ', columnName);
    };

    const handleRowClick = useCallback(
        (rowData: Deal) => {
            handleSelectDeal(rowData);
        },
        [handleSelectDeal],
    );

    return (
        <div className={className}>
            <DraggableTable<Deal>
                data={deals}
                columns={allColumns}
                columnVisibility={visibleColumns}
                onAscendingSortPressed={handleAscendingSortPressed}
                onDescendingSortPressed={handleDescendingSortPressed}
                onAddToExistingGroup={selectedRows => console.log('Handle add:', selectedRows)}
                onCreateGroupFromSelection={selectedRows => console.log('Handle create group:', selectedRows)}
                onCreateReport={selectedRows => console.log('Handle create report:', selectedRows)}
                onDelete={selectedRows => console.log('Handle delete:', selectedRows)}
                onRowClick={handleRowClick}
            />
            <div className="d-flex justify-content-between align-items-center pt-2">
                <PageSizeDropdown pageSize={pageSize} onPageSizeChange={setPageSize} pageSizeOptions={[5, 10, 20, 50]} />
                <PaginationButtons
                    currentPage={currentPage}
                    totalPages={Math.ceil(totalItems / pageSize)}
                    onPageChange={setCurrentPage}
                    itemsPerPage={pageSize}
                    totalItems={totalItems}
                />
            </div>
        </div>
    );
};

export default DealsTable;
