import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { BOEScenario, IBackOfEnvelopeQueryParams } from '../types';

const getBackOfEnvelope = async ({ orgId }: IBackOfEnvelopeQueryParams): Promise<Array<BOEScenario>> => {
    const response = await axiosInstance.get(API_ENDPOINTS.BACK_OF_ENVELOPE(orgId));
    return response.data;
};

export const useGetBackOfEnvelope = ({ orgId }: IBackOfEnvelopeQueryParams) =>
    useQuery({
        queryKey: ['back-of-envelope', orgId],
        queryFn: () => getBackOfEnvelope({ orgId }),
        staleTime: 5000,
        refetchOnMount: true,
    });
