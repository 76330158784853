import React, { useRef, useEffect } from 'react';
import { Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-light-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import NSLabel from '../NSLabel/NSLabel';

export interface INSCheckboxProps {
    id: string;
    labelText?: string | JSX.Element;
    iconOnly?: boolean;
    size?: SizeProp;
    iconClassName?: string;
    disabled?: boolean;
    checked: boolean;
    callback: Function;
    containerClassName?: string;
    containerId?: string;
    labelClassName?: string;
    name?: string;
    inputClassName?: string;
    dataTestId?: string;
    indeterminate?: boolean;
}

const NSCheckbox = ({
    id,
    labelText = '',
    iconOnly = false,
    size = 'lg',
    iconClassName = '',
    disabled = false,
    checked,
    callback,
    containerClassName = 'custom-control-inline',
    containerId,
    labelClassName = '',
    name = '',
    inputClassName = '',
    dataTestId,
    indeterminate,
}: INSCheckboxProps) => {
    const checkboxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const checkbox = window.document.getElementById(String(id));
        if (checkboxRef.current && checkbox && typeof indeterminate !== 'undefined') {
            // @ts-ignore
            checkbox.indeterminate = checked && indeterminate;
        }
    }, [id, checked, indeterminate]);

    return iconOnly ? (
        <FontAwesomeIcon
            onClick={() => !disabled && (callback() as React.MouseEventHandler<SVGSVGElement> | undefined)}
            icon={checked ? faCheckSquare : faSquare}
            size={size}
            className={classNames('NSCheckbox-icon-only cursor--pointer', iconClassName, {
                'NSCheckbox-icon-only--checked': checked,
                'NSCheckbox-icon-only--disabled': disabled,
            })}
            data-testid={dataTestId}
        />
    ) : (
        <div className={`NSCheckbox custom-control custom-checkbox ${containerClassName}`} id={containerId} data-testid={dataTestId}>
            <Input
                ref={checkboxRef as any}
                id={id}
                type="checkbox"
                className={`custom-control-input ${inputClassName}`}
                disabled={disabled}
                checked={checked}
                onChange={callback as any}
                name={name}
            />
            <NSLabel className={`custom-control-label ${labelClassName}`} for={id}>
                {labelText}
            </NSLabel>
        </div>
    );
};

export default NSCheckbox;
