import React, { ReactNode } from 'react';
import { NSCard } from 'bricks';
import './NSRadioCard.scss';

export interface INSRadioCardProps {
    cardClassName?: string;
    onClick: () => void;
    children?: ReactNode;
}

const NSRadioCard = ({ children, onClick, cardClassName }: INSRadioCardProps) => {
    return (
        <NSCard className={`${cardClassName} p-2 mb-2 NSCard--level-4`} onClick={onClick}>
            {children}
        </NSCard>
    );
};

export default NSRadioCard;
