import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faDownload, faRocket, faSearchDollar } from '@fortawesome/free-solid-svg-icons';
import { faChartMixed, faChartMixedUpCircleDollar } from '@fortawesome/pro-solid-svg-icons';
import { NSButton, NSCard } from '../../../../../bricks';

const ProFormaSideNavFooter = () => (
    <NSCard className="NSCard--level-4 p-2 pt-1 mt-3 ProFormaSideNav__footer w-100">
        <div className="d-flex">
            <NSCard className="p-2 mb-1 mr-1 w-100 text-muted position-relative overflow-hidden font-13 ProFormaSideNav__card--primary-overlay">
                <FontAwesomeIcon icon={faSearchDollar} className="position-absolute text-primary ProFormaSideNav__card--bg-icon" />
                <div>Leveraged IRR</div>
                <div className="text-dark font-weight-bold">18.59%</div>
            </NSCard>
            <NSCard className="p-2 mb-1 w-100 text-muted position-relative overflow-hidden font-13 ProFormaSideNav__card--primary-overlay">
                <FontAwesomeIcon icon={faChartMixedUpCircleDollar} className="position-absolute text-primary ProFormaSideNav__card--bg-icon" />
                <div>Leveraged EMx</div>
                <div className="text-dark font-weight-bold">1.8x</div>
            </NSCard>
        </div>
        <NSCard className="p-2 mb-0 mr-1 w-100 text-muted position-relative overflow-hidden font-13 ProFormaSideNav__card--primary-overlay">
            <FontAwesomeIcon icon={faChartMixed} className="position-absolute text-primary ProFormaSideNav__card--bg-icon" />
            <div>
                <FontAwesomeIcon icon={faChartMixed} className="mr-1" />
                <span>Development spread (untrended)</span>
            </div>
            <div className="text-dark font-weight-bold">181.10bps</div>
        </NSCard>

        <div className="mt-2 d-flex">
            <NSButton icon={faCog} color="secondary" className="mr-1 ProFormaSideNav__button--icon-bg-color" callback={() => {}} />
            <NSButton icon={faDownload} color="secondary" className="mr-1 ProFormaSideNav__button--icon-bg-color" callback={() => {}} />
            <NSButton text="Create project from deal" className="w-100 text-nowrap text-truncate" icon={faRocket} color="primary" callback={() => {}} />
        </div>
    </NSCard>
);

export default ProFormaSideNavFooter;
