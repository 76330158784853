import React from 'react';
import { IComparisonReportData } from 'views/Reports/types';

export const ColumnHeader: React.FC<{ data: IComparisonReportData }> = ({ data }) => {
    return (
        data && (
            <>
                <div className="text-white h4">{data.name}</div>
                <div>Rental apartment • Development</div>
            </>
        )
    );
};

export default ColumnHeader;
