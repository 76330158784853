import { numeralFormatterCurrency } from 'ns_libs/formatter';
import { dollarValueAttributes, percentageValueAttributes } from './constants';
import { Attributes } from '../constants';

export const getFormattedFilterValue = (filterAttribute: Attributes, filterValue?: string): string => {
    if (dollarValueAttributes.includes(filterAttribute)) return numeralFormatterCurrency(filterValue);
    if (percentageValueAttributes.includes(filterAttribute)) return `${filterValue}%`;
    return filterValue || '';
};

export const getFormattedRangeValue = (filterAttribute: Attributes, filterRange: { from?: string; to?: string }): string => {
    const fromValue = getFormattedFilterValue(filterAttribute, filterRange.from);
    const toValue = getFormattedFilterValue(filterAttribute, filterRange.to);

    return `${fromValue} - ${toValue}`;
};
