import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faChartLineUp, faChartMixedUpCircleDollar, faGrid2 } from '@fortawesome/pro-solid-svg-icons';
import { PRO_FORMA_SIZE_UNIT_OPTIONS } from 'constants/unitTypes';

export const PRO_FORMA_TABLE_NAV = {
    PRO_FORMA: { name: 'Pro Forma', icon: faChartLineUp },
    RETURNS_AND_WATERFALL: { name: 'Returns & Waterfall Analysis', icon: faChartBar },
    SENSITIVITY: { name: 'Sensitivity', icon: faChartMixedUpCircleDollar },
    EXECUTIVE_SUMMARY: { name: 'Executive Summary', icon: faGrid2 },
};
export const PRO_FORMA_TABLE_NAV_NAMES = Object.values(PRO_FORMA_TABLE_NAV).map(nav => nav.name);

export const PRO_FORMA_TABS = {
    GENERAL_INFORMATION: { prefix: 'A', name: 'General Information' },
    DEVELOPMENT: { prefix: 'B', name: 'Development' },
    OPERATIONS: { prefix: 'C', name: 'Operations' },
    DISPOSITION: { prefix: 'D', name: 'Disposition' },
};
export const PRO_FORMA_TAB_NAMES = Object.values(PRO_FORMA_TABS).map(tab => tab.name);

export const RETURNS_AND_WATERFALL_TABS = {
    RETURNS: 'Returns',
    WATERFALL_ANALYSIS: 'Waterfall Analysis',
};
export const RETURNS_AND_WATERFALL_TAB_NAMES = Object.values(RETURNS_AND_WATERFALL_TABS);

export const FORM_ROW_CLASS_NAME = 'd-flex mb-2';
export const FORM_LABEL_CLASS_NAME = 'pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap';

export const GENERAL_INFORMATION_EXPANDABLE_SECTIONS = [
    {
        number: 1,
        title: 'Basic Details',
        subtitle: `Information about this investment's name and location`,
    },
    {
        number: 2,
        title: 'Pro forma start',
        subtitle: `Determine start date for this analysis. Typically this is your acquisition closing.
                    This date will be used as period 1 for cash flows.`,
    },
    {
        number: 3,
        title: 'Size',
        subtitle: `Details about this investment's property size and building`,
    },
];

export const DEAL_TYPES = {
    APARTMENT: 'Apartment',
    INDUSTRIAL: 'Industrial',
    RETAIL: 'Retail',
    OFFICE: 'Office',
};

const parkingOptions = [
    { label: 'Structured', value: 'structured' },
    { label: 'Surface', value: 'surface' },
];

const industrialParkingOptions = [
    { label: 'Car', value: 'car' },
    { label: 'Trailer', value: 'trailer' },
];

export const dealTypeProFormaParkingOptions = {
    [DEAL_TYPES.APARTMENT]: parkingOptions,
    [DEAL_TYPES.INDUSTRIAL]: industrialParkingOptions,
    [DEAL_TYPES.RETAIL]: parkingOptions,
    [DEAL_TYPES.OFFICE]: parkingOptions,
};

export const dealTypeProFormaFormFields: { [key: string]: any } = {
    [DEAL_TYPES.APARTMENT]: {
        landArea: '',
        landAreaUnitType: PRO_FORMA_SIZE_UNIT_OPTIONS[0].value,
        isFAR: false,
        farAmount: '',
        grossBuildingArea: '',
        grossBuildingAreaUnitType: PRO_FORMA_SIZE_UNIT_OPTIONS[1].value,
        buildings: 1,
        averageStories: 1,
        builtGrossSF: '',
        parking: [],
        structuredParkingSpaces: '',
        surfaceParkingSpaces: '',
    },
    [DEAL_TYPES.INDUSTRIAL]: {},
    [DEAL_TYPES.RETAIL]: {},
    [DEAL_TYPES.OFFICE]: {},
};

export const POPULATE_USES_METHOD = {
    IMPORT_FROM_PREVIOUS_PROJECT: {
        title: 'Import from a previous deal or project',
        subtitle: 'Start with budget categorizations, estimates, and curve fits used previously.',
    },
    START_WITH_BACK_OF_ENVELOPE: {
        title: 'Start with my Back-Of-Envelope estimates',
        subtitle: 'Start with high level assumptions established using Back-Of-Envelope.',
    },
    USE_RECOMMENDED_CATEGORIES: {
        title: 'Use recommended categories',
        subtitle: 'Start with our recommended budget line types for this project type.',
    },
    START_FROM_SCRATCH: {
        title: 'Start from scratch',
    },
};
