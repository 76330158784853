import React, { useState } from 'react';
import classNames from 'classnames';
import { NSCard } from 'bricks';
import RollupItem from 'components/RollupItem/RollupItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { IOptionAnyValue } from 'bricks/types';
import { getFormattedFilterValue, getFormattedRangeValue } from './helpers';
import { IFilter } from './types';
import FilterModal from './FilterModal/FilterModal';
import { Attributes } from '../constants';

interface FilterProps {
    filter: IFilter;
    editFilter: (attribute: Attributes, filter: IFilter) => void;
    removeFilter: (attribute: Attributes) => void;
    attributeOptions: IOptionAnyValue[];
}

const Filter = ({ filter, editFilter, removeFilter, attributeOptions }: FilterProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isEditFilterModalShown, setIsEditFilterModalShown] = useState(false);
    const { attribute, filterOption, fieldInput, unitType } = filter;

    let filterAttribute: string = attribute;
    if (unitType) filterAttribute += `: ${unitType}`;

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const handleEditFilter = (updatedFilter: IFilter) => editFilter(attribute, updatedFilter);
    const handleRemoveFilter = () => removeFilter(attribute);

    return (
        <NSCard className={classNames(['NSCard--level-4 p-2 my-1 Filter', { 'NSCard--level-5': isHovered }])}>
            <div className="d-flex justify-content-between align-items-center" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div>
                    <h5 className="text-white m-0 font-weight-bold">{filterAttribute}</h5>
                    <span className="text-nowrap">{filterOption}</span>
                </div>
                {!isHovered ? (
                    <div className="text-white text-right text-truncate text-wrap ml-2">
                        {fieldInput && 'value' in fieldInput && <h5 className="text-white">{getFormattedFilterValue(attribute, fieldInput.value)}</h5>}
                        {fieldInput && 'to' in fieldInput && 'from' in fieldInput && <h5>{getFormattedRangeValue(attribute, fieldInput)}</h5>}
                        {fieldInput && 'options' in fieldInput && (
                            <RollupItem
                                id={`${filterAttribute.split(' ').join('-')}-selections`}
                                values={fieldInput.options.map(o => o.label)}
                                newlineSeparated
                            />
                        )}
                    </div>
                ) : (
                    <div className="text-white">
                        <FontAwesomeIcon icon={faPen} size="lg" className="cursor--pointer" onClick={() => setIsEditFilterModalShown(true)} />
                        <FontAwesomeIcon icon={faTrash} size="lg" className="ml-3 cursor--pointer" onClick={handleRemoveFilter} />
                    </div>
                )}
            </div>
            <FilterModal
                filter={filter}
                toggle={() => setIsEditFilterModalShown(!isEditFilterModalShown)}
                isOpen={isEditFilterModalShown}
                saveFilter={handleEditFilter}
                attributeOptions={attributeOptions}
            />
        </NSCard>
    );
};

export default Filter;
