import React from 'react';
import { faBullseyeArrow } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSCard } from 'bricks';
import classNames from 'classnames';

export interface IProFormaStep1StatCardProps {
    cardClassName?: string;
}

const ProFormaStep1StatCard = ({ cardClassName }: IProFormaStep1StatCardProps) => {
    return (
        <NSCard className={classNames(`NSCard__left-border--success d-flex flex-column bg-success-lighten p-2 w-100 mb-0 ${cardClassName}`)}>
            <div className="d-flex justify-content-between my-auto">
                <div className="text-dark text-nowrap">Return on cost:</div>
                <div>100%</div>
            </div>
            <div className="d-flex justify-content-between h6 mt-0 mb-auto">
                <div>
                    <FontAwesomeIcon icon={faBullseyeArrow} className="mr-1" />
                    Target:
                </div>
                <div>99.18%</div>
            </div>
        </NSCard>
    );
};

export default ProFormaStep1StatCard;
