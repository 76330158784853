import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faGrip, faUsers, faStore, faCalendarPlus, faLifeRing, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { DropdownMenu, DropdownItem } from 'reactstrap';
import './UserMenuItems.scss';
import { useAuth0 } from '@auth0/auth0-react';

const UserMenuItems = () => {
    const { logout } = useAuth0();

    const mockedUserInformation = {
        userName: 'Mocked User',
        userClass: 'Admin',
        userEmail: 'mockeduser@example.com',
    };

    return (
        <DropdownMenu className="UserMenuItems-dropdown-menu" data-testid="dropdown-menu">
            <DropdownItem header>
                {mockedUserInformation.userName} {' | '} {mockedUserInformation.userClass}
            </DropdownItem>
            <DropdownItem header className="UserMenuItems-dropdown-menu__dropdown-email">
                {mockedUserInformation.userEmail}
            </DropdownItem>
            <DropdownItem divider />

            <Link to="/profile">
                <DropdownItem className="d-flex align-items-center py-1 px-2">
                    <FontAwesomeIcon className="mr-1" icon={faUserCircle} size="sm" />
                    My Account
                </DropdownItem>
            </Link>

            <Link to="/organization">
                <DropdownItem className="d-flex align-items-center py-1 px-2">
                    <FontAwesomeIcon className="mr-1" icon={faGrip} size="sm" />
                    Organization Management
                </DropdownItem>
            </Link>

            <Link to="/company">
                <DropdownItem className="d-flex align-items-center py-1 px-2">
                    <FontAwesomeIcon className="mr-1" icon={faUsers} size="sm" />
                    Company Profile
                </DropdownItem>
            </Link>

            <DropdownItem divider />

            <Link to="/admin">
                <DropdownItem className="d-flex align-items-center py-1 px-2">
                    <FontAwesomeIcon className="mr-1" icon={faStore} size="sm" />
                    Admin Section
                </DropdownItem>
            </Link>

            <Link to="/reports">
                <DropdownItem className="d-flex align-items-center py-1 px-2">
                    <FontAwesomeIcon className="mr-1" icon={faCalendarPlus} size="sm" />
                    Reports
                </DropdownItem>
            </Link>

            <DropdownItem divider />

            <a href="/support" target="_blank" rel="noopener noreferrer">
                <DropdownItem className="d-flex align-items-center py-1 px-2">
                    <FontAwesomeIcon className="mr-1" icon={faLifeRing} size="sm" />
                    Support
                </DropdownItem>
            </a>

            <DropdownItem
                className="d-flex align-items-center py-1 px-2"
                onClick={() =>
                    logout({
                        logoutParams: {
                            returnTo: window.location.origin,
                        },
                    })
                }
            >
                <FontAwesomeIcon className="mr-1" icon={faRightFromBracket} size="sm" />
                Logout
            </DropdownItem>
        </DropdownMenu>
    );
};

export default UserMenuItems;
