import React from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { NSCheckbox } from 'bricks';
import { DraggableTableActionMenu } from '../../../components/DraggableTable/DraggableTableActionMenu/DraggableTableActionMenu';
import { Deal } from '../types';
import { COLUMN_OPTIONS } from '../constants';

export const createColumns = (options: typeof COLUMN_OPTIONS): (ColumnDef<Deal> & { accessorKey: string })[] => {
    const selectColumn: ColumnDef<Deal> = {
        id: 'select',
        accessorKey: 'select',
        header: ({ table }) => (
            <NSCheckbox
                id="select-all"
                checked={table.getIsAllPageRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                callback={() => table.toggleAllRowsSelected()}
                labelText=""
                iconOnly
            />
        ),
        cell: ({ row }) => (
            <NSCheckbox id={`row-select-${row.id}`} checked={row.getIsSelected()} callback={() => row.toggleSelected()} labelText="" iconOnly />
        ),
        enableSorting: false,
    };

    const dynamicColumns = options.map(option => ({
        id: option.value,
        accessorKey: option.value,
        header: option.label,
        enableSorting: option.sortable ?? false,
        cell: option.cellRenderer
            ? ({ row }: CellContext<Deal, unknown>) => option.cellRenderer!(row.original)
            : ({ row }: CellContext<Deal, unknown>) => <span>{row.getValue(option.value)}</span>,
    }));

    const actionsColumn: ColumnDef<Deal> = {
        id: 'actions',
        accessorKey: 'actions',
        header: '',
        cell: ({ row }) => <DraggableTableActionMenu<Deal> row={row} />,
        enableSorting: false,
    };

    return [selectColumn, ...dynamicColumns, actionsColumn];
};
