import React, { Fragment } from 'react';
import { NSLoadingScreen } from '../../bricks';

interface IRenderIf<T> {
    isLoading?: boolean;
    loadingMessage?: string;
    isTrue: T;
    children: React.ReactNode;
}

const RenderIf = <T extends unknown>({ isLoading = false, loadingMessage = '', isTrue, children }: IRenderIf<T>) => {
    if (isLoading) {
        return <NSLoadingScreen message={loadingMessage} />;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return isTrue ? <>{children}</> : null;
};

export default RenderIf;
