import React, { useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import classNames from 'classnames';
import { faChevronRight, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { NSBadge, NSButton, NSSelect } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import { IOptionAnyValue } from 'bricks/types';
import { CREATE_PRO_FORMA_STEPS, MOCK_SCENARIO_OPTIONS } from 'views/CreateProFormaModal/constants';
import NSRadioCard from 'components/NSRadioCard/NSRadioCard';
import ProFormaStep1StatCard from './ProFormaStep1StatCard/ProFormaStep1StatCard';

export interface ICreateProFormaStep1Props extends Partial<StepWizardChildProps> {
    toggle: () => void;
}

const CreateProFormaStep1 = ({ goToNamedStep, toggle }: ICreateProFormaStep1Props) => {
    const [shouldStartFromBackOfEnvelope, setShouldStartFromBackOfEnvelope] = useState(true);
    const [selectedScenario, setSelectedScenario] = useState<IOptionAnyValue | null>(null);

    const chooseStartingSelection = (startFromBoE: boolean) => {
        if (!startFromBoE) {
            setSelectedScenario(null);
        }
        setShouldStartFromBackOfEnvelope(startFromBoE);
    };

    const goToNextStep = () => {
        if (goToNamedStep) {
            goToNamedStep(CREATE_PRO_FORMA_STEPS.STEP_2);
        }
    };

    const shouldDisableConfirm = shouldStartFromBackOfEnvelope && !selectedScenario;

    return (
        <>
            <NSRadioCard
                cardClassName={classNames('p-2 mb-2 NSCard--level-4', {
                    'border border-primary': shouldStartFromBackOfEnvelope,
                    'border border-secondary': !shouldStartFromBackOfEnvelope,
                })}
                onClick={() => chooseStartingSelection(true)}
            >
                <div className="d-flex justify-content-between">
                    <NSBadge color="primary-lighten">Recommended</NSBadge>

                    <RenderIf isTrue={shouldStartFromBackOfEnvelope}>
                        <FontAwesomeIcon icon={faDotCircle} className="text-primary NSRadioCard__radio-btn--selected" />
                    </RenderIf>
                    <RenderIf isTrue={!shouldStartFromBackOfEnvelope}>
                        <FontAwesomeIcon icon={faCircle} />
                    </RenderIf>
                </div>
                <div className="text-white mb-1 mt-1">Start your pro forma from an existing Back-Of-Envelope scenario</div>

                {/** Placeholder for now */}
                <NSSelect
                    name="boeScenarioSelect"
                    className="select text-nowrap"
                    placeholder="Select scenario"
                    options={MOCK_SCENARIO_OPTIONS}
                    value={selectedScenario ? MOCK_SCENARIO_OPTIONS.find(option => option.value === selectedScenario.value) : null}
                    onChange={option => setSelectedScenario(option?.value)}
                    isClearable={false}
                    formatOptionLabel={(option: { label: string; value: number }, context: { context: string }) => {
                        if (context.context === 'menu') {
                            return (
                                <div className="d-flex justify-content-between">
                                    <strong>{option.label}</strong>
                                    <FontAwesomeIcon icon={faChevronRight} className="ml-1" />
                                </div>
                            );
                        }
                        return option.label;
                    }}
                />

                <RenderIf isTrue={Boolean(selectedScenario)}>
                    <hr className="my-1" />

                    <div className="d-flex justify-content-between">
                        <ProFormaStep1StatCard cardClassName="mr-1" />
                        <ProFormaStep1StatCard cardClassName="mr-1" />
                        <ProFormaStep1StatCard />
                    </div>

                    <div className="d-flex text-primary cursor--pointer mt-1" role="presentation" onClick={() => {}}>
                        <small className="my-auto">View scenario in Back-Of-Envelope</small>
                    </div>
                </RenderIf>
            </NSRadioCard>

            <NSRadioCard
                cardClassName={classNames('p-2 mb-2 NSCard--level-4', {
                    'border border-primary': !shouldStartFromBackOfEnvelope,
                    'border border-secondary': shouldStartFromBackOfEnvelope,
                })}
                onClick={() => chooseStartingSelection(false)}
            >
                <div className="d-flex justify-content-between">
                    <div className="text-white mb-1 my-auto">Start from scratch</div>

                    <RenderIf isTrue={!shouldStartFromBackOfEnvelope}>
                        <FontAwesomeIcon icon={faDotCircle} className="text-primary my-auto NSRadioCard__radio-btn--selected" />
                    </RenderIf>
                    <RenderIf isTrue={shouldStartFromBackOfEnvelope}>
                        <FontAwesomeIcon icon={faCircle} className="my-auto" />
                    </RenderIf>
                </div>

                <RenderIf isTrue={!shouldStartFromBackOfEnvelope}>
                    <div className="h6 mb-0">
                        <span className="text-warning">Note:</span> we recommend starting a new pro forma from a Back-Of-Envelope scenario to help reduce
                        data entry.
                    </div>
                </RenderIf>
            </NSRadioCard>

            <hr />

            <div className="d-flex float-right my-0">
                <NSButton outline callback={toggle} text="Cancel" className="btn-secondary mr-2" disabled={false} />
                <NSButton outline={false} callback={goToNextStep} className="btn-primary btn-block" text="Confirm" disabled={shouldDisableConfirm} />
            </div>
        </>
    );
};

export default CreateProFormaStep1;
