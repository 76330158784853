/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from 'react-router-dom';

import LayoutWithNavbar from 'components/LayoutWithNavbar/LayoutWithNavbar';
import DealDashboard from 'views/DealDashboard/DealDashboard';
import CreateDeal from 'views/Deals/CreateDeal/CreateDeal';
import ProFormaTable from './views/ProFormaTable/ProFormaTable';
import Reports from './views/Reports/Reports';
import Counter from './views/Counter/Counter.jsx';
import Tiff from './views/Tiff/Tiff';
import NSRoute from './components/NSRoute/NSRoute';
import Deals from './views/Deals/Deals';
import Utilities from './views/Utilities/Utilities';
import { URLS } from './services/urls';
import ProFormaHome from './views/ProFormaHome/ProFormaHome';

const NotFound = () => <div>404 - Page Not Found</div>;

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route element={<LayoutWithNavbar />}>
                <Route path="/" element={<Counter />} />
                <Route element={<NSRoute />}>
                    <Route path={URLS.MARTIN} element={<div>me gusta shake shack</div>} />
                    <Route path={URLS.TIFF} element={<Tiff />} />
                    <Route path={URLS.REPORTS} element={<Reports />} />
                    <Route path={URLS.DEALS.HOME} element={<Deals />} />
                    <Route path={URLS.DEALS.VIEW} element={<DealDashboard />} />
                    <Route path={URLS.DEALS.FORM} element={<CreateDeal />} />
                    <Route path={URLS.UTILITIES} element={<Utilities />} />
                    <Route path={URLS.PRO_FORMA.HOME} element={<ProFormaHome />} />
                    <Route path={URLS.PRO_FORMA.TABLE} element={<ProFormaTable />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Route>
        </>,
    ),
);

const AppRouter = () => <RouterProvider router={router} />;

export default AppRouter;
