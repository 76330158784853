import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBell, faCalculator } from '@fortawesome/pro-regular-svg-icons';
import NSLogo from '../../images/Northspyre-Logo.png';
import ProjectMenu from './components/ProjectMenu/ProjectMenu';
import UserMenu from './components/UserMenu/UserMenu';
import './NSNavbar.scss';

export const DisplayedLogo: { [key: string]: string } = {
    // TODO Add other environment logos
    production: NSLogo,
};

const NSNavbar = () => {
    const location = useLocation();

    return (
        <nav className="NSNavbar d-flex justify-content-between px-3 ">
            <div className="d-flex align-items-center">
                <div className="mr-2">
                    <Link to="/" data-testid="home_url">
                        <img src={DisplayedLogo.production} style={{ height: '40px' }} alt="Logo" id="nav-bar-logo" />
                    </Link>
                </div>

                <div className="NSNavbar__link-list d-flex">
                    <Link
                        to="/deals"
                        className={`NSNavbar__link-list__link d-flex align-items-center px-2 ${location.pathname === '/deals' ? 'active' : ''}`}
                    >
                        Deals
                    </Link>
                    <Link
                        to="/martin"
                        className={`NSNavbar__link-list__link d-flex align-items-center px-2 ${location.pathname === '/martin' ? 'active' : ''}`}
                    >
                        Projects
                    </Link>
                    <Link
                        to="/tiff"
                        className={`NSNavbar__link-list__link d-flex align-items-center px-2 ${location.pathname === '/tiff' ? 'active' : ''}`}
                    >
                        Analytics
                    </Link>
                    <Link
                        to="/utilities"
                        className={`NSNavbar__link-list__link d-flex align-items-center px-2 ${location.pathname === '/utilities' ? 'active' : ''}`}
                    >
                        Utilities
                    </Link>
                </div>
            </div>
            <div className="NSNavbar__right d-flex align-items-center">
                <ProjectMenu />
                <div className="NSNavbar__right__icon-list d-flex">
                    <FontAwesomeIcon icon={faSearch} className="NSNavbar__right__icon-list__icon cursor--pointer" aria-label="search" />
                    <FontAwesomeIcon icon={faCalculator} className="NSNavbar__right__icon-list__icon cursor--pointer" aria-label="calculator" />
                    <FontAwesomeIcon icon={faBell} className="NSNavbar__right__icon-list__icon cursor--pointer" aria-label="notifications" />
                </div>
                <UserMenu />
            </div>
        </nav>
    );
};

export default NSNavbar;
