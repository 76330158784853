import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSInput } from 'bricks';
import React, { useState } from 'react';

interface ITitleInlineEdit {
    reportName: string;
    onChangeName: (name: string) => void;
    isEditing: boolean;
    onValidationChange?: (isValid: boolean) => void;
}

const TitleInlineEdit = ({ reportName, onChangeName, isEditing, onValidationChange }: ITitleInlineEdit) => {
    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState(reportName);
    const [isNameValid, setIsNameValid] = useState(true);

    const handleSave = () => {
        if (isNameValid && name.trim() !== '') {
            onChangeName(name.trim());
            setEditMode(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const isValid = value.trim().length > 0;
        setName(value);
        setIsNameValid(isValid);

        onValidationChange?.(isValid);
    };

    return (
        <div>
            {editMode ? (
                <NSInput
                    type="text"
                    value={name}
                    onChange={handleInputChange}
                    name="report-name"
                    autoFocus
                    onBlur={handleSave}
                    customClassName="bg-transparent m-0 py-0 px-1 TitleInlineEdit__input text-white"
                    required
                    showErrorMsg={!isNameValid}
                    errorMsg="Report Name cannot be empty"
                />
            ) : (
                <div>
                    <span>{reportName}</span>
                    {isEditing && (
                        <FontAwesomeIcon
                            icon={faPen}
                            size="xs"
                            className="mx-1"
                            style={{ fontSize: '0.8rem', cursor: 'pointer' }}
                            onClick={() => setEditMode(true)}
                            aria-label="Edit report name"
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default TitleInlineEdit;
