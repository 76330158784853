import { NSButton, NSModal } from 'bricks';
import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useDeleteReports } from '../../hooks/useDeleteReports';

interface IActionRowProps {
    selectedRows: number[];
}

const ActionRow = ({ selectedRows }: IActionRowProps) => {
    const queryClient = useQueryClient();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);
    const toggle = () => setIsDeleteModalOpen(!isDeleteModalOpen);

    const { mutate: deleteReports } = useDeleteReports();

    const handleDelete = () => {
        deleteReports(
            { orgId: '3fa85f64-5717-4562-b3fc-2c963f66afa6', reportIds: selectedRows },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: ['getReports', '3fa85f64-5717-4562-b3fc-2c963f66afa6'] });
                },
            },
        );
        toggle();
    };

    return (
        <>
            <div className="ActionRow p-2 rounded">
                <span className="text-white">{selectedRows.length} selected</span>
                <NSButton className="ml-2" color="danger" outline text="Delete" data-testid="delete-toggle" callback={() => setIsDeleteModalOpen(true)} />
            </div>
            <NSModal
                isOpen={isDeleteModalOpen}
                toggle={toggle}
                modalHeaderText={`Delete ${selectedRows.length} selected ${selectedRows.length > 1 ? 'reports' : 'report'}`}
                modalHeaderClassName="text-white bg-danger"
                modalFooterButtonText="Delete"
                modalFooterButtonColor="danger"
                modalFooterButtonFunction={() => {
                    handleDelete();
                }}
            >
                <div className="text-dark h5 mb-0">{`Are you sure you want to delete ${selectedRows.length > 1 ? 'these reports' : 'the report'}?`}</div>
            </NSModal>
        </>
    );
};

export default ActionRow;
