import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { NSBadge } from '..';
import RenderIf from '../../components/RenderIf/RenderIf';

export interface INSNavItem {
    name: string | JSX.Element;
    callback: (event?: React.MouseEvent | any) => void;
    isActive: boolean;
    id: string;
    showBetaBadge?: boolean;
    className?: string;
    badge?: JSX.Element;
    navPrefix?: string;
}

export interface INSNavTabsProps {
    containerClassName?: string;
    navItems: INSNavItem[];
}

const NSNavTabs = ({ containerClassName, navItems }: INSNavTabsProps) => (
    <div className={`NSNavTabs ${containerClassName}`}>
        <Nav tabs className="nav-justified nav-bordered NSNavTabs__nav-list">
            {navItems.map(navItem => (
                <NavItem key={navItem.id} id={navItem.id}>
                    <NavLink
                        href=""
                        data-toggle="tab"
                        className={classNames([
                            'h-100 NSNavTabs__nav-link',
                            {
                                'active bg-primary-lighten': navItem.isActive,
                                'text-muted': !navItem.isActive,
                                [navItem.className || '']: !!navItem.className,
                            },
                        ])}
                        onClick={navItem.callback}
                    >
                        <RenderIf isTrue={navItem.navPrefix}>
                            <div className="d-flex justify-content-between">
                                <small
                                    className={classNames('my-auto mr-1', {
                                        'text-primary-lighten text-primary': navItem.isActive,
                                    })}
                                >
                                    {navItem.navPrefix}
                                </small>
                                <span>{navItem.name}</span>
                                <span />
                            </div>
                        </RenderIf>
                        <RenderIf isTrue={!navItem.navPrefix}>
                            <span>{navItem.name}</span>
                        </RenderIf>
                        <RenderIf isTrue={navItem.badge}>{navItem.badge}</RenderIf>
                        <RenderIf isTrue={navItem.showBetaBadge}>
                            <NSBadge color="primary-lighten ml-1">Beta</NSBadge>
                        </RenderIf>
                    </NavLink>
                </NavItem>
            ))}
        </Nav>
    </div>
);

export default NSNavTabs;
