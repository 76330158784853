import React, { useCallback, useState } from 'react';
import { NSButton, NSNavTabs } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import RenderIf from 'components/RenderIf/RenderIf';
import PageTitle from 'components/PageTitle/PageTitle';
import { TabContent, TabPane } from 'reactstrap';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { Deal } from 'views/Deals/types';
import DealScenarioDropdown from 'components/DealScenarioDropdown/DealScenarioDropdown';
import { noNestedScenarios, onlyNestedScenarios, simplifiedScenarios } from 'components/DealScenarioDropdown/constants';
import { DEAL_OVERVIEW, DEAL_OVERVIEW_TABS, BACK_OF_ENVELOPE, DEAL_ACTIVITY } from './constants';
import Overview from './Overview/Overview';
import './DealDashboard.scss';
import BackOfEnvelope from './BOE/BackOfEnvelope';

const DealDashboard = () => {
    const [activeTabName, setActiveTabName] = useState(DEAL_OVERVIEW);
    const [selectedScenario, setSelectedScenario] = useState<IOptionAnyValue | undefined>(undefined);
    const location = useLocation();
    const row = location.state?.row as Deal;

    const handleCreateScenario = useCallback(() => {
        window.alert('Create a new scenario');
    }, []);

    const handleSelectScenario = useCallback((option: IOptionAnyValue) => {
        setSelectedScenario(option);
    }, []);

    return (
        <div className="DealDashboard">
            <PageTitle title={row.dealName} subtitle={row.address} displayBreadcrumb>
                <NSButton icon={faDownload} color="secondary" outline={false} callback={() => {}} className="mr-2" />
            </PageTitle>

            <NSNavTabs
                containerClassName="d-flex mt-2 DealDashboard__separator"
                navItems={DEAL_OVERVIEW_TABS.map((navName, index) => ({
                    callback: () => setActiveTabName(navName),
                    name: navName,
                    isActive: activeTabName === navName,
                    id: `deal-overview-${index}`,
                    className: 'text-dark text-nowrap px-4 mr-2',
                }))}
            />

            <TabContent activeTab={activeTabName} className="DealDashboard__tab-content d-flex w-100">
                <RenderIf isTrue={activeTabName === DEAL_OVERVIEW}>
                    <TabPane className="d-flex flex-column w-100" tabId={DEAL_OVERVIEW}>
                        <Overview deal={row} />
                    </TabPane>
                </RenderIf>
                <RenderIf isTrue={activeTabName === BACK_OF_ENVELOPE}>
                    <TabPane className="d-flex flex-column w-100" tabId={BACK_OF_ENVELOPE}>
                        <BackOfEnvelope />
                    </TabPane>
                </RenderIf>
                <RenderIf isTrue={activeTabName === DEAL_ACTIVITY}>
                    <TabPane className="d-flex flex-column w-100 pt-2" tabId={DEAL_ACTIVITY}>
                        <DealScenarioDropdown
                            scenarios={simplifiedScenarios}
                            handleCreateScenario={handleCreateScenario}
                            handleSelectScenario={handleSelectScenario}
                            includeCreate
                            includeLabels
                            includeSearch
                        />
                        <br />
                        <DealScenarioDropdown
                            scenarios={noNestedScenarios}
                            handleCreateScenario={handleCreateScenario}
                            handleSelectScenario={handleSelectScenario}
                            includeSearch
                        />
                        <br />
                        <DealScenarioDropdown
                            scenarios={onlyNestedScenarios}
                            handleCreateScenario={handleCreateScenario}
                            handleSelectScenario={handleSelectScenario}
                            includeCreate
                            cleanOnSelect
                        />
                        <br />
                        <RenderIf isTrue={selectedScenario !== undefined}>
                            <h3>The selected scenario is</h3>
                            <p>{selectedScenario?.label}</p>
                        </RenderIf>
                    </TabPane>
                </RenderIf>
            </TabContent>
        </div>
    );
};

export default DealDashboard;
