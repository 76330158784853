import React from 'react';
import { NSCard } from 'bricks';

const Sensitivity = () => {
    return (
        <NSCard className="p-2">
            <div>Details go here.</div>
        </NSCard>
    );
};

export default Sensitivity;
