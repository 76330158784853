import React, { useState } from 'react';
import { Dropdown, DropdownToggle } from 'reactstrap';
import UserMenuItems from './UserMenuItem/UserMenuItems';
import './UserMenu.scss';

const UserMenu: React.FC = () => {
    // TODO remove mocked user picture
    const mockedUserPicture = 'https://picsum.photos/200';

    const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
    const toggleUserMenu = () => setIsUserMenuOpen(!isUserMenuOpen);

    return (
        <Dropdown isOpen={isUserMenuOpen} toggle={toggleUserMenu} className="UserMenu-dropdown" data-testid="user-menu-dropdown">
            <DropdownToggle tag="span" data-testid="user-menu-toggle">
                <div style={{ cursor: 'pointer' }}>
                    <img src={mockedUserPicture} alt="user-icon" className="UserMenu-dropdown__avatar" data-testid="user-menu-avatar" />
                </div>
            </DropdownToggle>
            {isUserMenuOpen && <UserMenuItems />}
        </Dropdown>
    );
};

export default UserMenu;
