import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { NSButton } from 'bricks';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

interface PageSizeDropdownProps {
    pageSize: number;
    onPageSizeChange: (size: number) => void;
    pageSizeOptions: number[];
}

const PageSizeDropdown: React.FC<PageSizeDropdownProps> = ({ pageSize, onPageSizeChange, pageSizeOptions }) => {
    return (
        <div className="d-flex align-items-center">
            <span data-testid="page-size-label" className="page-size-label mr-2">
                Show results per page:
            </span>
            <UncontrolledDropdown>
                <DropdownToggle tag="div" data-testid="page-size-toggle">
                    <NSButton icon={faCaretDown} iconPosition="right" outline={false} color="secondary" text={pageSize.toString()} />
                </DropdownToggle>
                <DropdownMenu data-testid="page-size-dropdown">
                    {pageSizeOptions.map(size => (
                        <DropdownItem
                            key={size}
                            onClick={() => onPageSizeChange(size)}
                            active={size === pageSize}
                            data-testid={`page-size-option-${size}`}
                            className="text-white"
                        >
                            {size}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};

export default PageSizeDropdown;
