import React, { useRef, useState } from 'react';
import { NSButton, NSSelect, NSSlideout } from 'bricks';
import './BackOfEnvelope.scss';
import { faCalculator, faPlus, faScaleBalanced } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProps } from 'formik';
import { useLocation } from 'react-router-dom';
import { Deal } from 'views/Deals/types';
import { formatDate } from 'ns_libs/formatter';
import { BOESlideoutFormValues } from './components/SlideoutForm/types';
import BOESlideoutForm from './components/SlideoutForm/BOESlideoutForm';
import ScenarioInformation from './components/ScenarioInformation';
import ScenarioReturns from './components/ScenarioReturns';
import { useGetBackOfEnvelope } from './hooks/useGetBOE';

const MOCK_SCENARIO_OPTIONS = [
    {
        value: 1,
        label: 'Scenario 1',
    },
    {
        value: 2,
        label: 'Scenario 2',
    },
];

const BackOfEnvelope = () => {
    const [selectedScenario, setSelectedScenario] = useState<{ value: number; label: string } | null>(null);
    const [openSlideout, setOpenSlideout] = useState<boolean>(false);
    const formikRef = useRef<FormikProps<BOESlideoutFormValues>>(null);

    const handleSave = () => {
        if (formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    const handleReset = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    };

    // Remove when the deal relantionship is integrated.
    const location = useLocation();
    const row = location.state?.row as Deal;

    const EmptyState = (
        <div className="d-flex flex-column align-items-center justify-content-center text-center h-100">
            <h4 className="text-white mb-2">No scenarios yet.</h4>
            <p className="text-muted d-flex align-items-center justify-content-center">
                Create your first return scenario using the{' '}
                <NSButton className="text-primary p-0 mx-1" callback={() => setOpenSlideout(!openSlideout)}>
                    <FontAwesomeIcon icon={faCalculator} className="text-primary px-1" aria-label="calculator" />
                    Back-Of-Envelope
                </NSButton>{' '}
                tool.
            </p>
        </div>
    );

    // Remove the hardcoded orgId when the API is ready.
    const orgId = '123e4567-e89b-12d3-a456-426614174000';
    const { data } = useGetBackOfEnvelope({ orgId });
    return (
        <div className="BackOfEnvelope d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center w-100 py-2 px-3">
                <div className="text-left">
                    <h3 className="text-white mb-1">Back-Of-Envelope</h3>
                    <p className="text-muted mb-0">Assess the financial feasibility of this deal.</p>
                </div>
                <div className="d-flex align-items-center">
                    {/* Mocked component, this will be replaced with the actual scenario selector */}
                    <NSSelect
                        name="boeScenarioSelect"
                        className="text-nowrap mr-2"
                        placeholder="Select scenario"
                        options={MOCK_SCENARIO_OPTIONS}
                        value={selectedScenario ? MOCK_SCENARIO_OPTIONS.find(option => option.value === selectedScenario.value) : null}
                        onChange={option => setSelectedScenario(option?.value)}
                        isClearable={false}
                    />
                    <NSButton text="Compare" color="secondary" icon={faScaleBalanced} className="mr-2" callback={() => console.log('Compare clicked')} />
                    <NSButton
                        data-testid="back-of-envelope-button"
                        text="Back-Of-Envelope"
                        color="secondary"
                        icon={faCalculator}
                        callback={() => setOpenSlideout(!openSlideout)}
                    />
                </div>
            </div>

            {data !== undefined && data.length > 0 ? (
                <div className="BackOfEnvelope__ScenarioContainer NSCard--level-2 py-2 px-3 mx-3 my-1">
                    <div className="BackOfEnvelope__ScenarioContainer--header d-flex flex-row justify-content-between align-items-center pb-2 mb-2">
                        <div>
                            <h4 className="text-white font-weight-bold mb-0">{data[0].name}</h4>
                            <h5 className="mt-0">{`Created on ${formatDate(data[0].createdAt)}`}</h5>
                        </div>
                        <NSButton text="Create pro forma" color="primary" icon={faPlus} callback={() => console.log('Pro forma clicked')} />
                    </div>
                    <ScenarioReturns boeScenario={data[0]} deal={row} />
                    <ScenarioInformation boeScenario={data[0]} />
                </div>
            ) : (
                EmptyState
            )}
            <NSSlideout
                modalHeaderText="Back-Of-Envelope"
                modalHeaderSubtitleText="Quickly assess the financial feasibility of this deal. "
                customHeaderActions={
                    <div className="d-flex">
                        <NSSelect
                            name="boeScenarioSelect"
                            className="text-nowrap mr-2"
                            placeholder="Select scenario"
                            options={MOCK_SCENARIO_OPTIONS}
                            value={selectedScenario ? MOCK_SCENARIO_OPTIONS.find(option => option.value === selectedScenario.value) : null}
                            onChange={option => setSelectedScenario(option?.value)}
                            isClearable={false}
                        />
                        <NSButton color="secondary" text="Clear" className="mx-2" callback={handleReset} />
                        <NSButton color="primary" text="Save" callback={handleSave} />
                    </div>
                }
                isOpen={openSlideout}
                toggle={() => setOpenSlideout(!openSlideout)}
            >
                <BOESlideoutForm ref={formikRef} />
            </NSSlideout>
        </div>
    );
};

export default BackOfEnvelope;
