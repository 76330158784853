import React from 'react';
import { Col, Row } from 'reactstrap';
import Map from 'components/Map/Map';
import DataPanel from './components/DataPanel';
import { Deal } from '../types';

interface IMapViewProps {
    selectedDeal: Deal;
}

export const DEFAULT_MAP_CENTER = { lng: -98.5795, lat: 39.8283 };

const MapView = ({ selectedDeal }: IMapViewProps) => {
    const lng = selectedDeal?.address_center?.lng !== undefined ? selectedDeal.address_center.lng : DEFAULT_MAP_CENTER.lng;
    const lat = selectedDeal?.address_center?.lat !== undefined ? selectedDeal.address_center.lat : DEFAULT_MAP_CENTER.lat;

    return (
        <Row className="pt-2 pl-2 pr-2 pb-0">
            <Col xs="6" className="d-block p-0">
                <Map lng={lng} lat={lat} />
            </Col>
            <Col xs="6" className="p-0">
                <DataPanel detail={selectedDeal} />
            </Col>
        </Row>
    );
};

export default MapView;
