import React from 'react';
import { percentFormatter } from 'ns_libs/formatter';
import { IProjectionMetricsData } from '../../../types';

export const ProjectionMetrics: React.FC<{ data: IProjectionMetricsData }> = ({ data }) => {
    return (
        data && (
            <div className="text-white">
                <div>Untrended: {percentFormatter(data.untrended)}</div>
                <div>Trended: {data.trended}</div>
                <div>Sale: {percentFormatter(data.sale)}</div>
            </div>
        )
    );
};

export default ProjectionMetrics;
