import * as React from 'react';
import { FormikProps } from 'formik';
import Select, { components, Props, Theme, FormatOptionLabelMeta, MenuPosition } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { FormGroup, UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';
import NSLabel from '../NSLabel/NSLabel';

export interface NSSelectProps extends Props<any, boolean, any> {
    name: string;
    label?: React.ReactNode | string;
    placeholder?: string;
    required?: boolean;
    formikProps?: FormikProps<any>;
    labelClassName?: string;
    errorMsgClassName?: string;
    errorMsgIcon?: IconProp;
    async?: boolean;
    isCreatable?: boolean;
    doNotShowErrorMsg?: boolean;
    isClearable?: boolean;
    formClassName?: string;
    formCustomStyle?: Object;
    formatOptionLabel?: (data: any, formatOptionLabelMeta: FormatOptionLabelMeta<any>) => React.ReactNode;
    dataTestId?: string;
    disabledTooltip?: string;
    invalid?: boolean;
}

const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
        <i className={['fas', 'fa-caret-down'].join(' ')} />
    </components.DropdownIndicator>
);

export const MemoizedDropdownIndicator = React.memo(DropdownIndicator);

// To do: Tiff to port these to scss stylesheet
const MED_GRAY = '#6c757d';
const BLUE = '#0FB3FF';
const GRAY_800 = '#e3eaef';
const GRAY_BLUE = '#47515d';
const NS_BLUE = BLUE;
const RED = '#fa6767';

export const getModifiedNSTheme = (theme: Theme) => ({
    ...theme,
    borderRadius: 4,
    colors: {
        ...theme.colors,
        primary25: MED_GRAY,
        primary: GRAY_BLUE,
        primary50: BLUE,
        danger: GRAY_800,
        dangerLight: BLUE,
        neutral30: GRAY_BLUE, // border color hover
        neutral80: BLUE, // multiValue input color
    },
});

export const customStyles = {
    container: (provided: any, data: any) => ({
        ...provided,
        opacity: data.isDisabled ? 0.5 : 1,
    }),
    option: (provided: any) => ({
        ...provided,
        padding: 10,
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        color: '#F1F1F1',
    }),
    indicatorSeparator: (provided: any) => ({
        ...provided,
        backgroundColor: 'transparent',
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: '#8391a2',
    }),
    control: (provided: any, { selectProps }: any) => ({
        ...provided,
        color: '#aab8c5',
        backgroundColor: '#404954',
        borderRadius: 4,
        cursor: 'pointer',
        border: selectProps.invalid ? `1px solid ${RED}` : `1px solid ${GRAY_BLUE}`,
        '&:hover': {
            borderColor: selectProps.invalid ? RED : GRAY_BLUE,
        },
    }),
    menu: (provided: any) => ({
        ...provided,
        color: '#aab8c5',
        backgroundColor: '#404954',
        borderRadius: 4,
        border: '1px solid #47515d',
        zIndex: 2,
    }),
    menuList: (provided: any) => ({
        ...provided,
        zIndex: 2,
    }),
    menuPortal: (provided: any) => ({
        ...provided,
        zIndex: 2,
    }),
    input: (provided: any) => ({
        ...provided,
        border: 'none',
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: '#e3eaef',
    }),
    multiValue: (provided: any) => ({
        ...provided,
        color: '#0fb3ff',
        backgroundColor: 'rgba(15, 179, 255, .26)',
        padding: '2px 8px',
    }),
    groupHeading: (provided: any) => ({
        ...provided,
        color: NS_BLUE,
        paddingTop: '0px',
        paddingBottom: '8px',
    }),
};

const NSSelect = (props: NSSelectProps) => {
    const {
        name,
        label,
        required,
        formikProps,
        labelClassName,
        errorMsgClassName,
        errorMsgIcon,
        async = false,
        doNotShowErrorMsg = false,
        isClearable = true,
        formClassName,
        isCreatable,
        formCustomStyle,
        formatOptionLabel,
        dataTestId,
        disabledTooltip,
        ...selectProps
    } = props;
    let Component: typeof Select | typeof AsyncSelect | typeof CreatableSelect = Select;
    if (isCreatable) {
        Component = CreatableSelect;
    } else if (async) {
        Component = AsyncSelect;
    }

    let menuPortalTarget = selectProps.menuPortalTarget as HTMLElement;
    let menuPosition: MenuPosition = selectProps.menuPosition || 'absolute';
    if (formatOptionLabel && !menuPortalTarget) {
        // eslint-disable-next-line no-undef
        menuPortalTarget = document.getElementById(name)!;
        menuPosition = 'fixed';
    }

    const errorMessage = typeof formikProps?.errors[name] === 'string' ? formikProps.errors[name] : 'This field is required';

    return (
        <FormGroup className={classNames('mb-0', formClassName)} style={formCustomStyle} data-testid={dataTestId} id={selectProps.id}>
            {label && (
                <NSLabel for={name} className={`${labelClassName || ''} text-dark font-weight-normal`}>
                    {label}
                    {required && <span className="text-danger">&nbsp;*</span>}
                </NSLabel>
            )}
            <Component
                id={name}
                name={name}
                styles={customStyles}
                theme={getModifiedNSTheme}
                components={{ DropdownIndicator: MemoizedDropdownIndicator }}
                {...selectProps}
                isClearable={isClearable}
                formatOptionLabel={formatOptionLabel}
                menuPortalTarget={menuPortalTarget}
                menuPosition={menuPosition}
            />
            {!doNotShowErrorMsg && formikProps && formikProps.errors[name] && formikProps.touched[name] && (
                <div className={`mt-1 text-danger small ${errorMsgClassName}`}>
                    {errorMsgIcon && <FontAwesomeIcon icon={errorMsgIcon} className="mr-1" />}
                    {errorMessage as string}
                </div>
            )}
            {selectProps.isDisabled && disabledTooltip && selectProps.id && (
                <UncontrolledTooltip target={selectProps.id!}>{disabledTooltip}</UncontrolledTooltip>
            )}
        </FormGroup>
    );
};

export default React.memo(NSSelect);
