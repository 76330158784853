import { faBarChart, faScaleUnbalanced, faTable } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { IReportType, ReportType } from '../types';

export const REPORT_TYPES: IReportType[] = [
    {
        type: ReportType.Aggregate,
        name: 'Aggregate Report',
        description: 'Combine Deal Pro Formas to understand aggregate returns.',
        icon: faBarChart,
        disabledTooltip: (
            <span>
                The Aggregate Report requires at least two <strong>development</strong> deals that have a substantially complete Pro Forma.
            </span>
        ),
    },
    {
        type: ReportType.Comparison,
        name: 'Comparison Report',
        description: 'Compare Deal Pro Forma metrics side-by-side.',
        icon: faScaleUnbalanced,
        disabledTooltip: (
            <span>
                The Comparison Report requires at least two <strong>development</strong> deals that have a substantially complete Pro Forma.
            </span>
        ),
    },
    {
        type: ReportType.Pipeline,
        name: 'Pipeline Report',
        description: 'Select and filter on Deal attributes.',
        icon: faTable,
        disabledTooltip: (
            <span>
                The Pipeline Report must start with all deals. Navigate to <strong>Reports</strong> from the Deal Pipeline to enable this report.
            </span>
        ),
    },
];
