/* eslint-disable no-undef */
/* eslint-disable operator-linebreak */
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { NSCard, NSDropdown, NSInput } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercentage } from '@fortawesome/free-solid-svg-icons';
import { faChevronDoubleRight, faDollar } from '@fortawesome/pro-regular-svg-icons';
import { Formik, Form, FormikProps } from 'formik';
import './BOESlideoutForm.scss';
import RenderIf from 'components/RenderIf/RenderIf';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import {
    ASSET_TYPE_OPTIONS,
    COST_BASIS_OPTIONS,
    COST_BASIS_TYPES,
    defaultValues,
    LOAN_CALCULATION_OPTIONS,
    LOAN_CALCULATION_TYPES,
    UNIT_OF_TIME_OPTIONS,
    validationSchema,
    ASSET_TYPE,
} from './constants';
import { BOESlideoutFormValues } from './types';
import { useCreateBackOfEnvelope } from './hooks/useCreateBOE';
import { prepareBackOfEnvelopePayload } from './helpers';
import { useBOECalculations } from './hooks';

interface BOESlideoutFormProps {
    initialValues?: BOESlideoutFormValues;
}

const BOESlideoutForm = forwardRef<FormikProps<BOESlideoutFormValues>, BOESlideoutFormProps>(({ initialValues }, ref) => {
    const [unitOfTimeStates, setUnitOfTimeStates] = useState({
        rentPrice: UNIT_OF_TIME_OPTIONS[0],
        expenses: UNIT_OF_TIME_OPTIONS[0],
        loan: UNIT_OF_TIME_OPTIONS[0],
    });

    const [costBasisStates, setCostBasisStates] = useState({
        rentPrice: COST_BASIS_OPTIONS[0],
        expenses: COST_BASIS_OPTIONS[0],
        softCosts: COST_BASIS_OPTIONS[0],
        hardCosts: COST_BASIS_OPTIONS[0],
    });

    const handleUnitOfTimeChange = (key: string, value: any) => {
        setUnitOfTimeStates(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleCostBasisChange = (key: string, value: any) => {
        setCostBasisStates(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const getDisplayValue = useCallback((value: number | null | undefined, placeholder: string = 'TBD'): string => {
        if (value === 0 || Number.isNaN(value)) {
            return placeholder;
        }
        return numeralFormatterCurrency(value ?? 0);
    }, []);

    const { mutate: createBackOfEnvelope } = useCreateBackOfEnvelope();

    // TODO Add actual logic
    const isEditing = false;

    const handleSubmit = (values: BOESlideoutFormValues): void => {
        const payload = prepareBackOfEnvelopePayload(values, unitOfTimeStates, costBasisStates);

        if (isEditing && initialValues?.id) {
            //  TODO Add useUpdateBackOfEnvelope
        } else {
            createBackOfEnvelope(
                {
                    orgId: '123e4567-e89b-12d3-a456-426614174000', // TODO Remove hardcoded ORG ID
                    postData: payload,
                },
                {
                    onSuccess: data => {
                        console.log('Scenario created successfully:', data);
                        alert('Back of Envelope successfully created!');
                    },
                    onError: error => {
                        console.error('Error creating scenario:', error);
                        alert('Failed to create Back of Envelope. Please try again.');
                    },
                },
            );
        }
    };

    return (
        <Formik innerRef={ref} initialValues={initialValues || defaultValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ values, handleChange, handleBlur, setFieldValue, errors, touched }) => {
                const isApartment = values.selectedAssetType.value === ASSET_TYPE.RENTAL_APARTMENT;

                const {
                    sfPerUnit,
                    exitValuation,
                    netRentable,
                    grossPotentialRent,
                    expenseTotal,
                    netOperatingIncome,
                    developerFeeTotal,
                    interestReserveTotal,
                    loanAmount,
                    equityCost,
                    hardCostsTotal,
                    softCostsTotal,
                } = useBOECalculations(values, unitOfTimeStates, costBasisStates);

                // Iterative Calculation
                useEffect(() => {
                    setFieldValue('developerFeeTotal', developerFeeTotal);
                    setFieldValue('totalDevelopmentCosts', developerFeeTotal + hardCostsTotal + softCostsTotal);
                    setFieldValue('loanAmount', loanAmount);
                    setFieldValue('interestReserveTotal', interestReserveTotal);
                    setFieldValue('equityCost', equityCost);
                }, [developerFeeTotal, hardCostsTotal, softCostsTotal, loanAmount, interestReserveTotal, equityCost, setFieldValue]);

                return (
                    <Form>
                        <div>
                            <NSCard className="p-2 w-50">
                                <span className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title">ASSET TYPE</span>
                                <NSDropdown
                                    options={ASSET_TYPE_OPTIONS}
                                    selectedOption={values.selectedAssetType}
                                    handleSelectOption={option => {
                                        setFieldValue('selectedAssetType', option);

                                        if (option.value !== ASSET_TYPE.RENTAL_APARTMENT) {
                                            handleCostBasisChange(
                                                'rentPrice',
                                                COST_BASIS_OPTIONS.find(opt => opt.value === COST_BASIS_TYPES.PER_SF),
                                            );
                                            handleCostBasisChange(
                                                'expenses',
                                                COST_BASIS_OPTIONS.find(opt => opt.value === COST_BASIS_TYPES.PER_SF),
                                            );
                                            handleCostBasisChange(
                                                'softCosts',
                                                COST_BASIS_OPTIONS.find(opt => opt.value === COST_BASIS_TYPES.PER_SF),
                                            );
                                            handleCostBasisChange(
                                                'hardCosts',
                                                COST_BASIS_OPTIONS.find(opt => opt.value === COST_BASIS_TYPES.PER_SF),
                                            );
                                        }
                                    }}
                                    customSelectedOptionText={values.selectedAssetType.label}
                                    containerClassName="mr-2"
                                    isFullWidth
                                />
                            </NSCard>
                            <div className="BOESlideoutForm__separator" />
                            <NSCard className="p-2 w-50">
                                <span className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title">PROPERTY SIZE</span>
                                <div className="d-flex justify-content-between">
                                    <div className="flex-grow-1 mr-2">
                                        <NSInput
                                            id="grossSquareFootage"
                                            type="number"
                                            label="Built Gross SF"
                                            name="grossSquareFootage"
                                            placeholder="Enter amount"
                                            appendInputAddon={<span>SF</span>}
                                            value={values.grossSquareFootage}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMsg={touched.grossSquareFootage && errors.grossSquareFootage ? errors.grossSquareFootage : null}
                                            showErrorMsg={!!(touched.grossSquareFootage && errors.grossSquareFootage)}
                                        />
                                        <div className="mt-1">
                                            Net rentable SF: <span className="text-white">{getDisplayValue(netRentable)}</span>
                                        </div>
                                    </div>
                                    <div className="flex-grow-2">
                                        <NSInput
                                            id="efficiency"
                                            label="Efficency ratio"
                                            name="efficiency"
                                            placeholder=""
                                            appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                            type="number"
                                            inputClassName="mb-1"
                                            value={values.efficiency}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                const newValue = Math.min(Math.max(event.target.valueAsNumber, 0), 100);
                                                setFieldValue('efficiency', newValue);
                                            }}
                                            onBlur={handleBlur}
                                            errorMsg={touched.efficiency && errors.efficiency ? errors.efficiency : null}
                                            showErrorMsg={!!(touched.efficiency && errors.efficiency)}
                                            includeRange
                                        />
                                    </div>
                                </div>
                            </NSCard>
                            <div className="d-flex">
                                <NSCard className="p-2 w-50">
                                    <span className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title">INCOME</span>
                                    <div>
                                        <div className="d-flex mb-1">
                                            <NSDropdown
                                                options={UNIT_OF_TIME_OPTIONS}
                                                selectedOption={unitOfTimeStates.rentPrice}
                                                handleSelectOption={option => handleUnitOfTimeChange('rentPrice', option)}
                                                customSelectedOptionText={unitOfTimeStates.rentPrice.label}
                                                badgeColor="dark-lighten"
                                                isBadgeToggle
                                            />
                                            <span className="ml-2 text-white">Rent price</span>
                                        </div>
                                        <NSInput
                                            id="rentPrice"
                                            type="number"
                                            label=""
                                            name="rentPrice"
                                            placeholder="Enter amount"
                                            prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                            appendInputAddon={
                                                <NSDropdown
                                                    options={
                                                        isApartment
                                                            ? COST_BASIS_OPTIONS
                                                            : COST_BASIS_OPTIONS.filter(option => option.value === COST_BASIS_TYPES.PER_SF)
                                                    }
                                                    selectedOption={costBasisStates.rentPrice}
                                                    customSelectedOptionText={costBasisStates.rentPrice.label}
                                                    handleSelectOption={option => handleCostBasisChange('rentPrice', option)}
                                                    badgeColor=""
                                                    isBadgeToggle
                                                    dropdownClassName="p-0 m-0"
                                                />
                                            }
                                            value={values.rentPrice}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMsg={touched.rentPrice && errors.rentPrice ? errors.rentPrice : null}
                                            showErrorMsg={!!(touched.rentPrice && errors.rentPrice)}
                                        />
                                        <div className="mt-2">
                                            <NSInput
                                                id="units"
                                                label={values.selectedAssetType.value === ASSET_TYPE.RENTAL_APARTMENT ? 'Units' : 'Suites'}
                                                name="units"
                                                placeholder=""
                                                type="number"
                                                inputClassName="mb-1"
                                                value={values.units}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errorMsg={touched.units && errors.units ? errors.units : null}
                                                showErrorMsg={!!(touched.units && errors.units)}
                                            />
                                            <span className="mt-1">
                                                SF per unit: <span className="text-white">{getDisplayValue(sfPerUnit)}</span>
                                            </span>
                                        </div>
                                    </div>
                                </NSCard>
                                <FontAwesomeIcon className="align-self-center mx-2" icon={faChevronDoubleRight} />
                                <NSCard className="d-flex justify-content-center flex-grow-1 align-self-center p-2 mb-0">
                                    <span className="d-flex align-items-center">Gross Potential rent</span>
                                    <span className="BOESlideoutForm__ text-white">
                                        <span className="font-weight-bold">{getDisplayValue(grossPotentialRent, '-')}</span>
                                        <span>{grossPotentialRent ? ' annually' : ''}</span>
                                    </span>
                                </NSCard>
                            </div>
                            <div className="d-flex">
                                <NSCard className="p-2 w-50">
                                    <span className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title">EXPENSES</span>
                                    <div>
                                        <div className="d-flex mb-1">
                                            <NSDropdown
                                                options={UNIT_OF_TIME_OPTIONS}
                                                selectedOption={unitOfTimeStates.expenses}
                                                handleSelectOption={option => handleUnitOfTimeChange('expenses', option)}
                                                customSelectedOptionText={unitOfTimeStates.expenses.label}
                                                badgeColor="dark-lighten"
                                                isBadgeToggle
                                            />
                                            <span className="ml-2 text-white">Expense cost</span>
                                        </div>
                                        <NSInput
                                            id="expenses"
                                            type="number"
                                            label=""
                                            name="expenses"
                                            placeholder="Enter amount"
                                            prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                            appendInputAddon={
                                                <NSDropdown
                                                    options={
                                                        isApartment
                                                            ? COST_BASIS_OPTIONS
                                                            : COST_BASIS_OPTIONS.filter(option => option.value === COST_BASIS_TYPES.PER_SF)
                                                    }
                                                    selectedOption={costBasisStates.expenses}
                                                    handleSelectOption={option => handleCostBasisChange('expenses', option)}
                                                    customSelectedOptionText={costBasisStates.expenses.label}
                                                    badgeColor=""
                                                    isBadgeToggle
                                                    dropdownClassName="p-0 m-0"
                                                />
                                            }
                                            value={values.expenses}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMsg={touched.expenses && errors.expenses ? errors.expenses : null}
                                            showErrorMsg={!!(touched.expenses && errors.expenses)}
                                        />
                                        <span className="mt-1">
                                            Expenses total: <span className="text-white">{getDisplayValue(expenseTotal)}</span>
                                        </span>
                                    </div>
                                </NSCard>
                                <FontAwesomeIcon className="align-self-center mx-2" icon={faChevronDoubleRight} />
                                <NSCard className="d-flex justify-content-center flex-grow-1 align-self-center p-2 mb-0">
                                    <span className="d-flex align-items-center">Net operating income</span>
                                    <span className="BOESlideoutForm__ text-white">
                                        <span className="font-weight-bold">{getDisplayValue(netOperatingIncome, '-')}</span>
                                        <span>{netOperatingIncome ? ' annually' : ''}</span>
                                    </span>
                                </NSCard>
                            </div>
                            <div className="d-flex">
                                <NSCard className="p-2 w-50">
                                    <span className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title">EXIT VALUE</span>
                                    <NSInput
                                        id="exitCapRate"
                                        type="number"
                                        label="Assumed exit cap rate"
                                        name="exitCapRate"
                                        placeholder="Enter amount"
                                        appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                        value={values.exitCapRate}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const newValue = Math.min(Math.max(event.target.valueAsNumber, 0), 100);
                                            setFieldValue('exitCapRate', newValue);
                                        }}
                                        onBlur={handleBlur}
                                        errorMsg={touched.exitCapRate && errors.exitCapRate ? errors.exitCapRate : null}
                                        showErrorMsg={!!(touched.exitCapRate && errors.exitCapRate)}
                                        rangeInputClassName="mt-1"
                                        includeRange
                                    />
                                </NSCard>
                                <FontAwesomeIcon className="align-self-center mx-2" icon={faChevronDoubleRight} />
                                <NSCard className="d-flex justify-content-center flex-grow-1 align-self-center p-2 mb-0">
                                    <span className="d-flex align-items-center">Exit valuation</span>
                                    <span className="BOESlideoutForm__ text-white font-weight-bold">{getDisplayValue(exitValuation, '-')}</span>
                                </NSCard>
                            </div>
                            <div className="d-flex">
                                <NSCard className="p-2 w-50">
                                    <span className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title">DEVELOPMENT COSTS</span>
                                    <NSInput
                                        id="acquisitionCosts"
                                        type="number"
                                        label="Acquisition costs"
                                        name="acquisitionCosts"
                                        placeholder="Enter amount"
                                        prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                        value={values.acquisitionCosts}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errorMsg={touched.acquisitionCosts && errors.acquisitionCosts ? errors.acquisitionCosts : null}
                                        showErrorMsg={!!(touched.acquisitionCosts && errors.acquisitionCosts)}
                                    />
                                    <NSInput
                                        id="hardCosts"
                                        type="number"
                                        label="Hard costs"
                                        name="hardCosts"
                                        placeholder="Enter amount"
                                        labelClassName="mt-2"
                                        prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                        appendInputAddon={
                                            <NSDropdown
                                                options={
                                                    isApartment
                                                        ? COST_BASIS_OPTIONS
                                                        : COST_BASIS_OPTIONS.filter(option => option.value === COST_BASIS_TYPES.PER_SF)
                                                }
                                                selectedOption={costBasisStates.hardCosts}
                                                handleSelectOption={option => handleCostBasisChange('hardCosts', option)}
                                                customSelectedOptionText={costBasisStates.hardCosts.label}
                                                badgeColor=""
                                                isBadgeToggle
                                                dropdownClassName="p-0 m-0"
                                            />
                                        }
                                        value={values.hardCosts}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errorMsg={touched.hardCosts && errors.hardCosts ? errors.hardCosts : null}
                                        showErrorMsg={!!(touched.hardCosts && errors.hardCosts)}
                                    />
                                    <span className="mt-1">
                                        Hard costs total: <span className="text-white">{getDisplayValue(hardCostsTotal)}</span>
                                    </span>
                                    <NSInput
                                        id="softCosts"
                                        type="number"
                                        label="Soft costs"
                                        name="softCosts"
                                        placeholder="Enter amount"
                                        labelClassName="mt-3"
                                        prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                        appendInputAddon={
                                            <NSDropdown
                                                options={
                                                    isApartment
                                                        ? COST_BASIS_OPTIONS
                                                        : COST_BASIS_OPTIONS.filter(option => option.value === COST_BASIS_TYPES.PER_SF)
                                                }
                                                selectedOption={costBasisStates.softCosts}
                                                handleSelectOption={option => handleCostBasisChange('softCosts', option)}
                                                customSelectedOptionText={costBasisStates.softCosts.label}
                                                badgeColor=""
                                                isBadgeToggle
                                                dropdownClassName="p-0 m-0"
                                            />
                                        }
                                        value={values.softCosts}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errorMsg={touched.softCosts && errors.softCosts ? errors.softCosts : null}
                                        showErrorMsg={!!(touched.softCosts && errors.softCosts)}
                                    />
                                    <span className="mt-1">
                                        Soft costs total: <span className="text-white">{getDisplayValue(softCostsTotal)}</span>
                                    </span>
                                    <NSInput
                                        id="developerFee"
                                        type="number"
                                        label="Developer fee"
                                        name="developerFee"
                                        placeholder="Enter amount"
                                        labelClassName="mt-3"
                                        inputClassName="w-50"
                                        appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                        value={values.developerFee}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errorMsg={touched.developerFee && errors.developerFee ? errors.developerFee : null}
                                        showErrorMsg={!!(touched.developerFee && errors.developerFee)}
                                    />
                                    <span className="mt-1">
                                        Developer fee total: <span className="text-white">{getDisplayValue(values.developerFeeTotal)}</span>
                                    </span>
                                    <span className="mt-2 text-white">Financing costs</span>
                                    <NSCard className="NSCard--level-2 p-2">
                                        <div className="d-flex">
                                            <div className="mr-2">
                                                <NSInput
                                                    id="apr"
                                                    type="number"
                                                    label="APR"
                                                    name="apr"
                                                    placeholder="Enter amount"
                                                    appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                                    value={values.apr}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        const newValue = Math.min(Math.max(event.target.valueAsNumber, 0), 100);
                                                        setFieldValue('apr', newValue);
                                                    }}
                                                    onBlur={handleBlur}
                                                    errorMsg={touched.apr && errors.apr ? errors.apr : null}
                                                    showErrorMsg={!!(touched.apr && errors.apr)}
                                                    rangeInputClassName="mt-1"
                                                    includeRange
                                                />
                                            </div>
                                            <div>
                                                <NSInput
                                                    id="avgLoanBalance"
                                                    type="number"
                                                    label="Average loan balance"
                                                    name="avgLoanBalance"
                                                    placeholder="Enter amount"
                                                    appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                                    value={values.avgLoanBalance}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        const newValue = Math.min(Math.max(event.target.valueAsNumber, 0), 100);
                                                        setFieldValue('avgLoanBalance', newValue);
                                                    }}
                                                    onBlur={handleBlur}
                                                    errorMsg={touched.avgLoanBalance && errors.avgLoanBalance ? errors.avgLoanBalance : null}
                                                    showErrorMsg={!!(touched.avgLoanBalance && errors.avgLoanBalance)}
                                                    rangeInputClassName="mt-1"
                                                    includeRange
                                                />
                                            </div>
                                        </div>
                                        <NSInput
                                            id="loanTerm"
                                            type="number"
                                            label="Loan term"
                                            name="loanTerm"
                                            placeholder="Enter amount"
                                            labelClassName="mt-2"
                                            appendInputAddon={
                                                <NSDropdown
                                                    options={UNIT_OF_TIME_OPTIONS}
                                                    selectedOption={unitOfTimeStates.loan}
                                                    handleSelectOption={option => handleUnitOfTimeChange('loan', option)}
                                                    customSelectedOptionText={unitOfTimeStates.loan.label}
                                                    badgeColor=""
                                                    isBadgeToggle
                                                    dropdownClassName="p-0 m-0"
                                                />
                                            }
                                            value={values.loanTerm}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMsg={touched.loanTerm && errors.loanTerm ? errors.loanTerm : null}
                                            showErrorMsg={!!(touched.loanTerm && errors.loanTerm)}
                                        />
                                        <span className="mt-1">
                                            Interest reserve total: <span className="text-white">{getDisplayValue(values.interestReserveTotal)}</span>
                                        </span>
                                    </NSCard>
                                </NSCard>
                                <FontAwesomeIcon className="align-self-center mx-2" icon={faChevronDoubleRight} />
                                <NSCard className="d-flex justify-content-center flex-grow-1 align-self-center p-2 mb-0">
                                    <span className="">Total development costs (uses)</span>
                                    <span className="BOESlideoutForm__ text-white font-weight-bold">
                                        {getDisplayValue(values.totalDevelopmentCosts, '-')}
                                    </span>
                                </NSCard>
                            </div>
                            <div className="d-flex">
                                <NSCard className="p-2 pb-3 w-50 mb-0">
                                    <div className="d-flex justify-content-between">
                                        <div className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title">CONSTRUCTION FINANCING</div>
                                        <div className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title">Loan sizing test</div>
                                    </div>
                                    <div className="d-flex mb-1">
                                        <NSDropdown
                                            options={LOAN_CALCULATION_OPTIONS}
                                            selectedOption={values.selectedLoanCalculationOptions}
                                            handleSelectOption={option => setFieldValue('selectedLoanCalculationOptions', option)}
                                            customSelectedOptionText={values.selectedLoanCalculationOptions.label}
                                            badgeColor="dark-lighten"
                                            isBadgeToggle
                                        />
                                    </div>
                                    <NSInput
                                        id="contructionFinancing"
                                        type="number"
                                        label=""
                                        name="contructionFinancing"
                                        placeholder="Enter amount"
                                        appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                        value={values.contructionFinancing}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const newValue = Math.min(Math.max(event.target.valueAsNumber, 0), 100);
                                            setFieldValue('contructionFinancing', newValue);
                                        }}
                                        onBlur={handleBlur}
                                        errorMsg={touched.contructionFinancing && errors.contructionFinancing ? errors.contructionFinancing : null}
                                        showErrorMsg={!!(touched.contructionFinancing && errors.contructionFinancing)}
                                        rangeInputClassName="mt-1"
                                        includeRange
                                    />
                                    <RenderIf isTrue={values.selectedLoanCalculationOptions.value === LOAN_CALCULATION_TYPES.LTV}>
                                        <NSInput
                                            id="marketCapRate"
                                            type="number"
                                            label="Market cap rate"
                                            name="marketCapRate"
                                            placeholder="Enter amount"
                                            appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                            value={values.marketCapRate}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                const newValue = Math.min(Math.max(event.target.valueAsNumber, 0), 100);
                                                setFieldValue('marketCapRate', newValue);
                                            }}
                                            onBlur={handleBlur}
                                            errorMsg={touched.marketCapRate && errors.marketCapRate ? errors.marketCapRate : null}
                                            showErrorMsg={!!(touched.marketCapRate && errors.marketCapRate)}
                                            labelClassName="mt-2"
                                            rangeInputClassName="mt-1"
                                            includeRange
                                        />
                                    </RenderIf>
                                </NSCard>

                                <FontAwesomeIcon className="align-self-center mx-2" icon={faChevronDoubleRight} />
                                <div className="d-row justify-content-center align-self-center flex-grow-1">
                                    <NSCard className="p-2 mb-2">
                                        <span className="d-flex align-items-center">Loan amount</span>
                                        <span className="BOESlideoutForm__ text-white font-weight-bold">{getDisplayValue(values.loanAmount, '-')}</span>
                                    </NSCard>
                                    <NSCard className="p-2 mb-0">
                                        <div className="d-flex align-items-center">Required equity</div>
                                        <span className="BOESlideoutForm__ text-white font-weight-bold">{getDisplayValue(values.equityCost, '-')}</span>
                                    </NSCard>
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
});

export default BOESlideoutForm;
