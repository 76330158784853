import React, { useState } from 'react';
import { IOptionAnyValue } from 'bricks/types';
import { NSCard, NSTable, NSCheckbox, NSBadge, NSModal, NSButton, NSSlideout, NSDropdown } from '../../bricks';
import ExpandableSection from '../../components/ExpandableSection/ExpandableSection';
import { UNIT_TYPE_NAMES } from '../../constants/unitTypes';

export interface ITiffProps {
    age?: number;
}

const Tiff = ({ age }: ITiffProps) => {
    const [checked, setChecked] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openSlideout, setOpenSlideout] = useState(false);

    const createUnitTypeOptions = () => {
        return Object.values(UNIT_TYPE_NAMES).map(unitType => ({
            label: unitType.dropdownItemName,
            value: unitType.value,
        }));
    };

    const unitTypeOptions = createUnitTypeOptions();
    const [unitType, setUnitType] = useState<IOptionAnyValue>(unitTypeOptions?.[0]);
    const [selectedOption, setSelectedOption] = useState<IOptionAnyValue>(unitTypeOptions?.[0]);
    const selectedUnitTypeNameMap = UNIT_TYPE_NAMES[unitType.value as keyof typeof UNIT_TYPE_NAMES];

    return (
        <>
            <NSCard className="p-3 m-3">
                <span>top level yay!</span>
                <div className="my-1">
                    <NSCheckbox iconOnly checked={checked} id="checkbox-id" callback={() => setChecked(!checked)} disabled />
                </div>
                <div className="my-1">
                    <NSBadge color="primary">hello</NSBadge>
                </div>
                <NSCard className="NSCard--level-2 p-3">
                    <span>NSCard--level-2</span>
                    <h4>Hello im Tiff</h4>
                    <NSCard className="NSCard--level-3 p-3">
                        <span>NSCard--level-3</span>
                        <p>{`I am ${age || 29}.`}</p>
                        <NSCard className="NSCard--level-4 p-3">
                            <span>NSCard--level-4</span>
                            <div>
                                <NSButton text="Open modal" color="primary" callback={() => setOpenModal(!openModal)} />
                                <NSModal isOpen={openModal} toggle={() => setOpenModal(!openModal)} />
                            </div>
                            <div>
                                <NSButton text="Open slideout" color="info" callback={() => setOpenSlideout(!openSlideout)} />
                                <NSSlideout isOpen={openSlideout} toggle={() => setOpenSlideout(!openSlideout)}>
                                    Im a slideout
                                </NSSlideout>
                            </div>
                            <NSCard className="NSCard--level-5 p-3">
                                <span>NSCard--level-5</span>
                            </NSCard>
                        </NSCard>
                    </NSCard>
                </NSCard>

                <div className="mt-3">
                    <small>NSDropdown</small>
                    <NSDropdown
                        options={unitTypeOptions}
                        selectedOption={selectedOption}
                        handleSelectOption={setSelectedOption}
                        containerClassName="mr-2"
                    />
                </div>

                <NSTable className="mt-3">
                    <thead className="NSTable__thead">
                        <tr className="NSTable__thead__tr">
                            <th className="NSTable__thead__tr__th">Header 1</th>
                            <th className="NSTable__thead__tr__th">Header 2</th>
                            <th className="NSTable__thead__tr__th">
                                <div className="d-flex">
                                    <span className="mr-2">NSDropdown</span>
                                    <NSDropdown
                                        options={unitTypeOptions}
                                        selectedOption={unitType}
                                        handleSelectOption={setUnitType}
                                        customSelectedOptionText={selectedUnitTypeNameMap.abbreviated}
                                        optionClassName={selectedUnitTypeNameMap.heapTag}
                                        badgeColor="dark-lighten"
                                        isBadgeToggle
                                    />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="NSTable__tbody">
                        <tr className="NSTable__tbody__tr">
                            <td className="NSTable__tbody__tr__td">Level 1, Cell 1</td>
                            <td className="NSTable__tbody__tr__td">Level 1, Cell 2</td>
                            <td className="NSTable__tbody__tr__td">Level 1, Cell 3</td>
                        </tr>

                        <tr className="NSTable__tbody__tr NSTable__tbody__tr--level-1">
                            <td className="NSTable__tbody__tr__td">Level 1, Cell 1</td>
                            <td className="NSTable__tbody__tr__td">Level 1, Cell 2</td>
                            <td className="NSTable__tbody__tr__td">Level 1, Cell 3</td>
                        </tr>

                        <tr className="NSTable__tbody__tr NSTable__tbody__tr--level-2">
                            <td className="NSTable__tbody__tr__td">Level 2, Cell 1</td>
                            <td className="NSTable__tbody__tr__td">Level 2, Cell 2</td>
                            <td className="NSTable__tbody__tr__td">Level 2, Cell 3</td>
                        </tr>

                        <tr className="NSTable__tbody__tr NSTable__tbody__tr--level-3 NSTable__tbody__tr--hoverable">
                            <td className="NSTable__tbody__tr__td">Level 3, Cell 1</td>
                            <td className="NSTable__tbody__tr__td">Level 3, Cell 2</td>
                            <td className="NSTable__tbody__tr__td">Level 3, Cell 3</td>
                        </tr>

                        <tr className="NSTable__tbody__tr NSTable__tbody__tr--level-4 NSTable__tbody__tr--hoverable">
                            <td className="NSTable__tbody__tr__td">Level 4, Cell 1</td>
                            <td className="NSTable__tbody__tr__td">Level 4, Cell 2</td>
                            <td className="NSTable__tbody__tr__td">Level 4, Cell 3</td>
                        </tr>
                    </tbody>
                    <tfoot className="NSTable__tfoot">
                        <tr className="NSTable__tfoot__tr">
                            <td className="NSTable__tfoot__tr__td">Footer 1</td>
                            <td className="NSTable__tfoot__tr__td">Footer 2</td>
                            <td className="NSTable__tfoot__tr__td">Footer 3</td>
                        </tr>
                    </tfoot>
                </NSTable>
            </NSCard>

            <ExpandableSection
                number={1} // Optional
                title="Components"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                className="mt-3 mx-3"
                isExpanded // Optional (default is false)
                animate // Optional (default is false)
            >
                <div className="m-3">
                    <p>
                        Forgive weren't beautiful declare malcontent lump doorstep second working downfall Shelob's? Toad madness swept around Tilda human
                        room ugly fence tingle expert! Host those smallest kingsfoil Gloin corpses. Walks course goal payment Rauros cares lever mat
                        natural. Devils wild locking Stone-Giants raid biting approach happily raising thrush forth. Queen investment battles nightfall
                        lightest malcontent avalanche Caradhras smack could've push threats. Do not take me for some conjurer of cheap tricks. Courageous
                        Gandalf the Grey stubbornness adventures. Days swish characters vain tollgate lowly splash ate cast Dwarf-city surprises she.
                        Cirith steal reinforcements!
                    </p>
                    <p>
                        Handful Haldir gonna woken? Forests shattered finds Gon bur moonlight credit thing watchful sit grateful. Portents vouch silver
                        banner nighttime Entwives really downfall. Serpent almost quickly fleet band Freda? Pigheaded dared herald coins glow oozy
                        Tuckborough land verse. Strength's Ori mellon warm favorite. You are full of surprises, Master Baggins. Saws clinging risk Isengard
                        nighttime tip glows canal. Justice scales hero Bar-hrum carries!
                    </p>
                </div>
            </ExpandableSection>
        </>
    );
};

export default Tiff;
