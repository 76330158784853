import React, { createContext, useContext, useEffect, useState, useCallback, ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LogoutOptions } from '@auth0/auth0-react/src/auth0-context';
import { setAxiosAccessToken } from 'ns_libs/nsAxios';
import { config } from '../config';

interface Auth0TokenContextValue {
    isLoading: boolean;
    isAuthenticated: boolean;
    accessToken?: string;
    login: () => Promise<void>;
    logout: (options?: LogoutOptions) => Promise<void>;
}

interface Auth0TokenContextProviderProps {
    children: ReactNode;
}

const Auth0TokenContext = createContext<Auth0TokenContextValue | null>(null);

/**
 * Provides Auth0 access token. Automatically refreshes it.
 */
export const useAuth0TokenContext = () => {
    const currentAuth0Context = useContext(Auth0TokenContext);

    if (!currentAuth0Context) {
        throw new Error('useAuth0TokenContext has to be used within <Auth0TokenContext.Provider>');
    }

    return currentAuth0Context;
};

export const Auth0TokenProvider = ({ children }: Auth0TokenContextProviderProps) => {
    const { isAuthenticated, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchAccessToken = useCallback(async () => {
        try {
            setIsLoading(true);
            /*
       Request access token silently. This method could behave in 3 possible ways:
       1. Open hidden iframe with Auth0 domain with our tenant and request access token using /authorize request. Auth0
          SDK will authorize using cookie from Auth0. It is considered 3rd party cookie, as Auth0 domain is not the same
          as ours. Such approach will work only if 3rd party cookies are allowed for current origin
       2. Use verification page if Auth0 detects that 3rd party cookies are disabled. We have disabled it, so this case
          is not applicable.
       3. If refresh tokens are used, the token endpoint is called directly with the 'refresh_token' grant. We
          do not use refresh tokens.
       4. If we set up custom domain, then getAccessTokenSilently will behave same as #1, but request from the hidden
          iframe will be to origin subdomain, so it will use first party cookie, and it will not be blocked by
          any browser.
      */
            const token = await getAccessTokenSilently();
            setAccessToken(token);
            setAxiosAccessToken(token);
            window.localStorage.setItem('accessToken', token);
        } catch (error) {
            // Login is required. Do nothing as it is another component responsibility to show Login button when
            // 'isLoading' becomes false and 'isAuthenticated' becomes false.
        }
        setIsLoading(false);
    }, [getAccessTokenSilently]);

    // Refresh the token periodically using interval and when isAuthenticated is changed
    useEffect(() => {
        const intervalId = setInterval(
            () => {
                fetchAccessToken();
            },
            Number(config.auth0AccessTokenRefreshIntervalMinutes) * 60 * 1000,
        );

        // Initial fetch when the component mounts or when isAuthenticated is changed
        fetchAccessToken();

        return () => {
            clearInterval(intervalId);
        };
    }, [fetchAccessToken]);

    return (
        <Auth0TokenContext.Provider
            value={{
                isLoading,
                isAuthenticated,
                accessToken,
                login: loginWithRedirect,
                logout,
            }}
        >
            {children}
        </Auth0TokenContext.Provider>
    );
};
