import React, { useState } from 'react';
import { DropdownItem, DropdownMenu, Dropdown, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { NSBadge } from '../index';
import RenderIf from '../../components/RenderIf/RenderIf';
import { IOptionAnyValue } from '../types';
import './NSDropdown.scss';

export interface INSDropdownProps {
    options: IOptionAnyValue[];
    selectedOption?: IOptionAnyValue;
    handleSelectOption: (option: IOptionAnyValue) => void;
    isBadgeToggle?: boolean;
    badgeColor?: string;
    customSelectedOptionText?: string;
    containerClassName?: string;
    optionClassName?: string;
    placeholder?: string;
    dropdownClassName?: string;
}

const NSDropdown = ({
    options,
    selectedOption,
    handleSelectOption,
    isBadgeToggle,
    badgeColor,
    customSelectedOptionText,
    containerClassName,
    optionClassName,
    isFullWidth,
    placeholder,
    dropdownClassName,
}: INSDropdownProps & { isFullWidth?: boolean }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const toggle = () => setIsDropdownOpen(!isDropdownOpen);

    return (
        <Dropdown isOpen={isDropdownOpen} toggle={toggle} className={`${isFullWidth ? 'w-100' : ''} ${containerClassName}`}>
            <DropdownToggle
                {...(isBadgeToggle && { tag: 'div' })}
                className={classNames(['text-nowrap cursor--pointer', dropdownClassName, { 'd-block w-100': isFullWidth }])}
            >
                <RenderIf isTrue={isBadgeToggle}>
                    <NSBadge color={badgeColor} className={isFullWidth ? 'w-100' : ''}>
                        <h6 className="m-0 font-weight-normal">
                            {customSelectedOptionText || selectedOption?.label || placeholder}
                            <FontAwesomeIcon icon={faCaretDown} className="ml-1" size="xs" />
                        </h6>
                    </NSBadge>
                </RenderIf>
                <RenderIf isTrue={!isBadgeToggle}>
                    <div className="d-flex justify-content-between align-items-center">
                        <span
                            className={classNames({
                                'text-muted': !customSelectedOptionText && !selectedOption?.label,
                            })}
                        >
                            {customSelectedOptionText || selectedOption?.label || placeholder}
                        </span>
                        <FontAwesomeIcon icon={faCaretDown} className="ml-2" size="xs" />
                    </div>
                </RenderIf>
            </DropdownToggle>
            <DropdownMenu className={classNames(['NSDropdown__dropdown-menu overflow-y--auto', { 'w-100': isFullWidth }])}>
                {options.map(option => (
                    <DropdownItem
                        key={option.value}
                        onClick={() => handleSelectOption(option)}
                        className={optionClassName}
                        disabled={option.disabled || false}
                        toggle={option.toggle ?? true}
                    >
                        {option.component || option.label}
                        <RenderIf isTrue={selectedOption?.value === option.value}>
                            <FontAwesomeIcon icon={faCheck} className="ml-1" />
                        </RenderIf>
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};

export default NSDropdown;
