import { NSButton } from 'bricks';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Col, Row } from 'reactstrap';
import PageTitle from 'components/PageTitle/PageTitle';
import classNames from 'classnames';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import ReportTypeComponent from './ReportType';
import { REPORT_TYPES } from './constants';
import { IDeal, ReportType } from '../types';
import DealSelection from '../components/DealSelection/DealSelection';
import { ComparisonReport, PipelineReport } from '../ReportTypes';

interface ICreateReportProps {
    setIsCreateReportShown: Dispatch<SetStateAction<boolean>>;
}

const CreateReport = ({ setIsCreateReportShown }: ICreateReportProps) => {
    const [selectedReportType, setSelectedReportType] = useState<string | null>(null);
    const [selectedDeals, setSelectedDeals] = useState<IDeal[]>([]);
    const [showCreateReport, setShowCreateReport] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const handleSelection = (reportType: string) => {
        setSelectedReportType(reportType !== selectedReportType ? reportType : null);
        if (selectedDeals.length < 2 && (reportType === ReportType.Comparison || reportType === ReportType.Aggregate)) {
            setShowModal(true);
        } else {
            setShowCreateReport(true);
        }
    };

    const handleCloseReport = () => {
        setSelectedReportType(null);
        setShowCreateReport(false);
    };

    return (
        <>
            <div
                className={classNames([
                    'm-1',
                    {
                        'd-none': showCreateReport,
                    },
                ])}
            >
                <PageTitle title="Create Report" subtitle="Select a report type for your new report">
                    <NSButton className="btn-secondary mr-2" callback={() => setIsCreateReportShown(false)} text="Exit" />
                </PageTitle>
                <DealSelection titleSuffix="DEALS SELECTED" selectedDeals={selectedDeals} setSelectedDeals={setSelectedDeals} />
                <Row className="d-flex justify-content-between mt-1">
                    {REPORT_TYPES.map(reportType => (
                        <Col key={reportType.name} xs={12} md={6} className="p-0">
                            <ReportTypeComponent
                                typeName={reportType.name}
                                subtitle={reportType.description}
                                icon={reportType.icon}
                                isSelected={selectedReportType === reportType.name}
                                disabledTooltip={reportType.disabledTooltip}
                                onClick={() => handleSelection(reportType.name)}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
            {selectedReportType === ReportType.Pipeline && <PipelineReport onClickBack={handleCloseReport} />}
            {showCreateReport && selectedReportType === ReportType.Comparison && <ComparisonReport onClickBack={handleCloseReport} />}
            {/* {showCreateReport && selectedReportType === ReportType.Aggregate && <AggregateReport onClickBack={handleCloseReport} />} */}
            <ConfirmationModal
                toggle={() => setShowModal(!showModal)}
                isOpen={showModal}
                onConfirm={() => {
                    setShowCreateReport(true);
                    setShowModal(false);
                }}
                onCancel={() => {
                    setSelectedReportType(null);
                    setShowModal(false);
                }}
                modalHeaderText="Warning: report selection"
                modalFooterButtonText="Go back to selection"
                modalFooterCancelButtonText="Continue anyway"
                backdrop="static"
                danger
            >
                <h4 className="text-white font-weight-bold">We strongly recommend adding at least {2 - selectedDeals.length} more deal.</h4>
                <p className="text-white">{`${selectedReportType} are intended for more than one selected deal. Are you sure you want to continue?`}</p>
            </ConfirmationModal>
        </>
    );
};

export default CreateReport;
