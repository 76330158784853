import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSButton } from 'bricks';
import { UncontrolledTooltip } from 'reactstrap';
import React, { useMemo, useCallback, useState } from 'react';

export interface IProps {
    id: string;
    values: string[];
    maxLength?: number;
    excludeDuplicates?: boolean;
    newlineSeparated?: boolean;
    canExpand?: boolean;
    placeholder?: string;
    textClassName?: string;
}

export const RollupItem = ({
    id,
    values,
    maxLength = 1,
    excludeDuplicates = true,
    newlineSeparated = false,
    canExpand = false,
    placeholder = '',
    textClassName = '',
}: IProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const uniqueValues = useMemo(() => (excludeDuplicates ? [...new Set(values)] : values), [values, excludeDuplicates]);

    const displayedValues = useMemo(() => uniqueValues.slice(0, maxLength), [uniqueValues, maxLength]);

    const hiddenValues = useMemo(() => uniqueValues.slice(maxLength), [uniqueValues, maxLength]);

    const rollup = useMemo(
        () => (uniqueValues.length > maxLength ? `+${hiddenValues.length} more` : null),
        [uniqueValues.length, maxLength, hiddenValues.length],
    );

    const renderValues = useCallback(
        (vals: string[]) => {
            if (!vals || vals.length === 0) {
                return placeholder;
            }
            if (newlineSeparated) {
                return vals.map(val => (
                    <div key={`rollup-item-${val}`} className={textClassName}>
                        {val}
                    </div>
                ));
            }
            return vals.join(', ');
        },
        [newlineSeparated, placeholder],
    );

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="d-flex">
            <div className="text-truncate">
                {renderValues(isExpanded ? uniqueValues : displayedValues)}
                {canExpand && rollup && (
                    <div onClick={handleToggle} className="text-primary cursor-pointer" role="presentation">
                        <FontAwesomeIcon className="icon mr-1" icon={isExpanded ? faMinus : faPlus} size="sm" />
                        {isExpanded ? 'see less' : `see all (${uniqueValues.length})`}
                    </div>
                )}
            </div>
            {rollup && !canExpand && (
                <>
                    <NSButton id={id} callback={() => null} className="text-primary py-0 px-1 border-0" text={rollup} />
                    <UncontrolledTooltip target={id} autohide={false}>
                        {renderValues(hiddenValues)}
                    </UncontrolledTooltip>
                </>
            )}
        </div>
    );
};

export default RollupItem;
