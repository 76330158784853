/* eslint-disable no-unused-vars */
export enum TABS {
    ATTRIBUTES = 'Attributes',
    FILTERS = 'Filters',
}

export enum Attributes {
    Name = 'Name',
    Address = 'Address',
    City = 'City',
    County = 'County',
    State = 'State',
    ZipCode = 'Zip code',
    Market = 'Market',
    Submarket = 'Submarket',
    DateCreated = 'Date created',
    Description = 'Description',
    DealType = 'Deal type',
    AssetClass = 'Asset class',
    ConstructionType = 'Construction type',
    Units = 'Units',
    Phase = 'Phase',
    Progress = 'Progress',
    LeadSource = 'Lead source',
    LeasingStrategy = 'Leasing strategy',
    Rank = 'Rank',
    AcquisitionPrice = 'Acquisition price',
    TargetReturnOnCost = 'Target return on cost',
    TargetReturnOnEquity = 'Target return on equity',
    TargetProfitReturn = 'Target profit return',
    IRR = 'IRR',
    EquityMultiple = 'Equity multiple',
    DealCreator = 'Deal creator',
    DealOwner = 'Deal owner',
    DevelopmentManager = 'Development manager',
    EquityPartner = 'Equity partner',
    Lender = 'Lender',
    LegalCounsel = 'Legal counsel',
    Architect = 'Architect',
    Engineer = 'Engineer',
    Surveyor = 'Surveyor',
    EnvironmentalConsultant = 'Environmental Consultant',
    UserDefined = 'User defined',
}

export const ATTRIBUTE_TYPES: string[] = [
    Attributes.Name,
    Attributes.Address,
    Attributes.City,
    Attributes.County,
    Attributes.State,
    Attributes.ZipCode,
    Attributes.Market,
    Attributes.Submarket,
    Attributes.DateCreated,
    Attributes.Description,
    Attributes.DealType,
    Attributes.AssetClass,
    Attributes.ConstructionType,
    Attributes.Units,
    Attributes.Phase,
    Attributes.Progress,
    Attributes.LeadSource,
    Attributes.LeasingStrategy,
    Attributes.Rank,
    Attributes.AcquisitionPrice,
    Attributes.TargetReturnOnCost,
    Attributes.TargetReturnOnEquity,
    Attributes.TargetProfitReturn,
    Attributes.IRR,
    Attributes.EquityMultiple,
    Attributes.DealCreator,
    Attributes.DealOwner,
    Attributes.DevelopmentManager,
    Attributes.EquityPartner,
    Attributes.Lender,
    Attributes.LegalCounsel,
    Attributes.Architect,
    Attributes.Engineer,
    Attributes.Surveyor,
    Attributes.EnvironmentalConsultant,
    Attributes.UserDefined,
];
