/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo } from 'react';
import { NSCard } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleUp, faChevronCircleDown } from '@fortawesome/pro-regular-svg-icons';
import { IDeal } from 'views/Reports/types';
import { IOptionAnyValue } from 'bricks/types';
import DealSelector from './DealSelector';

interface IDealSelectionProps {
    titlePrefix?: string;
    titleSuffix?: string;
    selectedDeals: IDeal[];
    setSelectedDeals: (deals: IDeal[]) => void;
}

const DealSelection = ({ titlePrefix, titleSuffix, selectedDeals, setSelectedDeals }: IDealSelectionProps) => {
    const deals: IDeal[] = useMemo(
        () => [
            {
                id: 1,
                dealName: 'Deal 1 with long long long  long long  long long  long long  long long  long long name ',
                dealType: 'Type A',
                project: 'Project Alpha',
                projectType: 'Commercial',
            },
            { id: 2, dealName: 'Deal 2', dealType: 'Type B', project: 'Project Bravo', projectType: 'Residential' },
            { id: 3, dealName: 'Deal 3', dealType: 'Type C', project: 'Project Charlie', projectType: 'Industrial' },
            { id: 4, dealName: 'Deal 4', dealType: 'Type D', project: 'Project Delta', projectType: 'Commercial' },
            { id: 5, dealName: 'Deal 5', dealType: 'Type E', project: 'Project Echo', projectType: 'Residential' },
            { id: 6, dealName: 'Deal 6', dealType: 'Type F', project: 'Project Foxtrot', projectType: 'Industrial' },
            { id: 7, dealName: 'Deal 7', dealType: 'Type G', project: 'Project Golf', projectType: 'Commercial' },
            { id: 8, dealName: 'Deal 8', dealType: 'Type H', project: 'Project Hotel', projectType: 'Residential' },
            { id: 9, dealName: 'Deal 9', dealType: 'Type I', project: 'Project India', projectType: 'Industrial' },
            { id: 10, dealName: 'Deal 10', dealType: 'Type J', project: 'Project Juliet', projectType: 'Commercial' },
            { id: 11, dealName: 'Deal 11', dealType: 'Type K', project: 'Project Kilo', projectType: 'Residential' },
            { id: 12, dealName: 'Deal 12', dealType: 'Type L', project: 'Project Lima', projectType: 'Industrial' },
            { id: 13, dealName: 'Deal 13', dealType: 'Type M', project: 'Project Mike', projectType: 'Commercial' },
            { id: 14, dealName: 'Deal 14', dealType: 'Type N', project: 'Project November', projectType: 'Residential' },
            { id: 15, dealName: 'Deal 15', dealType: 'Type O', project: 'Project Oscar', projectType: 'Industrial' },
            { id: 16, dealName: 'Deal 16', dealType: 'Type P', project: 'Project Papa', projectType: 'Commercial' },
            { id: 17, dealName: 'Deal 17', dealType: 'Type Q', project: 'Project Quebec', projectType: 'Residential' },
            { id: 18, dealName: 'Deal 18', dealType: 'Type R', project: 'Project Romeo', projectType: 'Industrial' },
            { id: 19, dealName: 'Deal 19', dealType: 'Type S', project: 'Project Sierra', projectType: 'Commercial' },
            { id: 20, dealName: 'Deal 20', dealType: 'Type T', project: 'Project Tango', projectType: 'Residential' },
            { id: 21, dealName: 'Deal 21', dealType: 'Type U', project: 'Project Uniform', projectType: 'Industrial' },
            { id: 22, dealName: 'Deal 22', dealType: 'Type V', project: 'Project Victor', projectType: 'Commercial' },
            { id: 23, dealName: 'Deal 23', dealType: 'Type W', project: 'Project Whiskey', projectType: 'Residential' },
            { id: 24, dealName: 'Deal 24', dealType: 'Type X', project: 'Project X-ray', projectType: 'Industrial' },
            { id: 25, dealName: 'Deal 25', dealType: 'Type Y', project: 'Project Yankee', projectType: 'Commercial' },
            { id: 26, dealName: 'Deal 26', dealType: 'Type Z', project: 'Project Zulu', projectType: 'Residential' },
            { id: 27, dealName: 'Deal 27', dealType: 'Type AA', project: 'Project Alpha Alpha', projectType: 'Industrial' },
            { id: 28, dealName: 'Deal 28', dealType: 'Type BB', project: 'Project Bravo Bravo', projectType: 'Commercial' },
            { id: 29, dealName: 'Deal 29', dealType: 'Type CC', project: 'Project Charlie Charlie', projectType: 'Residential' },
            { id: 30, dealName: 'Deal 30', dealType: 'Type DD', project: 'Project Delta Delta', projectType: 'Industrial' },
            { id: 31, dealName: 'Deal 31', dealType: 'Type EE', project: 'Project Echo Echo', projectType: 'Commercial' },
            { id: 32, dealName: 'Deal 32', dealType: 'Type FF', project: 'Project Foxtrot Foxtrot', projectType: 'Residential' },
            { id: 33, dealName: 'Deal 33', dealType: 'Type GG', project: 'Project Golf Golf', projectType: 'Industrial' },
            { id: 34, dealName: 'Deal 34', dealType: 'Type HH', project: 'Project Hotel Hotel', projectType: 'Commercial' },
            { id: 35, dealName: 'Deal 35', dealType: 'Type II', project: 'Project India India', projectType: 'Residential' },
            { id: 36, dealName: 'Deal 36', dealType: 'Type JJ', project: 'Project Juliet Juliet', projectType: 'Industrial' },
            { id: 37, dealName: 'Deal 37', dealType: 'Type KK', project: 'Project Kilo Kilo', projectType: 'Commercial' },
            { id: 38, dealName: 'Deal 38', dealType: 'Type LL', project: 'Project Lima Lima', projectType: 'Residential' },
            { id: 39, dealName: 'Deal 39', dealType: 'Type MM', project: 'Project Mike Mike', projectType: 'Industrial' },
            { id: 40, dealName: 'Deal 40', dealType: 'Type NN', project: 'Project November November', projectType: 'Commercial' },
        ],
        [],
    );

    const [showDeals, setShowDeals] = useState<boolean>(false);

    const dealOptions = useMemo(() => {
        return deals.map(d => ({
            value: d.id,
            label: d.dealName,
            disabled: selectedDeals.some(selected => selected.id === d.id), // Disable if in selectedDeals
        }));
    }, [deals, selectedDeals]);

    const addSelectedDeal = (dealSelection?: IOptionAnyValue) => {
        if (!dealSelection) return;

        const updatedDeals = [...selectedDeals, deals.find(i => i.id === dealSelection.value)!];
        setSelectedDeals(updatedDeals);
    };

    const removeSelectedDeal = (deal: IDeal) => {
        const filteredDeals = selectedDeals.filter(d => d.id !== deal.id);
        setSelectedDeals(filteredDeals);
    };

    const changeSelectedDeal = (deal: IDeal, dealSelection: IOptionAnyValue) => {
        const updatedDeals = selectedDeals.map(d => {
            if (d.id === deal.id) return deals.find(i => i.id === dealSelection.value)!;
            return d;
        });
        setSelectedDeals(updatedDeals);
    };

    const clearSelectedDeals = () => setSelectedDeals([]);

    const handleDealSelectionChange = (deal: IDeal, newSelection?: IOptionAnyValue) => {
        if (!newSelection) {
            removeSelectedDeal(deal);
        } else {
            changeSelectedDeal(deal, newSelection);
        }
    };

    return (
        <NSCard className="NSCard--level-2 mt-2 p-2">
            <div className="d-flex justify-content-between p-1">
                <span>
                    {`${titlePrefix ? `${titlePrefix} ` : ''}${selectedDeals.length}${titleSuffix ? ` ${titleSuffix}` : ''}`}
                    {selectedDeals.length > 0 && (
                        <span onClick={clearSelectedDeals} className="text-primary cursor--pointer ml-1">
                            Clear
                        </span>
                    )}
                </span>
                <span onClick={() => setShowDeals(!showDeals)}>
                    <span className="text-primary cursor--pointer">
                        {showDeals ? 'Hide deals' : 'Show deals'}
                        <FontAwesomeIcon className="ml-1" icon={showDeals ? faChevronCircleUp : faChevronCircleDown} />
                    </span>
                </span>
            </div>

            {showDeals && (
                <div className="d-flex flex-wrap justify-content-between">
                    {selectedDeals.map((deal: IDeal) => (
                        <div className="col-6 p-1" key={`selected-deal-selector-${deal.id}`}>
                            <DealSelector
                                dealOptions={dealOptions}
                                selectedDeal={deal}
                                onChangeDealSelection={newSelection => handleDealSelectionChange(deal, newSelection)}
                            />
                        </div>
                    ))}
                    <div className="col-6 p-1">
                        <DealSelector dealOptions={dealOptions} onChangeDealSelection={addSelectedDeal} />
                    </div>
                </div>
            )}
        </NSCard>
    );
};

export default DealSelection;
