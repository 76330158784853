import React from 'react';
import './EmptyState.scss';
import { NSCard } from 'bricks';

export type IProps = {
    title?: string;
    subtitle?: string;
    children?: React.ReactNode;
};

const EmptyState = ({ title, subtitle, children }: IProps) => {
    return (
        <NSCard className="emptyState d-flex flex-column justify-content-center align-items-center mt-2 vh-100">
            <h3 className="emptyState__title mb-1 text-white">{title}</h3>
            <p className="emptyState__description mt-0 mb-2 text-muted">{subtitle}</p>
            {children}
        </NSCard>
    );
};

export default EmptyState;
