import React from 'react';
import { ComparisonGrid, IGridConfig } from 'components/ComparisonGrid';
import { IOptionAnyValue } from 'bricks/types';
import { AddToComparisonSimpleSelect } from 'components/ComparisonGrid/components';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import { ReportHeader } from '../../ReportHeader/ReportHeader';
import { IReport, IComparisonReportData } from '../../types';
import { BasicMetric, CostMetrics, InvestmentMetrics, ProjectionMetrics } from './metrics';
import { ColumnHeader } from './ColumnHeader';

export interface IProps {
    onClickBack: () => void;
}

export const ComparisonReport = ({ onClickBack }: IProps) => {
    const [selectedOption, setSelectedOption] = React.useState<IOptionAnyValue>();

    // TODO: Replace with data from API && update corresponding type declarations
    const data: IComparisonReportData[] = [
        {
            name: '400 Highland',
            unlevered: { ROI: 391.1, EMx: 4.91, IRR: 63.83 },
            levered: { ROI: 89.0, EMx: 1.89, IRR: 21.86 },
            yieldOnCost: {
                untrended: 100.0,
                trended: 4.91,
                sale: 63.83,
            },
            costMetrics: {
                usagePercent: 100.0,
                costPerBuildSF: 4.91,
                costPerUnit: 63.83,
                amount: 100.0,
            },
            units: 884203.3,
            netRentableSF: 158031.3,
        },
        {
            name: 'Sunset Ridge Estates',
            unlevered: { ROI: 350.2, EMx: 3.5, IRR: 55.3 },
            levered: { ROI: 75.0, EMx: 1.5, IRR: 18.0 },
            units: 750203.0,
            netRentableSF: 130031.5,
        },
    ];

    // TODO: Replace with data from API
    const selectionConfig = {
        label: 'Add to comparison',
        placeholder: 'Select a deal',
        options: [
            { label: '400 Highland', value: '400 Highland' },
            { label: 'Sunset Ridge Estates', value: 'Sunset Ridge Estates' },
        ],
        onSelectOption: setSelectedOption,
        selectedOption,
    };

    // TODO: Replace with data from API
    type IReportName = Pick<IReport, 'name'>;
    const savedReports: IReportName[] = [{ name: 'Report 1' }, { name: 'Report 2' }, { name: 'Report 3' }];

    const gridConfig: IGridConfig = {
        headerRowFormatter: data => <ColumnHeader data={data} />,
        groups: [
            {
                groupLabel: 'Property Returns',
                rows: [
                    { label: 'Unlevered', formatter: data => <InvestmentMetrics data={data.unlevered} /> },
                    { label: 'Levered', formatter: data => <InvestmentMetrics data={data.levered} /> },
                ],
            },
            {
                groupLabel: 'Development Returns',
                rows: [{ label: 'Yield-on-cost', formatter: data => <ProjectionMetrics data={data.yieldOnCost} /> }],
            },
            {
                groupLabel: 'Details',
                rows: [
                    { label: 'Units', formatter: data => <BasicMetric value={numeralFormatterCurrency(data.units)} /> },
                    { label: 'Net Rentable SF', formatter: data => <BasicMetric value={numeralFormatterCurrency(data.netRentableSF)} /> },
                ],
            },
            {
                groupLabel: 'Uses',
                rows: [{ label: 'Acquisition costs', formatter: data => <CostMetrics data={data.costMetrics} /> }],
            },
        ],
        data,
    };

    return (
        <div>
            <ReportHeader
                reportName="Comparison Report"
                onClickBack={onClickBack}
                onChangeName={() => console.log('change name')}
                onClickExport={() => console.log('export')}
                onClickSave={() => console.log('save')}
                onClickEdit={() => console.log('edit')}
                savedReports={savedReports}
            />
            <ComparisonGrid
                gridConfig={gridConfig}
                renderAddToComparison={() => (
                    <AddToComparisonSimpleSelect
                        label={selectionConfig.label}
                        placehoder={selectionConfig.placeholder}
                        options={selectionConfig.options}
                        onSelectOption={selectionConfig.onSelectOption}
                        selectedOption={selectionConfig.selectedOption}
                    />
                )}
            />
        </div>
    );
};

export default ComparisonReport;
