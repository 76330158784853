import { useMutation } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { IFormValues, IUpdateVariables } from '../types';

const updateDeal = async ({ postData, orgId, dealId }: IUpdateVariables): Promise<IFormValues> => {
    const response = await axiosInstance.patch(`${API_ENDPOINTS.DEALS_BY_ID(orgId, dealId)}`, postData);
    return response.data;
};

export const useUpdateDeal = () =>
    useMutation({
        mutationFn: updateDeal,
    });
