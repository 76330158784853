/* eslint-disable no-unused-vars */
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

export enum ReportType {
    Aggregate = 'Aggregate Report',
    Comparison = 'Comparison Report',
    Pipeline = 'Pipeline Report',
}

export const ReportTypeIdToNameMap: Record<number, string> = {
    1: 'Pipeline',
    2: 'Comparison',
    3: 'Aggregate',
};

export interface IReportType {
    type: ReportType;
    name: string;
    description: string;
    icon: IconDefinition;
    disabledTooltip: JSX.Element;
}

export interface IDeal {
    id: number;
    dealName: string;
    dealType: string;
    project: string;
    projectType: string;
}

export interface IReportsQueryParams {
    pageSize: number;
    currentPage: number;
    orgId: string;
}

export interface IReportsResponse {
    items: Array<IReport>;
    itemCount: number;
}

export interface IReport {
    id: number;
    name: string;
    description: string;
    type: number;
    createdAt: string;
    metadata: any;
    deals: Array<string>;
}

export interface IReportName {
    name: string;
}
// FIXME: This is purely a placeholder. The actual data structure will definitely be different.

export interface IComparisonReportData {
    name: string;
    unlevered?: IInvestmentMetricsData;
    levered?: IInvestmentMetricsData;
    yieldOnCost?: IProjectionMetricsData;
    costMetrics?: ICostMetricsData;
    units?: number;
    netRentableSF?: number;
}

export interface IInvestmentMetricsData {
    ROI?: number;
    EMx?: number;
    IRR?: number;
}

export interface IProjectionMetricsData {
    untrended?: number;
    trended?: number;
    sale?: number;
}

export interface ICostMetricsData {
    usagePercent?: number;
    costPerBuildSF?: number;
    costPerUnit?: number;
    amount?: number;
}
