export const config = {
    environment: process.env.REACT_APP_ENV!,
    auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
    auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    auth0AccessTokenRefreshIntervalMinutes: process.env.REACT_APP_AUTH0_ACCESS_TOKEN_REFRESH_INTERVAL_MINUTES,
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
    webSocketBaseUrl: process.env.REACT_APP_WEBSOCKET_BASE_URL,
    northspyreProjectsAppUrl: process.env.REACT_APP_NORTHSPYRE_PROJECTS_APP_URL!,
    mapboxGlApiKey: process.env.REACT_APP_MAPBOX_GL_API_KEY!,
};
