/* eslint-disable no-unused-vars */
import * as Yup from 'yup';

export enum ASSET_TYPE {
    RENTAL_APARTMENT = 'rental_apartment',
    BUILT_FOR_RENT_SFH = 'built_for_rent_sfh',
    MIXED_USE_APARTMENT_OVER_RETAIL = 'mixed_use_apartment_over_retail',
    SENIOR_LIVING_FACILITY = 'senior_living_facility',
    INDUSTRIAL = 'industrial',
    DATA_CENTER = 'data_center',
    RETAIL = 'retail',
    OFFICE = 'office',
}

export const ASSET_TYPE_OPTIONS = [
    {
        label: 'Rental Apartment',
        value: ASSET_TYPE.RENTAL_APARTMENT,
    },
    {
        label: 'Built for Rent SFH',
        value: ASSET_TYPE.BUILT_FOR_RENT_SFH,
    },
    {
        label: 'Mixed Use – Apartment Over Retail',
        value: ASSET_TYPE.MIXED_USE_APARTMENT_OVER_RETAIL,
    },
    {
        label: 'Senior Living Facility',
        value: ASSET_TYPE.SENIOR_LIVING_FACILITY,
    },
    {
        label: 'Industrial',
        value: ASSET_TYPE.INDUSTRIAL,
    },
    {
        label: 'Data Center',
        value: ASSET_TYPE.DATA_CENTER,
    },
    {
        label: 'Retail',
        value: ASSET_TYPE.RETAIL,
    },
    {
        label: 'Office',
        value: ASSET_TYPE.OFFICE,
    },
];

export const ASSET_TYPE_MAPPING: Record<string, 'apartments' | 'office' | 'retail' | 'industrial' | 'data_center'> = {
    [ASSET_TYPE.RENTAL_APARTMENT]: 'apartments',
    [ASSET_TYPE.BUILT_FOR_RENT_SFH]: 'apartments',
    [ASSET_TYPE.MIXED_USE_APARTMENT_OVER_RETAIL]: 'apartments',
    [ASSET_TYPE.SENIOR_LIVING_FACILITY]: 'apartments',
    [ASSET_TYPE.INDUSTRIAL]: 'industrial',
    [ASSET_TYPE.DATA_CENTER]: 'data_center',
    [ASSET_TYPE.RETAIL]: 'retail',
    [ASSET_TYPE.OFFICE]: 'office',
};

export enum COST_BASIS_TYPES {
    PER_SF = 'per_sf',
    PER_UNIT = 'per_unit',
}

export const COST_BASIS_OPTIONS = [
    {
        label: 'Per Unit',
        value: COST_BASIS_TYPES.PER_UNIT,
    },
    {
        label: 'Per SF',
        value: COST_BASIS_TYPES.PER_SF,
    },
];

export enum UNIT_OF_TIME_TYPES {
    ANNUALLY = 'annually',
    MONTHLY = 'monthly',
}

export const UNIT_OF_TIME_OPTIONS = [
    {
        label: 'Monthly',
        value: UNIT_OF_TIME_TYPES.MONTHLY,
    },
    {
        label: 'Annually',
        value: UNIT_OF_TIME_TYPES.ANNUALLY,
    },
];

export enum LOAN_CALCULATION_TYPES {
    LTV = 'ltv',
    LTC = 'ltc',
    DY = 'dy',
}

export const LOAN_CALCULATION_OPTIONS = [
    {
        label: 'LTV',
        value: LOAN_CALCULATION_TYPES.LTV,
    },
    {
        label: 'LTC',
        value: LOAN_CALCULATION_TYPES.LTC,
    },
    {
        label: 'DY',
        value: LOAN_CALCULATION_TYPES.DY,
    },
];

export const validationSchema = Yup.object().shape({
    grossSquareFootage: Yup.number().required('Required'),
    efficiency: Yup.number().min(0).max(100).required('Required'),
    rentPrice: Yup.number().required('Required'),
    units: Yup.number().required('Required'),
    expenses: Yup.number().required('Required'),
    exitCapRate: Yup.number().required('Required'),
    acquisitionCosts: Yup.number().required('Required'),
    hardCosts: Yup.number().required('Required'),
    softCosts: Yup.number().required('Required'),
    developerFee: Yup.number().required('Required'),
    apr: Yup.number().required('Required'),
    avgLoanBalance: Yup.number().required('Required'),
    loanTerm: Yup.number().required('Required'),
    contructionFinancing: Yup.number().required('Required'),
    marketCapRate: Yup.number().nullable(),
});

export const defaultValues = {
    selectedAssetType: ASSET_TYPE_OPTIONS[0],
    selectedLoanCalculationOptions: LOAN_CALCULATION_OPTIONS[0],
    grossSquareFootage: null,
    efficiency: null,
    rentPrice: null,
    units: null,
    expenses: null,
    exitCapRate: null,
    acquisitionCosts: null,
    hardCosts: null,
    softCosts: null,
    developerFee: null,
    apr: null,
    avgLoanBalance: null,
    loanTerm: null,
    contructionFinancing: null,
    marketCapRate: null,
    developerFeeTotal: null, // Auto-calculated
    interestReserveTotal: null, // Auto-calculated
    totalDevelopmentCosts: null, // Auto-calculated
    loanAmount: null, // Auto-calculated
    equityCost: null, // Auto-calculated
};
