import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import NSTable from '../../../../bricks/NSTable/NSTable';
import { headerCellClassName } from '../../constants';

const NorthspyreProFormasTable = () => {
    return (
        <NSTable>
            <thead className="NSTable__thead">
                <tr className="NSTable__thead__tr">
                    <th className="NSTable__thead__tr__th">
                        <div className={headerCellClassName}>
                            <span>Name</span>
                            <FontAwesomeIcon icon="sort" />
                        </div>
                    </th>
                    <th className="NSTable__thead__tr__th">
                        <div className={headerCellClassName}>
                            <span>Scenario</span>
                            <FontAwesomeIcon icon="sort" />
                        </div>
                    </th>
                    <th className="NSTable__thead__tr__th">
                        <div className={headerCellClassName}>
                            <span>Last modified</span>
                            <FontAwesomeIcon icon="sort" />
                        </div>
                    </th>
                    <th className="NSTable__thead__tr__th">
                        <div className={headerCellClassName}>
                            <span>Date created</span>
                            <FontAwesomeIcon icon="sort" />
                        </div>
                    </th>
                    <th className="NSTable__thead__tr__th">
                        <div className={headerCellClassName}>
                            <span>Created by</span>
                            <FontAwesomeIcon icon="sort" />
                        </div>
                    </th>
                    <th className="NSTable__thead__tr__th">
                        <div className={headerCellClassName}>
                            <span>Status</span>
                            <FontAwesomeIcon icon="sort" />
                        </div>
                    </th>
                </tr>
            </thead>
        </NSTable>
    );
};

export default NorthspyreProFormasTable;
