import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import './ProjectMenu.scss';

// Mocked Constants
const PORTFOLIO = 'Portfolio';

// Mocked Data
const mockedUserSubprojects = [
    { id: 1, name: 'Subproject 1' },
    { id: 2, name: 'Subproject 2' },
];

const ProjectMenuDropdown = ({ subprojects }: { subprojects: any[] }) => {
    return (
        <div>
            {subprojects.map(project => (
                <div key={project.id || project.subprojectId} className="ProjectMenu-dropdown__dropdown-item cursor--pointer">
                    {project.name}
                </div>
            ))}
        </div>
    );
};

const ProjectMenu = () => {
    const userSubprojects = mockedUserSubprojects;

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleIsExpanded = () => setIsExpanded(!isExpanded);

    return (
        <Dropdown isOpen={isExpanded} toggle={toggleIsExpanded} direction="down" className="ProjectMenu-dropdown" role="menu">
            <DropdownToggle
                tag="div"
                className="btn btn-secondary d-flex align-items-center justify-content-between"
                onClick={toggleIsExpanded}
                data-testid="project-menu-toggle"
            >
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon className="mr-2" icon={faBriefcase} fixedWidth />
                    {PORTFOLIO}
                </div>
                <FontAwesomeIcon icon={isExpanded ? faCaretUp : faCaretDown} fixedWidth data-testid="caret-icon" />
            </DropdownToggle>

            <DropdownMenu className="ProjectMenu-dropdown__dropdown-menu">
                <ProjectMenuDropdown subprojects={userSubprojects} />
            </DropdownMenu>
        </Dropdown>
    );
};

export default ProjectMenu;
