import React from 'react';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSButton } from 'bricks';
import { INestedOptionAnyValue, SimplifiedScenario } from '../types';

export function transformScenarios(scenarios: SimplifiedScenario[]) {
    const dealScenarios: INestedOptionAnyValue[] = [];
    const uncategorizedScenarios: INestedOptionAnyValue[] = [];

    function createComponent(name: string) {
        return (
            <>
                <p>{name}</p>
                <FontAwesomeIcon icon={faChevronRight} className="ml-auto" />
            </>
        );
    }

    scenarios.forEach(scenario => {
        if (scenario.scenarios && scenario.scenarios.length > 0) {
            const submenu = [
                ...scenario.scenarios.map(subScenario => ({
                    value: subScenario.id,
                    label: subScenario.name,
                })),
                {
                    value: 'new',
                    label: `+ New scenario in ${scenario.name}`,
                    disabled: false,
                    component: (
                        <div className="w-100 p-1 m-0 button-container">
                            <NSButton color="primary" outline className="w-100" text={`+ New scenario in ${scenario.name}`} />
                        </div>
                    ),
                },
            ];

            dealScenarios.push({
                value: scenario.name,
                label: scenario.name,
                toggle: false,
                component: createComponent(scenario.name),
                submenu,
            });
        } else {
            uncategorizedScenarios.push({
                value: scenario.id,
                label: scenario.name,
            });
        }
    });

    return {
        dealScenarios,
        uncategorizedScenarios,
    };
}
