import React from 'react';
import { NSTable } from 'bricks';
import { IGridConfig } from '../types';

export interface IProps {
    gridConfig: IGridConfig;
}

export const ComparisonTable = ({ gridConfig }: IProps) => {
    const { data, groups, headerRowFormatter } = gridConfig;
    const numDataCols = data.length;

    return (
        <div className="ComparisonTable">
            <NSTable className="NSTable--no-footer">
                <tr className="NSTable--custom-header">
                    <td>{/* empty left most header row cell */}</td>
                    {data.map(col => (
                        <td key={col.name}>{headerRowFormatter(col)}</td>
                    ))}
                </tr>
                <tbody className="NSTable__tbody NSTable__tbody--show-column-borders">
                    {groups.map(group => (
                        <React.Fragment key={group.groupLabel}>
                            {/* Group Label Row */}
                            <tr className="NSTable__tbody__subheader">
                                <td className="">{group.groupLabel}</td>
                                {
                                    // emtpy cell for number of numDataCols
                                    Array.from({ length: numDataCols }).map((_, idx) => (
                                        <td key={`empty-${idx}`} /> // eslint-disable-line react/no-array-index-key
                                    ))
                                }
                            </tr>
                            {/* Group Rows */}
                            {group.rows.map(row => (
                                <tr className="NSTable__tbody__tr" key={`${group.groupLabel}-${row.label}`}>
                                    <td>{row.label}</td>
                                    {data.map(_data => (
                                        <td key={`${row.label}-${_data.id}`}>{row.formatter(_data)}</td>
                                    ))}
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </NSTable>
        </div>
    );
};

export default ComparisonTable;
