import React, { useState } from 'react';
import AddColumns from './AddColumns/AddColumns';
import SelectedColumns from './SelectedColumns/SelectedColumns';

const Attributes = ({ className }: { className?: string }) => {
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

    return (
        <div className={`Attributes d-flex flex-column ${className}`}>
            <SelectedColumns selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} />
            <hr />
            <AddColumns selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} />
        </div>
    );
};

export default Attributes;
