import React from 'react';
import { NSCard } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const UsesVsSourcesCard = () => (
    <NSCard className="NSCard--level-5 shadow-none m-2 text-dark">
        <div className="d-flex align-items-center mt-1 mb-1">
            <div className="ml-3">
                <h5 className="mb-0">Uses</h5>
                <h5 className="mb-1 mt-1">$1.500.000</h5>
            </div>
            <FontAwesomeIcon className="icon me-3 ml-2" icon={faChevronRight} fixedWidth size="sm" />
            <div className="ml-1 mr-3">
                <h5 className="mb-0">Sources</h5>
                <h5 className="mb-1 mt-1">$1.000.000</h5>
            </div>
        </div>
    </NSCard>
);

export default UsesVsSourcesCard;
