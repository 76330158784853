import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { Nav, NavItem } from 'reactstrap';
import { generatePath, useNavigate } from 'react-router-dom';
import { URLS } from 'services/urls';
import { PRO_FORMA_TABLE_NAV } from '../../constants';
import { NSButton, NSCard } from '../../../../bricks';
import EditProFormaModal from '../EditProFormaModal/EditProFormaModal';
import ProFormaSideNavFooter from './ProFormaSideNavFooter/ProFormaSideNavFooter';
import './ProFormaSideNav.scss';

export interface IProFormaSideNavProps {
    activeNav: string;
    navigateToSection: (sideNavName: string, tabName?: string) => void;
}

const ProFormaSideNav = ({ activeNav, navigateToSection }: IProFormaSideNavProps) => {
    const navigate = useNavigate();
    const [showEditProFormaModal, setShowEditProFormaModal] = useState(false);
    const toggleEditProFormaModal = () => setShowEditProFormaModal(!showEditProFormaModal);

    const navItemClass = (tag: string) =>
        classNames('d-flex align-items-center px-4 cursor--pointer', {
            'bg-primary-lighten text-primary': tag === activeNav,
            'text-muted': tag !== activeNav,
        });

    const navigateToProFormaList = () => {
        const path = generatePath(URLS.PRO_FORMA.HOME, { dealId: '111' });
        navigate(path);
    };

    return (
        <NSCard className="NSCard--level-2 ProFormaSideNav__nav-bar">
            <div className="mx-3 mt-3">
                <NSButton callback={navigateToProFormaList} color="primary" className="font-13 px-2" size="sm" outline>
                    <FontAwesomeIcon icon={faChevronLeft} className="mr-1" size="xs" />
                    Exit
                </NSButton>
                <h4 className="text-dark mb-0">Mock pro forma name</h4>
                <div className="h5 text-muted font-16 text-wrap">Mock pro forma description with some more writing to make it wrap</div>
                <div role="presentation" onClick={toggleEditProFormaModal} className="mt-1 mb-0 font-13 text-primary cursor--pointer">
                    Edit name/description
                </div>
            </div>
            <hr className="mb-0" />

            <div className="pb-1">
                <Nav tabs className="flex-column w-100 border-0">
                    {Object.entries(PRO_FORMA_TABLE_NAV).map(([key, nav]) => (
                        <NavItem key={key} className={navItemClass(nav.name)}>
                            <FontAwesomeIcon icon={nav.icon} />
                            <div
                                className="py-2 pr-2 pl-1 cursor--pointer"
                                data-toggle="tab"
                                onClick={() => navigateToSection(nav.name)}
                                aria-hidden="true"
                            >
                                <span>{nav.name}</span>
                            </div>
                        </NavItem>
                    ))}
                </Nav>

                <ProFormaSideNavFooter />
            </div>

            <EditProFormaModal showEditProFormaModal={showEditProFormaModal} toggleEditProFormaModal={toggleEditProFormaModal} />
        </NSCard>
    );
};

export default ProFormaSideNav;
