import React from 'react';
import { ComparisonTable } from './components';
import { IGridConfig } from './types';
import './ComparisonGrid.scss';

export interface IProps {
    gridConfig: IGridConfig;
    renderAddToComparison: () => React.ReactNode;
}

export const ComparisonGrid = ({ gridConfig, renderAddToComparison }: IProps) => {
    return (
        <div className="ComparisonGrid d-flex p-1">
            <ComparisonTable gridConfig={gridConfig} />
            <div className="ComparisonGrid__add-to-comparison-section ml-3 p-3">{renderAddToComparison()}</div>
        </div>
    );
};

export default ComparisonGrid;
