import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSCard } from 'bricks';
import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface IReportTypeProps {
    typeName: string;
    icon: IconProp;
    subtitle?: string;
    isSelected: boolean;
    isDisabled?: boolean;
    disabledTooltip?: ReactNode;
    onClick: () => void;
}

const ReportType = ({ typeName, icon, subtitle, isSelected = false, isDisabled = false, disabledTooltip, onClick }: IReportTypeProps) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const toggleTooltip = () => setShowTooltip(!showTooltip);

    const cardId = `report-type-${typeName.toLowerCase().split(' ').join('-')}`;

    return (
        <NSCard
            id={cardId}
            className={classNames([
                'Report-type m-1 text-center flex-grow-1',
                {
                    'Report-type--selected': isSelected,
                    'Report-type--disabled': isDisabled,
                },
            ])}
            onClick={isDisabled ? () => {} : onClick}
        >
            <div id={`${cardId}-info`}>
                <FontAwesomeIcon id={`${cardId}-icon`} icon={icon} size="3x" className="text-primary" />
                <h4 className="text-white font-weight-bold mb-0">{typeName}</h4>
                {subtitle && <span>{subtitle}</span>}
            </div>

            {disabledTooltip && isDisabled && (
                <Tooltip target={`${cardId}-info`} isOpen={showTooltip} toggle={toggleTooltip} innerClassName="Report-type__tooltip">
                    {disabledTooltip}
                </Tooltip>
            )}
        </NSCard>
    );
};

export default ReportType;
