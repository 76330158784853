import { NSCard } from 'bricks';
import React from 'react';
import { Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import NSDropdown from 'bricks/NSDropdown/NSDropdown';
import classNames from 'classnames';
import { IOptionAnyValue } from 'bricks/types';
import { IDeal } from 'views/Reports/types';

export interface IDealSelectorProps {
    selectedDeal?: IDeal;
    dealOptions: IOptionAnyValue[];
    onChangeDealSelection: (newSelection?: IOptionAnyValue) => void;
}

const DealSelector = ({ selectedDeal, dealOptions, onChangeDealSelection }: IDealSelectorProps) => {
    const currentSelection = selectedDeal ? { label: selectedDeal.dealName, value: selectedDeal.id } : undefined;

    return (
        <NSCard
            className={classNames([
                'NSCard--level-4 p-2 px-3 m-0',
                {
                    'Deal-selector--unselected bg-transparent': !selectedDeal,
                },
            ])}
        >
            <Row className="d-flex m-0 justify-content-between align-items-center">
                <div className="m-0">
                    {selectedDeal ? (
                        <>
                            <h4 className="text-white mb-0">{selectedDeal.project}</h4>
                            <div>
                                <span>{`${selectedDeal.projectType} • ${selectedDeal.dealType}`}</span>
                            </div>
                        </>
                    ) : (
                        <span>Select deal to add:</span>
                    )}
                </div>
                <div className={classNames(['d-flex m-0 align-items-center py-1 pl-3'], { 'flex-fill': !selectedDeal })}>
                    <NSDropdown
                        options={dealOptions}
                        handleSelectOption={option => onChangeDealSelection(option as IOptionAnyValue)}
                        selectedOption={currentSelection}
                        placeholder="Select"
                        containerClassName="w-100"
                        dropdownClassName="Deal-selector__dropdown w-100"
                    />
                    {selectedDeal && (
                        <FontAwesomeIcon
                            icon={faTrash}
                            data-testid="trash-icon"
                            size="lg"
                            className="pl-2 cursor--pointer"
                            onClick={() => onChangeDealSelection()}
                        />
                    )}
                </div>
            </Row>
        </NSCard>
    );
};

export default DealSelector;
