import React, { useCallback } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { NSButton } from 'bricks';
import { useNavigate } from 'react-router-dom';
import { Deal } from '../../types';

interface IDataPanelProps {
    detail: Deal;
}
const DataPanel = ({ detail }: IDataPanelProps) => {
    const navigate = useNavigate();
    const handleViewDeal = useCallback(
        (deal: Deal) => () => {
            navigate(`${deal.id}`, { state: { row: deal } });
        },
        [navigate],
    );

    return (
        <Card className="h-100 mb-0 border-0 shadow-none">
            <CardBody>
                <CardTitle className="border-bottom pb-2 mb-3">
                    <Row>
                        <Col xs="9">
                            <h3 className="mb-1 text-dark">{detail.dealName}</h3>
                            <p className="text-muted small mb-0">{detail.address}</p>
                        </Col>
                        <Col xs="3" className="d-flex justify-content-end align-items-center">
                            <NSButton callback={handleViewDeal(detail)} className="btn-sm" color="secondary">
                                View Deal
                            </NSButton>
                        </Col>
                    </Row>
                </CardTitle>
                <Row>
                    <Col xs="6">
                        <p className="text-uppercase font-weight-bold text-primary small mb-2">Details</p>
                        <p>
                            <span className="text-muted">Market:</span>
                            <br />
                            <span className="text-dark">{detail.market}</span>
                        </p>
                        <p>
                            <span className="text-muted">Submarket:</span>
                            <br />
                            <span className="text-dark">{detail.subMarket}</span>
                        </p>
                        <p>
                            <span className="text-muted">Lead Source:</span>
                            <br />
                            <span className="text-dark">{detail?.leadSource?.name}</span>
                        </p>
                        <p>
                            <span className="text-muted">Deal Creator:</span>
                            <br />
                            <span className="text-dark">{detail.dealCreator}</span>
                        </p>
                        <p>
                            <span className="text-muted">Construction Type:</span>
                            <br />
                            <span className="text-dark">{detail.constructionType}</span>
                        </p>
                    </Col>
                    <Col xs="6">
                        <p className="text-uppercase font-weight-bold text-primary small mb-2">Additional Details</p>
                        <p>
                            <span className="text-muted">Deal Type:</span>
                            <br />
                            <span className="text-dark">{detail?.dealType?.name}</span>
                        </p>
                        <p>
                            <span className="text-muted">Asset Type:</span>
                            <br />
                            <span className="text-dark">{detail.assetClass}</span>
                        </p>
                        <p>
                            <span className="text-muted">Deal Owner:</span>
                            <br />
                            <span className="text-dark">{detail?.dealOwner?.name}</span>
                        </p>
                        <p>
                            <span className="text-muted">Adquisition price:</span>
                            <br />
                            <span className="text-dark">{detail.acquisitionPrice}</span>
                        </p>
                        <p>
                            <span className="text-muted">County:</span>
                            <br />
                            <span className="text-dark">{detail.county}</span>
                        </p>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

DataPanel.propTypes = {};

export default DataPanel;
