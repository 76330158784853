import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import NSTable from '../../../../bricks/NSTable/NSTable';
import { headerCellClassName } from '../../constants';

const UploadedProFormasTable = () => {
    return (
        <NSTable>
            <thead className="NSTable__thead">
                <tr className="NSTable__thead__tr">
                    <th className="NSTable__thead__tr__th text-dark">Name</th>
                    <th className="NSTable__thead__tr__th">
                        <div className={headerCellClassName}>
                            <span>Uploaded</span>
                            <FontAwesomeIcon icon="sort" />
                        </div>
                    </th>
                    <th className="NSTable__thead__tr__th">
                        <div className={headerCellClassName}>
                            <span>Uploaded by</span>
                            <FontAwesomeIcon icon="sort" />
                        </div>
                    </th>
                    <th className="NSTable__thead__tr__th">
                        <div className={headerCellClassName}>
                            <span>Size</span>
                            <FontAwesomeIcon icon="sort" />
                        </div>
                    </th>
                </tr>
            </thead>
        </NSTable>
    );
};

export default UploadedProFormasTable;
