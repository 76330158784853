import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { IReportsQueryParams, IReportsResponse } from '../types';

const getReports = async ({ pageSize, currentPage, orgId }: IReportsQueryParams): Promise<IReportsResponse> => {
    const offset = (currentPage - 1) * pageSize;
    const response = await axiosInstance.get(`${API_ENDPOINTS.REPORTS(orgId)}?offset=${offset}&page_size=${pageSize}`);
    return response.data;
};

export const useGetReports = ({ pageSize, currentPage, orgId }: IReportsQueryParams) =>
    useQuery({
        queryKey: ['getReports', orgId, currentPage, pageSize],
        queryFn: () => getReports({ pageSize, currentPage, orgId }),
        staleTime: 5000,
        refetchOnMount: true,
    });
