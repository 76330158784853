import React from 'react';
import classNames from 'classnames';
import { faList, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './NavMenu.scss';

export const DEALS_SECTIONS = {
    MAP: 'map',
    LIST: 'list',
};

const NavMenu = ({ activeSection, setActiveSection }: any) => {
    const handleClickSectionTab = (section: string) => {
        setActiveSection(section);
        window.localStorage.setItem('dealsActiveSection', section);
    };

    return (
        <Nav tabs className="border-0">
            <NavItem
                data-testid="nav-tab-map"
                className={classNames(
                    'mr-2',
                    { NavMenu__item: activeSection === DEALS_SECTIONS.MAP },
                    { 'NavMenu__item--inactive': activeSection !== DEALS_SECTIONS.MAP },
                )}
            >
                <NavLink
                    href="#"
                    data-toggle="tab"
                    onClick={() => handleClickSectionTab(DEALS_SECTIONS.MAP)}
                    active={activeSection === DEALS_SECTIONS.MAP}
                    className="border-0 rounded"
                >
                    <FontAwesomeIcon className="mr-2" size="lg" icon={faMapLocationDot} />
                    Map
                </NavLink>
            </NavItem>
            <NavItem
                data-testid="nav-tab-list"
                className={classNames(
                    { NavMenu__item: activeSection === DEALS_SECTIONS.LIST },
                    { 'NavMenu__item--inactive': activeSection !== DEALS_SECTIONS.LIST },
                )}
            >
                <NavLink
                    href="#"
                    data-toggle="tab"
                    onClick={() => handleClickSectionTab(DEALS_SECTIONS.LIST)}
                    active={activeSection === DEALS_SECTIONS.LIST}
                    className="border-0 rounded"
                >
                    <FontAwesomeIcon className="mr-2" size="lg" icon={faList} />
                    List
                </NavLink>
            </NavItem>
        </Nav>
    );
};
export default NavMenu;
