/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftToLine, faListTimeline } from '@fortawesome/pro-solid-svg-icons';
import ProjectStages from './ProjectStages/ProjectStages';
import './PipelineSidebar.scss';

export interface IProps {
    isExpandedPipeline: boolean;
    setIsExpandedPipeline: (isExpanded: boolean) => void;
}

const PipelineSidebar = ({ isExpandedPipeline, setIsExpandedPipeline }: IProps) => {
    const toggleExpandedPipeline = (isIconTogglerClicked?: boolean) => {
        if (!isIconTogglerClicked || !isExpandedPipeline) {
            window.localStorage.setItem('isExpandedProjectPipeline', JSON.stringify(!isExpandedPipeline));
            setIsExpandedPipeline(!isExpandedPipeline);
        }
    };

    return (
        <div
            className={`PipelineSidebar ${isExpandedPipeline ? 'PipelineSidebar--expanded' : 'PipelineSidebar--collapsed'}`}
            data-testid="PipelineSidebar"
        >
            <div
                className={`PipelineSidebar__toggle cursor--pointer ${isExpandedPipeline ? 'hidden' : ''}`}
                onClick={() => toggleExpandedPipeline(true)}
                role="button"
            >
                <FontAwesomeIcon icon={faListTimeline} className="mt-2 py-2 px-2 bg-secondary rounded-right" size="lg" />
            </div>

            <div className="PipelineSidebar__content">
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="PipelineSidebar__content__title">Deal Pipeline</h4>
                    <div onClick={() => toggleExpandedPipeline(false)} className="PipelineSidebar__content__hide-button" data-testid="hideIcon">
                        <FontAwesomeIcon icon={faArrowLeftToLine} className="cursor--pointer" />
                    </div>
                </div>
                <ProjectStages />
            </div>
        </div>
    );
};

export default PipelineSidebar;
