import React, { useState } from 'react';
import NSLabel from 'bricks/NSLabel/NSLabel';
import { Input, InputGroup, InputGroupAddon, InputGroupText, UncontrolledTooltip } from 'reactstrap';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RenderIf from 'components/RenderIf/RenderIf';
import 'css/partials/_bricks.NSInputNumberStepper.scss';

export interface INSInputNumberStepperProps {
    name: string;
    id?: string;
    labelClassName?: string;
    labelText?: string;
    value: number;
    inputGroupClassName?: string;
    min?: number;
    max?: number;
    onChange?: any;
    icon?: any;
    iconTooltipText?: string;
    required?: boolean;
    step: number;
}

const NSInputNumberStepper = ({
    name,
    id,
    labelClassName = '',
    labelText = '',
    value,
    inputGroupClassName,
    min,
    max,
    onChange,
    step,
    icon,
    iconTooltipText,
    required = false,
}: INSInputNumberStepperProps) => {
    const [count, setCount] = useState<number>(value);

    const updateCount = (newValue: number) => {
        if ((min !== undefined && newValue < min) || (max !== undefined && newValue > max)) {
            return;
        }
        const formattedValue = Number.isInteger(step) ? Math.round(newValue) : parseFloat(newValue.toFixed(1));
        setCount(formattedValue);
        onChange(formattedValue);
    };

    const countDown = () => {
        const newValue = (count || 0) - step;
        updateCount(newValue);
    };

    const countUp = () => {
        const newValue = (count || 0) + step;
        updateCount(newValue);
    };

    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = parseFloat(event.target.value);
        updateCount(inputValue);
    };

    return (
        <>
            <NSLabel for={name} className={labelClassName}>
                {labelText}
                <RenderIf isTrue={required}>
                    <span className="text-danger ml-1">*</span>
                </RenderIf>
                <RenderIf isTrue={icon}>
                    <FontAwesomeIcon icon={icon} className="pl-1 cursor-pointer" id="numberStepperIcon" />
                    <RenderIf isTrue={iconTooltipText}>
                        <UncontrolledTooltip target="numberStepperIcon">{iconTooltipText}</UncontrolledTooltip>
                    </RenderIf>
                </RenderIf>
            </NSLabel>
            <InputGroup className={`d-flex NSInputNumberStepper ${inputGroupClassName}`}>
                <InputGroupAddon addonType="prepend" className="NSInputNumberStepper__addon-prepend">
                    <InputGroupText className="py-0 NSInputNumberStepper__addon-prepend__text" onClick={countDown}>
                        <FontAwesomeIcon icon={faMinus} data-testid="prependButton" />
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    type="number"
                    id={id}
                    name={name}
                    value={count}
                    className="NSInputNumberStepper__input border-right-0 border-left-0 text-center"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNumberChange(event)}
                    min={min}
                    max={max}
                />
                <InputGroupAddon addonType="append" className="NSInputNumberStepper__addon-append">
                    <InputGroupText className="py-0 NSInputNumberStepper__addon-append__text" onClick={countUp}>
                        <FontAwesomeIcon icon={faPlus} data-testid="appendButton" />
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </>
    );
};

export default NSInputNumberStepper;
