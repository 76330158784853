import React from 'react';
// import './ReportsControl.scss';
import { NSButton } from 'bricks';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';

const ReportsControl = () => {
    return (
        <div className="Reports-control d-flex justify-content-between my-3">
            <NSButton className="NSButton" icon={faFilter} text="Filters" color="secondary" outline={false} />
            <input type="text" placeholder="Search" className="Reports-control__search-input bg-secondary text-white px-2" />
        </div>
    );
};

export default ReportsControl;
