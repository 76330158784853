import React, { useState } from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'reactstrap';

import { POPULATE_USES_METHOD } from 'views/ProFormaTable/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';
import { NSCard } from 'bricks';
import NSInfoBanner from 'components/NSInfoBanner/NSInfoBanner';
import UsesVsSourcesCard from './UsesVsSourcesCard/UsesVsSourcesCard';
import DevelopmentUsesTable from './DevelopmentUsesTable/DevelopmentUsesTable';
import DevelopmentSourcesTable from './DevelopmentSourcesTable/DevelopmentSourcesTable';
import ImportUsesModal from './ImportUsesModal/ImportUsesModal';

const ProFormaDevelopment: React.FC = () => {
    const [usesMethod, setUsesMethod] = useState<string | null>(null);
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);

    const toggleImportModal = () => setIsImportModalOpen(!isImportModalOpen);

    const handleDeleteBudgetCategories = () => {
        // TODO: add react query to delete budget cats
        setUsesMethod(null);
        toggleImportModal();
    };

    return (
        <>
            <ExpandableSection
                number={1}
                title="Development Timeline"
                subtitle="Determine development timeline for this investment"
                className="NSCard--level-2"
                isExpanded
                animate
            >
                <div className="d-flex m-3">
                    <NSCard className="NSCard--level-4 w-100 py-2 px-3 mr-3">
                        <div>
                            <h5 className="my-0 text-muted font-14">Development start</h5>
                            <small className="text-dark font-14">June 2024</small>
                        </div>
                    </NSCard>
                    <NSCard className="NSCard--level-4 w-100 py-2 px-3">
                        <div>
                            <h5 className="my-0 text-muted font-14">Development end</h5>
                            <small className="text-dark font-14">In progress</small>
                        </div>
                    </NSCard>
                </div>
            </ExpandableSection>

            <NSInfoBanner
                title="Warning: Uses exceed sources"
                subtitle="Ensure funding is sufficient to cover uses."
                className="mt-3 NSCard--level-4 NSCard__left-border--warning"
                warning
                customInfo={<UsesVsSourcesCard />}
            />

            <ExpandableSection
                number={2}
                title="Uses"
                subtitle={`Information about the investment's allocation of funds`}
                className="mt-3 NSCard--level-2"
                displayButton={Boolean(usesMethod)}
                displayButtonText="Import Uses via different method"
                displayButtonCallback={toggleImportModal}
                isExpanded
                animate
            >
                <RenderIf isTrue={!usesMethod}>
                    <Row className="m-3">
                        <Col m={12} xl={4} className="px-0">
                            <div className="h3 mt-0 text-dark mb-3">
                                Select a method to populate <b>Uses</b>:
                            </div>
                            <NSCard
                                className="NSCard--level-4 mb-2 p-2 cursor--pointer"
                                onClick={() => setUsesMethod(POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.title)}
                            >
                                <div className="text-dark h5 mt-1 mb-0">{POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.title}</div>
                                <div className="mb-0">{POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.subtitle}</div>
                            </NSCard>

                            <NSCard
                                className="NSCard--level-4 mb-2 p-2 cursor--pointer"
                                onClick={() => setUsesMethod(POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.title)}
                            >
                                <div className="text-dark h5 mt-1 mb-0">{POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.title}</div>
                                <div className="mb-0">{POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.subtitle}</div>
                            </NSCard>

                            <NSCard
                                className="NSCard--level-4 mb-2 p-2 cursor--pointer"
                                onClick={() => setUsesMethod(POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.title)}
                            >
                                <div className="text-dark h5 mt-1 mb-0">{POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.title}</div>
                                <div className="mb-0">{POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.subtitle}</div>
                            </NSCard>

                            <div
                                role="presentation"
                                onClick={() => setUsesMethod(POPULATE_USES_METHOD.START_FROM_SCRATCH.title)}
                                className="text-primary d-flex mt-3 cursor--pointer"
                            >
                                <div>{POPULATE_USES_METHOD.START_FROM_SCRATCH.title}</div>
                                <FontAwesomeIcon icon={faArrowRight} className="ml-1 my-auto" />
                            </div>
                        </Col>
                    </Row>
                </RenderIf>

                <RenderIf isTrue={usesMethod}>
                    <div className="d-flex m-3">
                        <RenderIf isTrue={usesMethod === POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.title}>
                            {/** IMPORT FORM PREV COMPONENT */}
                        </RenderIf>

                        <RenderIf isTrue={usesMethod === POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.title}>
                            {/** START WITH BOE COMPONENT */}
                        </RenderIf>
                        <RenderIf isTrue={usesMethod === POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.title}>
                            {/** USE RECOMMENDED COMPONENT */}
                        </RenderIf>
                        <RenderIf isTrue={usesMethod === POPULATE_USES_METHOD.START_FROM_SCRATCH.title}>
                            <DevelopmentUsesTable />
                        </RenderIf>
                    </div>
                </RenderIf>
            </ExpandableSection>
            <ExpandableSection
                number={3}
                title="Sources"
                subtitle={`Information about the investment's sources of funds`}
                className="mt-3 NSCard--level-2"
                isExpanded
                animate
            >
                <div className="d-flex m-3">
                    <DevelopmentSourcesTable />
                </div>
            </ExpandableSection>

            <RenderIf isTrue={isImportModalOpen}>
                <ImportUsesModal isOpen={isImportModalOpen} toggle={toggleImportModal} handleDeleteBudgetCategories={handleDeleteBudgetCategories} />
            </RenderIf>
        </>
    );
};

export default ProFormaDevelopment;
