/* eslint-disable react/no-array-index-key */
import React from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { NSBadge, NSLabel, NSSelectWithCheckboxes } from '..';
import RenderIf from '../../components/RenderIf/RenderIf';
import { NSSelectWithCheckboxesProps, OptionType } from '../NSSelectWithCheckboxes/NSSelectWithCheckboxes';

export interface INSMultiSelectProps extends Omit<NSSelectWithCheckboxesProps, 'name'> {
    selectName: string;
    onChange: (options: OptionType[], action?: { action: string }) => void;
    value: OptionType[];
    options: OptionType[];
    label?: string;
    labelClassName?: string;
    badgeColor?: string;
    componentClassName?: string;
    selectedOptionsMaxHeight?: number;
}

const NSMultiSelect = ({
    onChange,
    value,
    options,
    selectName,
    label,
    labelClassName,
    badgeColor = 'primary-lighten',
    componentClassName = 'w-100 mt-2',
    selectedOptionsMaxHeight,
    ...rest
}: INSMultiSelectProps) => {
    const handleChange = (options: OptionType[]) => {
        const currentOptions = options || [];
        onChange(currentOptions);
    };

    return (
        <div className={componentClassName}>
            <RenderIf isTrue={label}>
                <NSLabel for={selectName} className={labelClassName}>
                    {label}
                </NSLabel>
            </RenderIf>
            <NSSelectWithCheckboxes
                isClearable={false}
                options={options}
                value={value}
                name={selectName}
                formClassName="w-100"
                onChange={handleChange}
                controlShouldRenderValue={false}
                isNSMulti
                {...rest}
            />
            <OverlayScrollbarsComponent
                defer
                options={{
                    scrollbars: { autoHide: 'leave' },
                    overflow: { y: 'scroll', x: 'hidden' },
                }}
                className="pr-2"
                style={{
                    maxHeight: selectedOptionsMaxHeight ? `${selectedOptionsMaxHeight}px` : undefined,
                }}
            >
                <div className="d-flex flex-wrap">
                    {value?.map((option, index) => (
                        <NSBadge key={`${option.value}-${index}`} color={badgeColor} className="mt-1 p-1 mr-1 text-truncate">
                            <span className="text-truncate">{option.label}</span>
                            <FontAwesomeIcon
                                role="button"
                                icon={faXmark}
                                className="pl-1 cursor--pointer"
                                onClick={() => onChange(value.filter(o => o.value !== option.value))}
                            />
                        </NSBadge>
                    ))}
                </div>
            </OverlayScrollbarsComponent>
        </div>
    );
};

export default NSMultiSelect;
