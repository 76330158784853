import { formatPercentage, formatUSDCurrency, formatUSDCurrencyTwoDigits, numeralFormatter } from 'ns_libs/formatter';
import { Deal } from 'views/Deals/types';
import { BOEScenario } from './types';

type FormatterType = 'formatPercentage' | 'formatUSDCurrency' | 'numeralFormatter';

interface ScenarioRow {
    label: string;
    value: number | string | null;
    formatter?: FormatterType;
    placeholder?: string;
}

interface ScenarioSection {
    title: string[];
    value: (number | string)[];
    formatter: FormatterType[];
    rows: ScenarioRow[][];
    placeholder?: string;
}

interface ScenarioReturnsSection {
    title: string[];
    value: (number | string)[];
    target?: number;
    formatter: FormatterType[];
    displayMode: 'unstyled' | 'danger' | 'success';
}

export const formatBOEValues = (value: number, formatter?: string) => {
    switch (formatter) {
        case 'formatUSDCurrency':
            return formatUSDCurrency(value);
        case 'formatUSDCurrencyTwoDigits':
            return formatUSDCurrencyTwoDigits(value);
        case 'numeralFormatter':
            return numeralFormatter(value);
        case 'formatPercentage':
            return formatPercentage(value * 100, 100, 2);
        default:
            return value;
    }
};
const placeholders = {
    propertySize: {
        title: 'PROPERTY SIZE',
        placeholder: 'Built GSF',
        rows: {
            efficiencyRatio: 'Efficiency Ratio',
            netRental: 'Net Rental SF',
        },
    },
    grossPotentialRent: {
        title: 'GROSS POTENTIAL RENT',
        rows: {
            monthlyRentPricePerUnit: { title: 'Monthly rent price (per unit)', placeholder: '/ unit' },
            monthlyRentPricePerSf: { title: 'Monthly rent price (per SF)', placeholder: '/ SF' },
            numberOfUnits: 'Number of units',
            sfPerUnit: 'SF per unit',
        },
    },
    netOperatingIncome: {
        title: 'NET OPERATING INCOME',
        rows: {
            grossPotentialRent: 'Gross potent. rent',
            monthlyExpenseCostPerUnit: 'Monthly expense / unit',
            monthlyExpenseCostPerSf: 'Monthly expense / SF',
            expensesTotal: 'Total annual expenses',
        },
    },
    totalDevelopmentCosts: {
        title: 'TOTAL DEVELOPMENT COSTS',
        rows: {
            acquisitionCosts: 'Acquisition costs',
            hardCostsPerUnit: 'Hard costs (per unit)',
            hardCostsPerSf: 'Hard costs (per SF)',
            softCostsPerUnit: 'Soft costs (per unit)',
            softCostsPerSf: 'Soft costs (per SF)',
            financingCosts: 'Financing costs',
        },
    },
    loanAndEquity: {
        title: ['LOAN AMOUNT', 'REQUIRED EQUITY'],
        rows: {
            constructionFinancing: 'Construction Financing (LTC)',
        },
    },
    returns: {
        returnOnCost: 'RETURN ON COST',
        returnOnEquity: 'RETURN ON EQUITY',
        profitReturn: 'PROFIT RETURN',
    },
};

export const generateScenarioInformation = (BOEScenario: BOEScenario): ScenarioSection[][] => {
    return [
        [
            {
                title: [placeholders.propertySize.title],
                value: [Number(BOEScenario.builtSf || 0)],
                formatter: ['numeralFormatter'],
                placeholder: placeholders.propertySize.placeholder,
                rows: [
                    [
                        {
                            label: placeholders.propertySize.rows.efficiencyRatio,
                            value: BOEScenario.buildingEfficiency ? Number(BOEScenario.buildingEfficiency) : null,
                            formatter: 'formatPercentage',
                        },
                        {
                            label: placeholders.propertySize.rows.netRental,
                            value: BOEScenario.netRentableSf ? Number(BOEScenario.netRentableSf) : null,
                            formatter: 'numeralFormatter',
                        },
                    ],
                ],
            },
            {
                title: [placeholders.grossPotentialRent.title],
                value: [BOEScenario.grossPotentialRent ? Number(BOEScenario.grossPotentialRent) : 0],
                formatter: ['formatUSDCurrency'],
                rows: [
                    [
                        {
                            label: placeholders.grossPotentialRent.rows.monthlyRentPricePerUnit.title,
                            value: BOEScenario.monthlyRentPricePerUnit ? Number(BOEScenario.monthlyRentPricePerUnit) : null,
                            formatter: 'formatUSDCurrency',
                            placeholder: placeholders.grossPotentialRent.rows.monthlyRentPricePerUnit.placeholder,
                        },
                        {
                            label: placeholders.grossPotentialRent.rows.monthlyRentPricePerSf.title,
                            value: BOEScenario.monthlyRentPricePerSf ? Number(BOEScenario.monthlyRentPricePerSf) : null,
                            formatter: 'formatUSDCurrency',
                            placeholder: placeholders.grossPotentialRent.rows.monthlyRentPricePerSf.placeholder,
                        },
                        {
                            label: placeholders.grossPotentialRent.rows.numberOfUnits,
                            value: BOEScenario.numberOfUnits ? Number(BOEScenario.numberOfUnits) : null,
                        },
                        {
                            label: placeholders.grossPotentialRent.rows.sfPerUnit,
                            value: BOEScenario.sfPerUnit ? Number(BOEScenario.sfPerUnit) : null,
                        },
                    ],
                ],
            },
            {
                title: [placeholders.netOperatingIncome.title],
                value: [BOEScenario.netOperatingIncome ? Number(BOEScenario.netOperatingIncome) : 0],
                formatter: ['formatUSDCurrency'],
                rows: [
                    [
                        {
                            label: placeholders.netOperatingIncome.rows.grossPotentialRent,
                            value: BOEScenario.grossPotentialRent ? Number(BOEScenario.grossPotentialRent) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.netOperatingIncome.rows.monthlyExpenseCostPerUnit,
                            value: BOEScenario.monthlyExpenseCostPerUnit ? Number(BOEScenario.monthlyExpenseCostPerUnit) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.netOperatingIncome.rows.monthlyExpenseCostPerSf,
                            value: BOEScenario.monthlyExpenseCostPerSf ? Number(BOEScenario.monthlyExpenseCostPerSf) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.netOperatingIncome.rows.expensesTotal,
                            value: BOEScenario.expensesTotal ? Number(BOEScenario.expensesTotal) : null,
                            formatter: 'formatUSDCurrency',
                        },
                    ],
                ],
            },
        ],
        [
            {
                title: [placeholders.totalDevelopmentCosts.title],
                value: [BOEScenario.totalDevelopmentCosts ? Number(BOEScenario.totalDevelopmentCosts) : 0],
                formatter: ['formatUSDCurrency'],
                rows: [
                    [
                        {
                            label: placeholders.totalDevelopmentCosts.rows.acquisitionCosts,
                            value: BOEScenario.acquisition ? Number(BOEScenario.acquisition) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.totalDevelopmentCosts.rows.hardCostsPerUnit,
                            value: BOEScenario.hardCostsPerUnit ? Number(BOEScenario.hardCostsPerUnit) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.totalDevelopmentCosts.rows.hardCostsPerSf,
                            value: BOEScenario.hardCostsPerSf ? Number(BOEScenario.hardCostsPerSf) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.totalDevelopmentCosts.rows.softCostsPerUnit,
                            value: BOEScenario.softCostsPerUnit ? Number(BOEScenario.softCostsPerUnit) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.totalDevelopmentCosts.rows.softCostsPerSf,
                            value: BOEScenario.softCostsPerSf ? Number(BOEScenario.softCostsPerSf) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.totalDevelopmentCosts.rows.financingCosts,
                            value: BOEScenario.loanToCost ? Number(BOEScenario.loanToCost) : null,
                            formatter: 'formatUSDCurrency',
                        },
                    ],
                ],
            },
            {
                title: placeholders.loanAndEquity.title,
                value: [BOEScenario.loanAmount ? Number(BOEScenario.loanAmount) : 0, BOEScenario.requiredEquity ? Number(BOEScenario.requiredEquity) : 0],
                formatter: ['formatUSDCurrency', 'formatUSDCurrency'],
                rows: [
                    [
                        {
                            label: placeholders.loanAndEquity.rows.constructionFinancing,
                            value: BOEScenario.averageLoanBalance ? Number(BOEScenario.averageLoanBalance) : null,
                            formatter: 'formatPercentage',
                        },
                    ],
                ],
            },
        ],
    ];
};

export const generateScenarioReturns = (BOEScenario: BOEScenario, deal?: Deal): ScenarioReturnsSection[] => {
    const getDisplayMode = (value: number | null, target: number | null): 'success' | 'danger' | 'unstyled' => {
        if (target !== null) {
            return value !== null && value > target ? 'success' : 'danger';
        }
        return 'unstyled';
    };
    return [
        {
            title: [placeholders.returns.returnOnCost],
            value: [BOEScenario.returnOnCost ? Number(BOEScenario.returnOnCost) : 0],
            target: deal?.targetReturnOnCost ? Number(deal.targetReturnOnCost) : 0,
            formatter: ['formatPercentage'],
            displayMode: getDisplayMode(Number(BOEScenario.returnOnCost), deal?.targetReturnOnCost ?? null),
        },
        {
            title: [placeholders.returns.returnOnEquity],
            value: [BOEScenario.returnOnEquity ? Number(BOEScenario.returnOnEquity) : 0],
            target: deal?.targetReturnOnEquity ? Number(deal.targetReturnOnEquity) : 0,
            formatter: ['formatPercentage'],
            displayMode: getDisplayMode(Number(BOEScenario.returnOnEquity), deal?.targetReturnOnEquity ?? null),
        },
        {
            title: [placeholders.returns.profitReturn],
            value: [BOEScenario.profitReturn ? Number(BOEScenario.profitReturn) : 0],
            target: deal?.targetProfitReturn ? Number(deal.targetProfitReturn) : 0,
            formatter: ['formatUSDCurrency'],
            displayMode: getDisplayMode(Number(BOEScenario.profitReturn), deal?.targetProfitReturn ?? null),
        },
    ];
};
