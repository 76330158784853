/* eslint-disable operator-linebreak */
import { useMemo } from 'react';
import { BOESlideoutFormValues, CostBasisStates, UnitOfTimeStates } from './types';
import { calculateBOEValues, calculateIterativeValues } from './helpers';

export const useBOECalculations = (values: BOESlideoutFormValues, unitOfTimeStates: UnitOfTimeStates, costBasisStates: CostBasisStates) => {
    const calculations = useMemo(() => {
        const {
            netRentable,
            sfPerUnit,
            grossPotentialRent,
            expenseTotal,
            netOperatingIncome,
            exitValuation,
            calculateLoanAmount,
            hardCostsTotal,
            softCostsTotal,
        } = calculateBOEValues(values, unitOfTimeStates, costBasisStates);

        const { developerFeeTotal, interestReserveTotal, loanAmount, equityCost } = calculateIterativeValues(
            values,
            unitOfTimeStates,
            hardCostsTotal,
            softCostsTotal,
            calculateLoanAmount,
        );
        return {
            netRentable,
            sfPerUnit,
            grossPotentialRent,
            expenseTotal,
            netOperatingIncome,
            exitValuation,
            calculateLoanAmount,
            hardCostsTotal,
            softCostsTotal,
            developerFeeTotal,
            interestReserveTotal,
            loanAmount,
            equityCost,
        };
    }, [values, unitOfTimeStates, costBasisStates]);

    return calculations;
};
