import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { NSButton, NSInput } from 'bricks';
import { faCaretDown, faCaretUp, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RenderIf from 'components/RenderIf/RenderIf';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { URLS } from 'services/urls';
import DealsTable from './DealsTable/DealsTable';
import NavMenu, { DEALS_SECTIONS } from './NavMenu/NavMenu';
import VisibleColumnsDropdown from './VisibleColumnsDropdown/VisibleColumnsDropdown';
import './Deals.scss';
import { COLUMN_OPTIONS, getInitialColumnVisibility } from './constants';
import { Deal, IVisibleColumns } from './types';
import { DealsOverviewProvider } from './context/DealsOverviewProvider';
import PipelineSidebar from './PipelineSidebar/PipelineSidebar';
import MapView from './MapView/MapView';
import { useFetchDeals } from './hooks/useFetchDeals';
import useDebounce from './hooks/useDebounce';

const mockedDropdownOptions = [
    { id: 1, name: 'Option 1' },
    { id: 2, name: 'Option 2' },
    { id: 3, name: 'Option 3' },
];

const Deals = () => {
    const locallySavedActiveSection = window.localStorage.getItem('dealsActiveSection');
    const [activeSection, setActiveSection] = useState<string | null>(locallySavedActiveSection || DEALS_SECTIONS.MAP);
    const [searchValue, setSearchValue] = useState<string>('');
    const [visibleColumns, setVisibleColumns] = useState<IVisibleColumns>(getInitialColumnVisibility());
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isExpandedPipeline, setIsExpandedPipeline] = useState<boolean>(() => {
        const storedValue = window.localStorage.getItem('isExpandedProjectPipeline');
        return storedValue ? JSON.parse(storedValue) : false;
    });
    const [selectedStages, setSelectedStages] = useState<any | null>(null);
    const [selectedDeal, setSelectedDeal] = useState<Deal>({} as Deal);

    const debouncedValue = useDebounce(searchValue, 500);

    const handleDealSelection = useCallback((deal: Deal) => {
        setSelectedDeal(deal);
    }, []);

    // Pagination state
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const isOptionDisplayed = (key: string) => {
        return true; // Adjust logic
    };

    useEffect(() => {
        const locallySavedSelectedStages = window.localStorage.getItem('dealPipelineSelectedStages');
        if (locallySavedSelectedStages) {
            setSelectedStages(JSON.parse(locallySavedSelectedStages));
        }
    }, []);

    // Fetch deals using the custom hook
    // Remove the hardcoded orgId when the API is ready.
    const orgId = '123e4567-e89b-12d3-a456-426614174000';
    const { data } = useFetchDeals({ pageSize, currentPage, orgId, searchValue: debouncedValue });

    const isEmpty = data?.items.length === 0;

    const updateVisibleColumns = (columns: IVisibleColumns) => {
        setVisibleColumns(columns);
    };

    const handleChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    useEffect(() => setCurrentPage(1), [debouncedValue]);

    return (
        <DealsOverviewProvider
            selectedStages={selectedStages}
            setSelectedStages={setSelectedStages}
            isExpandedPipeline={isExpandedPipeline}
            setIsExpandedPipeline={setIsExpandedPipeline}
        >
            <PipelineSidebar isExpandedPipeline={isExpandedPipeline} setIsExpandedPipeline={setIsExpandedPipeline} />
            <div
                className={classNames('Deals', {
                    'Deals--empty': isEmpty,
                    'Deals--padding-collapsed': !isExpandedPipeline,
                    'Deals--padding-expanded': isExpandedPipeline,
                })}
            >
                <div className="Deals__header-container d-flex justify-content-between align-items-center">
                    <div>
                        <p className="Deals__header-container__title">Deals</p>
                        <p className="Deals__header-container__subtitle">10 active deals</p>
                    </div>

                    <div className="d-flex align-items-center">
                        <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown} className="Deals__dropdown">
                            <DropdownToggle tag="div">
                                <div
                                    className={classNames('Deals__dropdown__dropdown-toggle d-flex cursor--pointer align-items-center', {
                                        'Deals__dropdown__dropdown-toggle--expanded': isDropdownOpen,
                                    })}
                                    onClick={toggleDropdown}
                                    onKeyDown={toggleDropdown}
                                    role="button"
                                    tabIndex={0}
                                    data-testid="project-menu-toggle"
                                >
                                    All deals
                                    <FontAwesomeIcon
                                        className="Deals__dropdown__dropdown-toggle__dropdown-caret-icon cursor--pointer"
                                        icon={isDropdownOpen ? faCaretUp : faCaretDown}
                                        fixedWidth
                                        data-testid="caret-icon"
                                    />
                                </div>
                            </DropdownToggle>
                            <DropdownMenu className="Deals__dropdown__dropdown-menu">
                                {mockedDropdownOptions.map(option => (
                                    <DropdownItem key={option.id} className="Deals__dropdown__dropdown-menu__dropdown-item">
                                        {option.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>

                        <div className="Deals__vertical-divider" />
                        <NSButton innerContentClassName="NSButton--inner-content-bold" color="secondary" outline={false}>
                            Reports
                        </NSButton>
                        <Link to={URLS.DEALS.FORM}>
                            <NSButton
                                innerContentClassName="NSButton--inner-content-bold"
                                color="primary"
                                outline={false}
                                className="ml-2"
                                icon={faCaretDown}
                                iconPosition="right"
                                text="Create deal"
                            />
                        </Link>
                    </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                    <NavMenu activeSection={activeSection} setActiveSection={setActiveSection} />
                    <div className="d-flex w-25">
                        <VisibleColumnsDropdown
                            visibleColumns={visibleColumns}
                            updateVisibleColumns={updateVisibleColumns}
                            columnOptions={COLUMN_OPTIONS}
                            isOptionDisplayed={isOptionDisplayed}
                        />
                        <NSInput
                            type="search"
                            name="search-bar"
                            placeholder="Search"
                            value={searchValue}
                            onChange={handleChangeSearchValue}
                            customClassName="bg-secondary border-0 px-0 w-100"
                            inputGroupClassName="bg-secondary border-0"
                            prependInputAddon={<FontAwesomeIcon className="text-muted" icon={faMagnifyingGlass} />}
                        />
                    </div>
                </div>
                <RenderIf isTrue={activeSection === DEALS_SECTIONS.MAP}>
                    <MapView selectedDeal={selectedDeal} />
                    <DealsTable
                        className="pt-2"
                        deals={data?.items || []}
                        visibleColumns={visibleColumns}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalItems={data?.itemCount || 0}
                        handleSelectDeal={handleDealSelection}
                    />
                </RenderIf>
                <RenderIf isTrue={activeSection === DEALS_SECTIONS.LIST}>
                    <DealsTable
                        className="pt-2"
                        deals={data?.items || []}
                        visibleColumns={visibleColumns}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalItems={data?.itemCount || 0}
                    />
                </RenderIf>
            </div>
        </DealsOverviewProvider>
    );
};

export default Deals;
